import './CourseStudentProgress.css';
import { useTranslation } from 'react-i18next';

import { fetchStudentProgress, deleteStudentProgress } from '../../../services/instructorDashboard';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';


function CourseStudentProgress({ course_id }) {
    const { t } = useTranslation();
    const [studentProgress, setStudentProgress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    async function getStudentProgress() {
        const resp = await fetchStudentProgress(course_id);
        console.log(resp);
        if (resp.success) {
            setStudentProgress(resp.data);
        }

        setRefreshing(false);
        setLoading(false);
    }

    async function handleDeleteProgress(id) {
        Swal.fire({
            title: t('instructorDashboard.areYouSure'),
            text: t('instructorDashboard.youWontBeAbleToRevertThis'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0B4475",
            cancelButtonColor: "#d33",
            confirmButtonText: t('instructorDashboard.confirm')
          }).then(async(result) => {
            if (result.isConfirmed) {
                setRefreshing(true);
                const resp = await deleteStudentProgress(id);
                if (resp.success) {
                    getStudentProgress();
                    Swal.fire({
                        title: t('instructorDashboard.reset'),
                        text: t('instructorDashboard.progressHasBeenReset'),
                        icon: "success"
                      });
                }
            }
          });
    }

    function handleProgressView(studyGuide) {
        Swal.fire({
            title: `${studyGuide.user.first_name} ${studyGuide.user.last_name} ${t('instructorDashboard.progress')}`,
            html: `
                <div class="progress-details">
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.lessons')}</strong> 
                        <span>${studyGuide.completion_stats.lesson}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.flashcards')}</strong> 
                        <span>${studyGuide.completion_stats.flashcards}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.quizzes')}</strong> 
                        <span>${studyGuide.completion_stats.quiz}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.mockExam')}</strong> 
                        <span>${studyGuide.completion_stats.mock_exam}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.overallProgress')}</strong> 
                        <span>${studyGuide.overall_progress}</span>
                    </div>
                </div>
            `,
            customClass: {
                popup: 'large-swal-container',
                title: 'custom-swal-title'
            },
            width: '500px',
            confirmButtonText: t('instructorDashboard.close'),
            confirmButtonColor: '#0B4475'
        });
    }

    useEffect(() => {
        getStudentProgress();
    }, []);

    return (
        <div className="course-student-progress">
            <h1>{t('instructorDashboard.studentProgress')}</h1>

            {(loading || refreshing) && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress />
                </Box>
            )}

            {!loading && !refreshing && studentProgress.length > 0 && (
                <div className="course-student-progress-list">
                    <div className="course-student-progress-list-header">
                        <span>&#8203;</span>
                        <span>{t('instructorDashboard.lessons')}</span>
                        <span>{t('instructorDashboard.flashcards')}</span>
                        <span>{t('instructorDashboard.quizzes')}</span>
                        <span>{t('instructorDashboard.mockExam')}</span>
                        <span>{t('instructorDashboard.overallProgress')}</span>
                        <span>&#8203;</span>
                    </div>
                    {studentProgress.map((studyGuide) => (
                        <div className="course-student-progress-item">
                            <span className="course-student-progress-item-name">{studyGuide.user.first_name} {studyGuide.user.last_name}</span>
                            <span className="course-student-progress-item-lesson">{studyGuide.completion_stats.lesson}</span>
                            <span className="course-student-progress-item-flashcards">{studyGuide.completion_stats.flashcards}</span>
                            <span className="course-student-progress-item-quiz">{studyGuide.completion_stats.quiz}</span>
                            <span className="course-student-progress-item-mock-exam">{studyGuide.completion_stats.mock_exam}</span>
                            <span className="course-student-progress-item-overall-progress">{studyGuide.overall_progress}</span>
                            <button onClick={() => handleProgressView(studyGuide)} className="course-student-progress-item-view-button" style={{display: 'none'}}>{t('instructorDashboard.view')}</button>
                            <button onClick={() => handleDeleteProgress(studyGuide.id)}>{t('instructorDashboard.reset')}</button>
                        </div>
                    ))}
                </div>
            )}

            {!loading && !refreshing && studentProgress.length === 0 && (
                <div className="course-student-progress-no-progress">
                    <h1>{t('instructorDashboard.noStudyGuidesFound')}</h1>
                </div>
            )}
        </div>
    );
}

export default CourseStudentProgress;