import './Sidebar.css';
import React, { useState, Suspense } from 'react';
// import { aiTag } from "../../assets/icons/icons";
import Ace from './Ace';
import Connect from './Connect';
import CourseContent from './CourseContent';
import StudyGuide from './StudyGuide';
import aiTag from '../../../assets/image/Al-Tag.svg';

import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';

// Import translation hook
import { useTranslation } from 'react-i18next';
import Loading from '../../Common/Loading';

function Sidebar({
    setActiveComponent,
    activeComponent,
    // setChapterData,

    // setSidebarTop,
    courseData,
    currentLesson,
    check,
    markAsCompleted,
    unmarkComplete,
    setCurrentLesson,
    setView,
    setVideoUrl,
    setVideoEnd,
    setIsDownloadable,
    setUniqueId,

    course_id,
    course_title,
    setChapterId,

    setFlashcards,
    flashcards,
    // setViewMode,
    // viewMode,

    setQuiz,
    quiz,
    setQuizStates,
    setQuizSubmitted,
    setQuizScore,

    setLoading,
    // setError,
    loading,
    // error,
    setCreateNewQuiz,
    createNewQuiz,

    courseIsPurchased,
    studyGuide,
    setStudyGuide,
    studyGuideAvailable,
    setStudyGuideAvailable,
    studyGuideLoading,
    setStudyGuideLoading,

    handleLessonClick,
    handleTaskClick,
    openChapters,
    setOpenChapters,
    activeChapter,
    setActiveChapter,
    activeLessonID,
    setActiveLessonID,

    selectedItem,
    setSelectedItem,

    user,

    selectedChapter,
    setSelectedChapter,
    selectedOption,
    setSelectedOption,
    currentTask,
    setCurrentTask,
    summaryReportStats,
    setSummaryReportStats,
    taskIndex,
    setTaskIndex,
    learningStarted,
    setLearningStarted,
    nextDisabled,
    setNextDisabled,
    studyGuideCompleted,
    setStudyGuideCompleted,
    examTaken,
    setExamTaken,
    finalGradeSubmitted,
    setFinalGradeSubmitted,
    isMockExam,
    setIsMockExam,
    endOfQuiz,
    setEndOfQuiz,
    mockExam,
    setMockExam,
    mockExamAnswers,
    setMockExamAnswers,
    mockExamTimeLimit,
    setMockExamTimeLimit,
    mockExamStartTime,
    setMockExamStartTime,
    clearExamTimer,
    mockExamCompleted,
    setMockExamCompleted
}) {
    const { t, i18n } = useTranslation();

    // const [data, setData] = useState([]);
    // async function toGetStudentCourse() {
    //     setLoading(true);
    //     const resp = await getStudentCourse();
    //     if (resp.success) {
    //         setData(resp.data);
    //     } else {
    //         console.log('student all courses - error', resp);
    //     }
    // }
    // console.log('student all courses - data', data);

    // console.log('--------sidebar debugging--------');
    // console.log('sidebar setFlashcards:', typeof setFlashcards);
    // console.log('courseData', courseData);

    const renderActiveComponent = () => {
        switch (selectedItem) {
            case 'studyGuide':
                return (
                    <StudyGuide
                        setActiveComponent={setActiveComponent}
                        activeComponent={activeComponent}
                        courseData={courseData}
                        courseIsPurchased={courseIsPurchased}
                        studyGuide={studyGuide}
                        setStudyGuide={setStudyGuide}
                        studyGuideAvailable={studyGuideAvailable}
                        setStudyGuideAvailable={setStudyGuideAvailable}
                        studyGuideLoading={studyGuideLoading}
                        setStudyGuideLoading={setStudyGuideLoading}
                        user={user}
                        loading={loading}
                        setLoading={setLoading}
                        summaryReportStats={summaryReportStats}
                        setSummaryReportStats={setSummaryReportStats}
                        handleTaskClick={handleTaskClick}
                        setCurrentTask={setCurrentTask}
                        currentTask={currentTask}
                        setSelectedItem={setSelectedItem}
                        setSelectedChapter={setSelectedChapter}
                        setSelectedOption={setSelectedOption}
                        flashcards={flashcards}
                        quiz={quiz}
                        taskIndex={taskIndex}
                        setTaskIndex={setTaskIndex}
                        learningStarted={learningStarted}
                        setLearningStarted={setLearningStarted}
                        nextDisabled={nextDisabled}
                        setNextDisabled={setNextDisabled}
                        studyGuideCompleted={studyGuideCompleted}
                        setStudyGuideCompleted={setStudyGuideCompleted}
                        examTaken={examTaken}
                        setExamTaken={setExamTaken}
                        finalGradeSubmitted={finalGradeSubmitted}
                        setFinalGradeSubmitted={setFinalGradeSubmitted}
                        isMockExam={isMockExam}
                        setIsMockExam={setIsMockExam}
                        mockExam={mockExam}
                        setMockExam={setMockExam}
                        mockExamAnswers={mockExamAnswers}
                        setMockExamAnswers={setMockExamAnswers}
                        clearExamTimer={clearExamTimer}
                        mockExamCompleted={mockExamCompleted}
                        setMockExamCompleted={setMockExamCompleted}
                    />
                );
            case 'courseContent':
                return (
                    <CourseContent
                        setActiveComponent={setActiveComponent}
                        // setSidebarTop={setSidebarTop}
                        courseData={courseData}
                        // Pass the additional props
                        currentLesson={currentLesson}
                        check={check}
                        markAsCompleted={markAsCompleted}
                        unmarkComplete={unmarkComplete}
                        setCurrentLesson={setCurrentLesson}
                        setView={setView}
                        setVideoUrl={setVideoUrl}
                        setVideoEnd={setVideoEnd}
                        setIsDownloadable={setIsDownloadable}
                        setUniqueId={setUniqueId}
                        setLoading={setLoading}
                        handleLessonClick={handleLessonClick}
                        handleTaskClick={handleTaskClick}
                        openChapters={openChapters}
                        setOpenChapters={setOpenChapters}
                        activeChapter={activeChapter}
                        setActiveChapter={setActiveChapter}
                        activeLessonID={activeLessonID}
                        setActiveLessonID={setActiveLessonID}
                    />
                );
            case 'ace':
                return (
                    <Ace
                        setActiveComponent={setActiveComponent}
                        // setChapterData={setChapterData}
                        courseData={courseData}
                        // setSidebarTop={setSidebarTop}
                        course_id={course_id}
                        course_title={course_title}
                        setFlashcards={setFlashcards}
                        flashcards={flashcards}
                        // setViewMode={setViewMode}
                        // viewMode={viewMode}
                        setQuiz={setQuiz}
                        setQuizStates={setQuizStates}
                        setQuizSubmitted={setQuizSubmitted}
                        setQuizScore={setQuizScore}
                        setChapterId={setChapterId}
                        setLoading={setLoading}
                        // setError={setError}
                        loading={loading}
                        // error={error}
                        setCreateNewQuiz={setCreateNewQuiz}
                        createNewQuiz={createNewQuiz}
                        studyGuide={studyGuide}
                        setStudyGuide={setStudyGuide}
                        selectedChapter={selectedChapter}
                        setSelectedChapter={setSelectedChapter}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setSelectedItem={setSelectedItem}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        isMockExam={isMockExam}
                        setIsMockExam={setIsMockExam}
                        endOfQuiz={endOfQuiz}
                        setEndOfQuiz={setEndOfQuiz}
                        mockExam={mockExam}
                        setMockExam={setMockExam}
                        mockExamAnswers={mockExamAnswers}
                        setMockExamAnswers={setMockExamAnswers}
                        mockExamTimeLimit={mockExamTimeLimit}
                        setMockExamTimeLimit={setMockExamTimeLimit}
                        mockExamStartTime={mockExamStartTime}
                        setMockExamStartTime={setMockExamStartTime}
                    />
                );
            case 'connect':
                return <Connect setActiveComponent={setActiveComponent} data={courseData} />;
            default:
                return null;
        }
    };

    const handleItemClick = (componentName) => {
        setSelectedItem(componentName);
    };

    return (
        <div className="sidebar">
            <div className="sidebar__menu">
                <ul>
                    <li
                        className={`sidebar__item ${selectedItem === 'studyGuide' ? 'selected' : 'inactive'}`}
                        onClick={() => {
                            handleItemClick('studyGuide');
                            setActiveComponent('StudyGuide');
                        }}
                    >
                        {/* <img src={studyGuideIcon} className="sidebar__icon" /> */}
                        {/* <AssistantOutlinedIcon
                            style={{
                                fontSize: '24px',
                                color: '0e0e0e', // Color for outlined icon
                                opacity: selectedItem === 'studyGuide' ? 1 : 0.8,
                            }}
                        /> */}
                        <MenuBookOutlinedIcon
                            style={{
                                fontSize: '24px',
                                color: '0e0e0e', // Color for outlined icon
                                opacity: selectedItem === 'studyGuide' ? 1 : 0.8,
                            }}
                        />
                        <span className={selectedItem === 'studyGuide' ? '' : 'inactive'}>
                            Study Guide
                        </span>
                    </li>

                    <li
                        className={`sidebar__item ${selectedItem === 'courseContent' ? 'selected' : 'inactive'}`}
                        onClick={() => handleItemClick('courseContent')}
                    >
                        {/* <img src={courseContent} className="sidebar__icon" /> */}
                        <PlaylistAddOutlinedIcon
                            style={{
                                fontSize: '28px',
                                color: '0e0e0e', // Color for outlined icon
                                opacity: selectedItem === 'courseContent' ? 1 : 0.8,
                            }}
                        />
                        <span className={selectedItem === 'courseContent' ? '' : 'inactive'}>
                            {t('course.courseContent')}
                            {/* Course Content */}
                        </span>
                    </li>

                    <li
                        className={`sidebar__item ${selectedItem === 'ace' ? 'selected' : 'inactive'}`}
                        onClick={() => handleItemClick('ace')}
                    >
                        <div className="sidebar__icon-container">
                            <AutoAwesomeOutlinedIcon
                                style={{
                                    fontSize: '28px',
                                    color: '0e0e0e', // Color for outlined icon
                                    opacity: selectedItem === 'ace' ? 1 : 0.8,
                                }}
                            />
                            <span className={selectedItem === 'ace' ? '' : 'inactive'}>
                                {t('aifeatures.ace')}
                                {/* ACE */}
                            </span>
                        </div>
                        <img src={aiTag} alt="aiTag" className="sidebar__icon--aiTag" />
                    </li>

                    <li
                        className={`sidebar__item ${selectedItem === 'connect' ? 'selected' : 'inactive'}`}
                        onClick={() => handleItemClick('connect')}
                    >
                        <PersonAddAltOutlinedIcon
                            style={{
                                fontSize: '24px',
                                color: '0e0e0e',
                                opacity: selectedItem === 'connect' ? 1 : 0.8,
                            }}
                        />
                        <span className={selectedItem === 'connect' ? '' : 'inactive'}>
                            {t('common.connect')}
                            {/* Connects */}
                        </span>
                    </li>
                </ul>
            </div>

            <div className="sidebar__content">{renderActiveComponent()}</div>
        </div>
    );
}

export default Sidebar;
