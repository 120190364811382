import './Favorites.css';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getFavorites, deleteFavoriteFlashcard, deleteFavoriteQuiz, rateFavorite } from '../../services/studyGuides';

import Swal from 'sweetalert2';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

function Favorites({user}) {

    const { t } = useTranslation();
    const [selectedOption, setSelectedOption] = useState('Flashcard');
    const [loading, setLoading] = useState(false);
    const [flashcards, setFlashcards] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const hasFlashcards = flashcards.length > 0;
    const hasQuizzes = quizzes.length > 0;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showAnswers, setShowAnswers] = useState([]);

    useEffect(() => {
        fetchFavorites();
    }, []);

    async function fetchFavorites() {
        setLoading(true);
        const {resp, resp2} = await getFavorites(user.id);

        if (resp.success && resp2.success) {
            let setter = []
            for (let i = 0; i < resp.data.length; i++) {
                let flashcard = resp.data[i].flashcard
                setter.push({favorite_id: resp.data[i].id, ...flashcard})
            }
            setFlashcards(setter);

            let setter2 = []
            for (let i = 0; i < resp2.data.length; i++) {
                let quiz = resp2.data[i].ai_quiz
                setter2.push({favorite_id: resp2.data[i].id, ...quiz})
            }
            setQuizzes(setter2);

            console.log('flashcards', setter)
            console.log('quizzes', setter2)
        }
        setLoading(false)
    }

    function handleOptionChange(option) {
        setSelectedOption(option);
    }

    const toggleShowAnswer = (index) => {
        setShowAnswers((prevShowAnswers) => ({
            ...prevShowAnswers,
            [index]: !prevShowAnswers[index],
        }));
    };

    // Navigation handlers for page mode
    const handleNext = (e) => {
        e.stopPropagation(); // Prevent triggering parent click events
        if (currentIndex < flashcards.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = (e) => {
        e.stopPropagation(); // Prevent triggering parent click events
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    async function handleDeleteFlashcard(flashcard) {
        const result = await Swal.fire({
            title: t('studyGuide.favorites.areYouSure'),
            text: t('studyGuide.favorites.youWontBeAbleToRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0B4374',
            cancelButtonColor: '#d33',
            confirmButtonText: t('studyGuide.favorites.delete')
        });

        if (result.isConfirmed) {
            setFlashcards(prevFlashcards => prevFlashcards.filter(card => card.favorite_id !== flashcard.favorite_id));
            if (currentIndex === flashcards.length - 1 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            }
            const resp = await deleteFavoriteFlashcard(flashcard.favorite_id);
            if (resp.success) {
                // Show success message
                Swal.fire(
                    t('studyGuide.favorites.deleted'),
                    t('studyGuide.favorites.yourFlashcardHasBeenRemovedFromFavorites'),
                    t('studyGuide.favorites.success')
                );
            }
        }
    }

    async function handleFlashcardRate(flashcard) {
        const { value: formValues } = await Swal.fire({
            title: t('studyGuide.favorites.rateFlashcard'),
            html:
                '<div class="rating-container">' +
                '<label>' + t('studyGuide.favorites.rating') + ':</label>' +
                '<div class="range-value-display" style="text-align: center; font-size: 24px; margin: 10px 0;"><span id="range-value">3</span>/5</div>' +
                '<input type="range" id="swal-rating" class="swal2-range" min="1" max="5" value="3" step="1" style="width: 100%;" oninput="document.getElementById(\'range-value\').textContent = this.value">' +
                '</div>' +
                '<div class="feedback-container">' +
                '<textarea id="swal-feedback" class="swal2-textarea" placeholder="' + t('studyGuide.favorites.enterYourFeedbackHere') + '"></textarea>' +
                '</div>',
            width: '800px',
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: t('studyGuide.favorites.submit'),
            confirmButtonColor: '#0B4374',
            cancelButtonText: t('studyGuide.favorites.cancel'),
            cancelButtonColor: '#d33',
            preConfirm: () => {
                const rating = document.getElementById('swal-rating').value;
                const feedback = document.getElementById('swal-feedback').value;
                return [rating, feedback];
            }
        });
    
        if (formValues) {
            const [rating, feedback] = formValues;
            let payload = {
                user_id: user.id,
                course_id: flashcard.course_id,
                chapter_id: flashcard.chapter_id,
                type: 'flashcard',
                rating: parseInt(rating),
                feedback: feedback,
                flashcard_favorite_id: flashcard.favorite_id,
                quiz_favorite_id: null
            }
            const resp = await rateFavorite(payload)
            if (resp.success) {
                Swal.fire(t('studyGuide.favorites.thankYouForYourFeedback'), t('studyGuide.favorites.yourRatingHasBeenSubmitted'), t('studyGuide.favorites.success'));
            }
        }
    }

    async function handleDeleteQuiz(quiz) {
        const result = await Swal.fire({
            title: t('studyGuide.favorites.areYouSure'),
            text: t('studyGuide.favorites.youWontBeAbleToRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0B4374',
            cancelButtonColor: '#d33',
            confirmButtonText: t('studyGuide.favorites.delete')
        });

        if (result.isConfirmed) {
            setQuizzes(prevQuizzes => prevQuizzes.filter(card => card.favorite_id !== quiz.favorite_id));
            const resp = await deleteFavoriteQuiz(quiz.favorite_id);
            if (resp.success) {
                // Show success message
                Swal.fire(
                    t('studyGuide.favorites.deleted'),
                    t('studyGuide.favorites.yourQuizHasBeenRemovedFromFavorites'),
                    t('studyGuide.favorites.success')
                );
            }
        }
    }

    async function handleQuizRate(quiz) {
        const { value: formValues } = await Swal.fire({
            title: t('studyGuide.favorites.rateQuiz'),
            html:
                '<div class="rating-container">' +
                '<label>' + t('studyGuide.favorites.rating') + ':</label>' +
                '<div class="range-value-display" style="text-align: center; font-size: 24px; margin: 10px 0;"><span id="range-value">3</span>/5</div>' +
                '<input type="range" id="swal-rating" class="swal2-range" min="1" max="5" value="3" step="1" style="width: 100%;" oninput="document.getElementById(\'range-value\').textContent = this.value">' +
                '</div>' +
                '<div class="feedback-container">' +
                '<textarea id="swal-feedback" class="swal2-textarea" placeholder="' + t('studyGuide.favorites.enterYourFeedbackHere') + '"></textarea>' +
                '</div>',
            width: '800px',
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonText: t('studyGuide.favorites.submit'),
            confirmButtonColor: '#0B4374',
            cancelButtonText: t('studyGuide.favorites.cancel'),
            cancelButtonColor: '#d33',
            preConfirm: () => {
                const rating = document.getElementById('swal-rating').value;
                const feedback = document.getElementById('swal-feedback').value;
                return [rating, feedback];
            }
        });
    
        if (formValues) {
            const [rating, feedback] = formValues;
            let payload = {
                user_id: user.id,
                course_id: quiz.course_id,
                chapter_id: quiz.chapter_id,
                type: 'quiz',
                rating: parseInt(rating),
                feedback: feedback,
                flashcard_favorite_id: null,
                quiz_favorite_id: quiz.favorite_id
            }
            const resp = await rateFavorite(payload)
            if (resp.success) {
                Swal.fire(t('studyGuide.favorites.thankYouForYourFeedback'), t('studyGuide.favorites.yourRatingHasBeenSubmitted'), t('studyGuide.favorites.success'));
            }
        }
    }

    const renderFlashcard = (flashcard, index) => {
        const currentState = flashcards[flashcard.id] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };

        return (
            <div
                key={flashcard.id}
                className="flashcard"
                style={{ backgroundColor: showAnswers[index] ? '#138ab4' : '' }}
            >
                <div className="flashcard__header">
                    <h1>Dawraty</h1>
                </div>
                <div className="flashcard__content">
                    <div className="flashcard__content-info" onClick={() => toggleShowAnswer(index)}>
                        {currentIndex > 0 ? (
                            <div className="flashcard__content-info-icon" onClick={handlePrevious}>
                                <ArrowBackIosNewOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        ) : (
                            <div className="flashcard__content-info-placeholder"></div> // Placeholder for alignment
                        )}

                        <h1 className="flashcard__content-info-content">
                            {showAnswers[index] ? flashcard.answer : flashcard.question}
                        </h1>

                        {currentIndex < flashcards.length - 1 && (
                            <div className="flashcard__content-info-icon" onClick={handleNext}>
                                <ArrowForwardIosOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="flashcard__counter">
                    <div className="flashcard__counter-content">
                        <h4 className="flashcard__counter-content-numerator">{index + 1}</h4>
                        <span>/</span>
                        <h4 className="flashcard__counter-content-denominator">{flashcards.length}</h4>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="favorites-container">
            <div id="favorites-switch-menu" className={`ace__switch-menu ${loading ? 'loading' : ''}`}>
                <button
                    className={`ace__switch-btn ${selectedOption === 'Flashcard' ? 'active' : ''}`}
                    onClick={() => handleOptionChange('Flashcard')}
                    disabled={loading}
                >
                    {t('studyGuide.favorites.flashcards')}
                </button>
                <button
                    className={`ace__switch-btn ${selectedOption === 'Quiz' ? 'active' : ''}`}
                    onClick={() => handleOptionChange('Quiz')}
                    disabled={loading}
                >
                    {t('studyGuide.favorites.quizzes')}
                </button>
            </div>

            {!loading && hasFlashcards && selectedOption === 'Flashcard' && (
                <div className="favorites-flashcards">
                    {renderFlashcard(flashcards[currentIndex], currentIndex)}
                    <div className="favorites-flashcards-actions">
                        <button onClick={() => handleDeleteFlashcard(flashcards[currentIndex])}>{t('studyGuide.favorites.delete')}</button>
                        <button onClick={() => handleFlashcardRate(flashcards[currentIndex])}>{t('studyGuide.favorites.rate')}</button>
                    </div>
                </div>
            )}

            {!loading && hasQuizzes && selectedOption === 'Quiz' && (
                <div className="favorites-quizzes">
                    {quizzes.map((quiz, index) => (
                        <div key={index} className="favorites-quizzes-item">
                            <h2 className="favorites-quizzes-item-question">{quiz.question}</h2>
                            <span className="favorites-quizzes-item-answer">{t('studyGuide.favorites.answer')}{quiz.answer}</span>
                            <span className="favorites-quizzes-item-incorrect-answer">{t('studyGuide.favorites.B')}{quiz.incorrect_answer1}</span>
                            <span className="favorites-quizzes-item-incorrect-answer">{t('studyGuide.favorites.C')}{quiz.incorrect_answer2}</span>
                            <span className="favorites-quizzes-item-incorrect-answer">{t('studyGuide.favorites.D')}{quiz.incorrect_answer3}</span>
                            <span className="favorites-quizzes-item-explanation">{t('mock_exam.explanation')}: {quiz.explanation}</span>
                            <div className="favorites-quizzes-item-actions">
                                <button onClick={() => handleDeleteQuiz(quiz)}>{t('studyGuide.favorites.delete')}</button>
                                <button onClick={() => handleQuizRate(quiz)}>{t('studyGuide.favorites.rate')}</button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {!loading && !hasFlashcards && selectedOption === 'Flashcard' && (
                <div className="favorites-flashcards-empty">
                    <h2>{t('studyGuide.favorites.noFlashcards')}</h2>
                </div>
            )}

            {!loading && !hasQuizzes && selectedOption === 'Quiz' && (
                <div className="favorites-quizzes-empty">
                    <h2>{t('studyGuide.favorites.noQuizzes')}</h2>
                </div>
            )}

            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%' }}>
                    <CircularProgress />
                </Box>
            )}
        </div>
    )
}

export default Favorites;