import React, { useState, useContext, useEffect, useRef, useCallback, Suspense } from 'react';
import { Redirect, withRouter, useLocation } from 'react-router-dom';
import { animated, useSpring, useSprings } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/AuthContext';

import Loading from '../Common/Loading';
import ErrorBoundary from '../Common/ErrorBoundary';

import PageLayout from '../Layouts/PageLayout';
import Sidebar from './Sidebar/Sidebar';
import Flashcard from './Course/Flashcard';
import Favorites from './Course/Favorites';
// import Loader from './Course/Loader';

// import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';

import Test from './Course/Test';
// import Test from './Quiz';
import PDFReader from './Course/PDFReader';
// import LiveSession from './LiveSession';
import LiveSession from './Course/LiveSession';
import Quiz from './Course/Quiz';
import { StudyGuideDashboard } from './Sidebar/StudyGuide';
import Unavailable from '../Common/Unavailable';

// Import audio and video player components
import AudioPlayer from 'react-h5-audio-player';

import { ToastContainer, toast } from 'react-toastify';

import {
    Player,
    ControlBar,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    BigPlayButton,
    PlaybackRateMenuButton,
} from 'video-react';

// Import services for API calls
import { getCourseById, lessonCompletionUpdate, getStudentPurchases } from '../services/student';
import { getFullStudyGuideByCourseId, updateStudyGuideTask } from '../services/studyGuides/index';
import { postComments } from '../services/discussionForum';
import getTranslationFromMany from '../../services/helper';
import { showError, showSuccess } from '../Utils/helper';

import 'react-h5-audio-player/lib/styles.css';
import 'video-react/dist/video-react.css';
import './CoursePage.css';

import TutorRevamp from '../AI/TutorRevamp';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { FaRobot } from 'react-icons/fa';

import Swal from 'sweetalert2';

const CoursePage = (props) => {
    const [activeComponent, setActiveComponent] = useState('StudyGuide');
    const { loggedIn } = useContext(UserContext);
    const { user } = useContext(UserContext);

    // *course page*
    const [data, setData] = useState([]);
    const [courseIsPurchased, setCourseIsPurchased] = useState(false);
    const [studyGuide, setStudyGuide] = useState({});
    const [studyGuideAvailable, setStudyGuideAvailable] = useState(false);
    const [studyGuideLoading, setStudyGuideLoading] = useState(false);
    const [studyGuideCompleted, setStudyGuideCompleted] = useState(false);
    const [examTaken, setExamTaken] = useState(false);
    const [finalGradeSubmitted, setFinalGradeSubmitted] = useState(false);
    const playerRef = useRef(null);
    const course_id = props.location.state;
    const [isDownloadable, setIsDownloadable] = useState(false);
    const [uniqueId, setUniqueId] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [videoState, setVideoState] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    // const [viewMode, setViewMode] = useState('list');
    const [videoEnd, setVideoEnd] = useState(false);
    const [instructorToAsk, setInstructorToAsk] = useState(null);
    const [chapter_id, setChapterId] = useState(null);

    // *sidebar*
    const [view, setView] = useState('');

    // *flashcard*
    const [chapterData, setChapterData] = useState();
    const [flashcards, setFlashcards] = useState([]);
    // const [viewMode, setViewMode] = useState('list');

    // *quiz*
    const [quiz, setQuiz] = useState([]);
    const [check, setCheck] = useState([]);
    const [createNewQuiz, setCreateNewQuiz] = useState(false);

    // *loading *
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);

    // *others
    const heightRef = useRef(null);
    const { t, i18n } = useTranslation();
    // const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(false);
    const [height, setHeight] = useState(0);

    const contentRef = useRef(null); // Reference for content
    const sidebarRef = useRef(null); // Reference for sidebar

    const [selectedChapter, setSelectedChapter] = useState({
        name: '',
        id: null,
    });
    const [selectedOption, setSelectedOption] = useState('');
    const [currentTask, setCurrentTask] = useState({});
    const [nextDisabled, setNextDisabled] = useState(false);
    const [isMockExam, setIsMockExam] = useState(false);
    const [endOfQuiz, setEndOfQuiz] = useState(true);
    const [mockExam, setMockExam] = useState([]);
    const [mockExamAnswers, setMockExamAnswers] = useState([]);
    const [mockExamCompleted, setMockExamCompleted] = useState(false);

    const [summaryReportStats, setSummaryReportStats] = useState({
        quiz: '0%',
        lesson: 0,
        flashcards: 0,
        mock_exam: '0%',
        lesson_quiz: '0%',
        overall_progress: '0%',
        week_progress: '0%',
        average_grade: '0%',
        stats_loaded: false,
    });

    const [resp, setResp] = useState([]);

    const [openTutor, setOpenTutor] = useState(false); // Tutor visibility state
    const { setChatbotAvailable } = props; // Function to set chatbot availability

    const [openChapters, setOpenChapters] = useState({});
    const [activeChapter, setActiveChapter] = useState(null);
    const [activeLessonID, setActiveLessonID] = useState(null);
    const [selectedItem, setSelectedItem] = useState('studyGuide');
    const [taskIndex, setTaskIndex] = useState(1);
    const [learningStarted, setLearningStarted] = useState(false);

    const lastCompletedTaskRef = useRef(null);

    // Add these new states near your other state declarations
    const [examTimeLimit, setExamTimeLimit] = useState(null);
    const [examTimer, setExamTimer] = useState(null);
    const [examTimerInterval, setExamTimerInterval] = useState(null);

    useEffect(() => {
        if (flashcards.length > 0 || quiz.length > 0) {
            if (
                studyGuideAvailable &&
                (currentTask.type == 'quiz' || currentTask.type == 'mock_exam' || currentTask.type == 'flashcards')
            ) {
                setSelectedItem('studyGuide');
            }
        }
    }, [flashcards, quiz]);

    useEffect(() => {
        if (currentTask && studyGuideAvailable) {
            const task = studyGuide?.tasks.find((task) => task.id == currentTask.id);
            if (task?.is_completed === false && (currentTask.type == 'quiz' || currentTask.type == 'mock_exam')) {
                setNextDisabled(true);
            } else if (task?.is_completed === true) {
                if (studyGuide.tasks.some((t) => t.is_completed === false)) {
                    setNextDisabled(false);
                } else {
                    setStudyGuideCompleted(true);
                }
                // Only show toast if this is a newly completed task
                if (lastCompletedTaskRef.current !== task.id) {
                    toast.success(t('studyGuide.tasks.taskMarkedCompleted'));
                    lastCompletedTaskRef.current = task.id;
                }
            }
        }
    }, [currentTask, studyGuide]);

    // Animation for the tutor icon
    const springs = useSpring({
        transform: openTutor ? 'rotate(360deg)' : 'rotate(0deg)',
    });

    // Spring properties for scaling
    const [springProps, set] = useSpring(() => ({ scale: 1 }));

    async function toGetCourseById() {
        setLoading(true);
        const resp = await getCourseById(course_id);
        setResp(resp);
        if (resp.success) {
            setData(resp.data);
            if (resp?.data?.chapter[0]?.lessons[0]?.media[0]?.is_downloadable === 1) {
                setIsDownloadable(true);
            }
            setVideoUrl(resp?.data?.chapter[0]?.lessons[0]?.media[0]?.url);
            setCurrentLesson(resp?.data?.chapter[0]?.lessons[0]);
            setView(
                resp?.data?.chapter[0]?.lessons[0]?.quiz?.length > 0
                    ? 'quiz'
                    : resp?.data?.chapter[0]?.lessons[0]?.media[0]?.media_type
            );
            toGetStudentPurchases();
            setLoading(false);
        } else {
            showError('Somthing went wrong');
            setLoading(false);
            console.log('course page - error', resp);
        }
    }

    async function toGetStudentPurchases() {
        const resp = await getStudentPurchases(user?.id);
        if (resp.success) {
            for (let i = 0; i < resp?.data?.length; i++) {
                if (resp?.data[i]?.course_id === course_id) {
                    setCourseIsPurchased(true);
                    toGetStudyGuide();
                }
            }
            setLoading(false);
        } else {
            setCourseIsPurchased(false);
            setLoading(false);
        }
    }

    async function toGetStudyGuide() {
        const resp = await getFullStudyGuideByCourseId(user?.id, course_id);
        if (resp.success) {
            setStudyGuide(resp?.data);
            setStudyGuideAvailable(true);
            const tasks = resp?.data?.tasks?.filter((task) => task.is_completed === false);
            if (tasks.length === 0) {
                setStudyGuideCompleted(true);
                if (resp?.data?.expected_grade != null) {
                    setExamTaken(true);
                }
                if (resp?.data?.final_grade != null) {
                    setFinalGradeSubmitted(true);
                }
            }
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    // Function to update lesson completion status
    async function lessonCompletionUpdateApi() {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: currentLesson?.chapter_id,
            lesson_id: currentLesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCounter(false);
            setCheck([...check, resp?.data?.lesson_id]);
            // showSuccess(t('alertMessage.lessonCompletedSuccess'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to mark a lesson as completed
    async function markAsCompleted(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t('alertMessage.lessonCompleted'));

            // Find and update corresponding study guide task
            const matchingTask = studyGuide?.tasks?.find(
                (task) => task.type === 'lesson' && task.lesson_id === _lesson.id
            );

            if (matchingTask) {
                const taskResp = await updateStudyGuideTask(matchingTask.id, {
                    is_completed: true,
                });

                if (taskResp.success) {
                    // Update the studyGuide state with new task completion and stats
                    setStudyGuide((prevGuide) => ({
                        ...prevGuide,
                        tasks: prevGuide.tasks.map((t) =>
                            t.id === matchingTask.id ? { ...t, is_completed: true } : t
                        ),
                        completion_stats: taskResp.data.completion_stats,
                        overall_progress: taskResp.data.overall_progress,
                        week_progress: taskResp.data.week_progress,
                    }));
                }
            }
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to unmark a lesson as completed
    async function unmarkComplete(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck(check.filter((item) => item !== resp?.data?.lesson_id));
            showSuccess(t('alertMessage.success'));

            // Find and update corresponding study guide task
            const matchingTask = studyGuide?.tasks?.find(
                (task) => task.type === 'lesson' && task.lesson_id === _lesson.id
            );

            if (matchingTask) {
                const taskResp = await updateStudyGuideTask(matchingTask.id, {
                    is_completed: false,
                });

                if (taskResp.success) {
                    // Update the studyGuide state with new task completion and stats
                    setStudyGuide((prevGuide) => ({
                        ...prevGuide,
                        tasks: prevGuide.tasks.map((t) =>
                            t.id === matchingTask.id ? { ...t, is_completed: false } : t
                        ),
                        completion_stats: taskResp.data.completion_stats,
                        overall_progress: taskResp.data.overall_progress,
                        week_progress: taskResp.data.week_progress,
                    }));
                }
            }
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    const handleLessonClick = async (lesson, task = {}) => {
        // if (!lesson) {
        //     throw new Error('Lesson data is unavailable');
        // }

        if (lesson?.type === 'quiz' || lesson?.type === 'live-session') {
            // setLoading(false);
            setCurrentLesson(lesson);
            setView(lesson?.type);
        } else {
            // setLoading(false);
            // if (!lesson?.media?.[0]?.url) {
            //     throw new Error('Media content is currently unavailable');
            // }
            setVideoUrl(lesson?.media[0]?.url);
            setCurrentLesson(lesson);
            setVideoEnd(false);
            setView(lesson?.media[0]?.media_type);
            setIsDownloadable(lesson?.media[0]?.is_downloadable);
        }
        // setLoading(false);

        // if (lesson?.type != 'quiz') {
        //     console.log('lessonType', lesson?.type);
        //     if (Object.keys(task).length > 0) {
        //         const resp = await updateStudyGuideTask(task.id, {
        //             is_completed: true
        //         });
        //         if (resp.success) {
        //             const updatedStudyGuide = {
        //                 ...studyGuide,
        //                 tasks: studyGuide.tasks.map(t =>
        //                     t.id === task.id ? { ...t, is_completed: true } : t
        //                 ),
        //                 completion_stats: resp.data.completion_stats,
        //                 overall_progress: resp.data.overall_progress,
        //                 week_progress: resp.data.week_progress,
        //                 average_grade: resp.data.average_grade
        //             };
        //             setStudyGuide(updatedStudyGuide);
        //         }
        //     }
        // }
    };

    const handleTaskClick = (_lesson, task = {}) => {
        // setLoading(true);

        setActiveLessonID(_lesson?.id);
        setActiveComponent('Course');
        handleLessonClick(_lesson, task);

        // Scroll to top smoothly
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // for smooth scrolling
        });

        // Scroll course content list to top
    };

    const renderActiveComponent = () => {
        // Check for unavailable content
        // if (!currentLesson || !view || (view !== 'quiz' && view !== 'live-session' && !videoUrl)) {
        //     return <Unavailable />;
        // }
        // if (!currentLesson || !view || (view !== 'quiz' && view !== 'live-session' && !videoUrl)) {
        //     return <Unavailable />;
        // }
        // if (!view) {
        //     return <Unavailable />;
        // }
        const components = {
            Course: (
                <div className="course__container">
                    {view === 'video' && (
                        <div className="course_video">
                            <Player
                                src={process.env.REACT_APP_CONTENT_URL + videoUrl}
                                ref={playerRef}
                                fluid={true}
                                width="100%"
                                height="100%"
                            >
                                <BigPlayButton position="center" />

                                <ControlBar autoHide={false}>
                                    <PlayToggle />
                                    <ReplayControl seconds={10} order={2.2} />
                                    <ForwardControl seconds={10} order={3.2} />
                                    <PlaybackRateMenuButton rates={[2, 1, 1.75, 1.5]} />
                                </ControlBar>
                            </Player>
                        </div>
                    )}

                    {view === 'image' && (
                        <img src={process.env.REACT_APP_ASSETS + videoUrl} class="responsive-image" alt={data?.name} />
                    )}
                    {view === 'audio' && (
                        <div className="course_video">
                            <AudioPlayer
                                autoPlay
                                src={process.env.REACT_APP_ASSETS + videoUrl}
                                onEnded={() => lessonCompletionUpdateApi()}
                                showSkipControls={false}
                                loop={false}
                            />
                        </div>
                    )}

                    {view === 'pdf' && <PDFReader videoUrl={videoUrl} isDownloadable={isDownloadable} />}
                    {view === 'live-session' && (
                        <LiveSession
                            name={currentLesson?.name}
                            url={currentLesson?.live_session[0]?.URL}
                            date={currentLesson?.live_session[0]?.session_date}
                        />
                    )}
                    {view === 'quiz' && (
                        <Test
                            key={uniqueId}
                            quiz={currentLesson}
                            course_id={course_id}
                            markAsCompleted={markAsCompleted}
                            uniqueId={uniqueId}
                            studyGuide={studyGuide}
                            setStudyGuide={setStudyGuide}
                        />
                    )}
                    {!view && <Unavailable />}
                </div>
            ),
            Quiz: (
                <Quiz
                    // activeSidebar={activeSidebar}
                    chapterData={chapterData}
                    // handleActiveSidebar={handleActiveSidebar}
                    chapter={chapterData?.chapterName}
                    course_id={data?.id}
                    chapter_id={chapter_id}
                    quiz={quiz}
                    setCreateNewQuiz={setCreateNewQuiz}
                    // createNewQuiz={createNewQuiz}
                    studyGuide={studyGuide}
                    setStudyGuide={setStudyGuide}
                    currentTask={currentTask}
                    setCurrentTask={setCurrentTask}
                    isMockExam={isMockExam}
                    setIsMockExam={setIsMockExam}
                    endOfQuiz={endOfQuiz}
                    setEndOfQuiz={setEndOfQuiz}
                    mockExam={mockExam}
                    setMockExam={setMockExam}
                    mockExamAnswers={mockExamAnswers}
                    setMockExamAnswers={setMockExamAnswers}
                    setSelectedOption={setSelectedOption}
                    setSelectedItem={setSelectedItem}
                    handleMockExamStart={handleMockExamStart}
                    setActiveComponent={setActiveComponent}
                    setExamTimer={setExamTimer}
                    clearExamTimer={clearExamTimer}
                    activeComponent={activeComponent}
                    setMockExamCompleted={setMockExamCompleted}
                    mockExamCompleted={mockExamCompleted}
                />
            ),
            Flashcard: (
                <Flashcard
                    chapterData={chapterData}
                    chapter={chapterData?.chapterName}
                    course_id={data?.id}
                    chapter_id={chapter_id}
                    flashcards={flashcards}
                />
            ),
            StudyGuide: (
                <StudyGuideDashboard
                    user={user}
                    courseIsPurchased={courseIsPurchased}
                    setCourseIsPurchased={setCourseIsPurchased}
                    course_name={data?.name}
                    studyGuide={studyGuide}
                    setStudyGuide={setStudyGuide}
                    studyGuideAvailable={studyGuideAvailable}
                    setStudyGuideAvailable={setStudyGuideAvailable}
                    studyGuideLoading={studyGuideLoading}
                    setStudyGuideLoading={setStudyGuideLoading}
                    setView={setView}
                    setActiveComponent={setActiveComponent}
                    handleLessonClick={handleLessonClick}
                    handleTaskClick={handleTaskClick}
                    courseData={data}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    setOpenChapters={setOpenChapters}
                    selectedChapter={selectedChapter}
                    setSelectedChapter={setSelectedChapter}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    currentTask={currentTask}
                    setCurrentTask={setCurrentTask}
                    summaryReportStats={summaryReportStats}
                    setSummaryReportStats={setSummaryReportStats}
                    isMockExam={isMockExam}
                    setIsMockExam={setIsMockExam}
                    endOfQuiz={endOfQuiz}
                    setEndOfQuiz={setEndOfQuiz}
                    mockExam={mockExam}
                    setMockExam={setMockExam}
                    mockExamAnswers={mockExamAnswers}
                    setMockExamAnswers={setMockExamAnswers}
                />
            ),
            Favorites: <Favorites user={user} />,
        };
        return components[activeComponent] || components.Course;
    };

    // Function to handle instructor selection for questions
    const handleChooseQAInstructor = (event) => {
        setInstructorToAsk(event.target.value);
    };

    // Effect to prevent right-click context menu and fetch course data
    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        setLoading(true);
        // toGetCourseById();
        // toGetStudentPurchases();
    }, []);

    useEffect(() => {
        if (user != null && user != undefined) {
            if (Object.keys(user).length > 0) {
                toGetCourseById();
            }
        }
    }, [user]);

    // Effect to subscribe to video player state changes
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.subscribeToStateChange((e) => {
                setVideoState(e);
            });
        }
    });

    // Effect to handle video completion
    useEffect(() => {
        if (videoState && videoState?.ended && !videoEnd) {
            lessonCompletionUpdateApi();
            setVideoEnd(true);
        }
    }, [videoState]);

    // Effect to redirect if not logged in
    useEffect(() => {
        if (!loggedIn) {
            <Redirect to="/" />;
        }
    }, [loggedIn]);

    useEffect(() => {
        setChatbotAvailable(false);
        return () => {
            setChatbotAvailable(true);
        };
    }, []);

    useEffect(() => {
        let resizeObserver;

        // Debounce the resize callback
        const updateHeight = () => {
            if (contentRef.current && sidebarRef.current) {
                requestAnimationFrame(() => {
                    sidebarRef.current.style.height = `${contentRef.current.clientHeight}px`;
                });
            }
        };

        if (contentRef.current) {
            resizeObserver = new ResizeObserver((entries) => {
                // Use requestAnimationFrame to avoid the loop warning
                window.requestAnimationFrame(() => {
                    updateHeight();
                });
            });

            resizeObserver.observe(contentRef.current);
        }
        // Cleanup
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [data]);

    // Add this new function before your return statement
    const handleMockExamStart = async () => {
        const { value: minutes } = await Swal.fire({
            title: 'Mock Exam Timer',
            text: 'You will be answering 10 questions. How many minutes would you like to take?',
            input: 'number',
            inputLabel: 'Minutes',
            inputPlaceholder: 'Enter number of minutes',
            inputAttributes: {
                min: 1,
                max: 60,
                step: 1,
            },
            inputValue: 20,
            showCancelButton: true,
            confirmButtonText: 'Start Exam',
            inputValidator: (value) => {
                if (!value || value < 1) {
                    return 'Please enter a valid number of minutes';
                }
            },
        });

        if (minutes) {
            const timeInMs = minutes * 60 * 1000;
            const endTime = Date.now() + timeInMs;

            // Clear any existing timer
            clearExamTimer();

            // Start the new timer
            const newInterval = setInterval(() => {
                const now = Date.now();
                const timeLeft = endTime - now;

                if (timeLeft <= 0) {
                    clearExamTimer();
                    Swal.fire({
                        title: "Time's Up!",
                        icon: 'info',
                        confirmButtonText: 'OK',
                    });
                } else {
                    const minutesLeft = Math.floor(timeLeft / 60000);
                    const secondsLeft = Math.floor((timeLeft % 60000) / 1000);
                    setExamTimer(`${minutesLeft}:${secondsLeft.toString().padStart(2, '0')}`);
                }
            }, 1000);

            setExamTimerInterval(newInterval);
            setExamTimeLimit(timeInMs);
        }
    };

    // Add this useEffect to clean up the timer
    useEffect(() => {
        return () => {
            clearExamTimer();
        };
    }, []);

    // Add this function near your other function declarations
    const clearExamTimer = () => {
        if (examTimerInterval) {
            clearInterval(examTimerInterval);
            setExamTimerInterval(null);
        }
        setExamTimer(null);
        setExamTimeLimit(null);
    };

    return (
        <>
            <PageLayout>
                <div className="course-page">
                    {examTimer && (
                        <div
                            className="exam-timer"
                            style={{
                                position: 'fixed',
                                top: '20px',
                                right: '20px',
                                background: '#0b4374',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                zIndex: 1000,
                                fontWeight: 'bold',
                            }}
                        >
                            Time Remaining: {examTimer}
                        </div>
                    )}
                    <div className="course-page__sidebar" ref={sidebarRef}>
                        <Sidebar
                            setActiveComponent={setActiveComponent}
                            activeComponent={activeComponent}
                            courseData={data}
                            setChapterData={setChapterData}
                            currentLesson={currentLesson}
                            check={check}
                            markAsCompleted={markAsCompleted}
                            unmarkComplete={unmarkComplete}
                            setCurrentLesson={setCurrentLesson}
                            setView={setView}
                            setVideoUrl={setVideoUrl}
                            setVideoEnd={setVideoEnd}
                            setIsDownloadable={setIsDownloadable}
                            setUniqueId={setUniqueId}
                            course_id={course_id}
                            course_title={data?.name}
                            setFlashcards={setFlashcards}
                            flashcards={flashcards}
                            setQuiz={setQuiz}
                            quiz={quiz}
                            setChapterId={setChapterId}
                            setLoading={setLoading}
                            loading={loading}
                            setCreateNewQuiz={setCreateNewQuiz}
                            createNewQuiz={createNewQuiz}
                            courseIsPurchased={courseIsPurchased}
                            studyGuide={studyGuide}
                            setStudyGuide={setStudyGuide}
                            studyGuideAvailable={studyGuideAvailable}
                            setStudyGuideAvailable={setStudyGuideAvailable}
                            studyGuideLoading={studyGuideLoading}
                            setStudyGuideLoading={setStudyGuideLoading}
                            user={user}
                            handleLessonClick={handleLessonClick}
                            handleTaskClick={handleTaskClick}
                            openChapters={openChapters}
                            setOpenChapters={setOpenChapters}
                            activeChapter={activeChapter}
                            setActiveChapter={setActiveChapter}
                            activeLessonID={activeLessonID}
                            setActiveLessonID={setActiveLessonID}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                            selectedChapter={selectedChapter}
                            setSelectedChapter={setSelectedChapter}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            summaryReportStats={summaryReportStats}
                            setSummaryReportStats={setSummaryReportStats}
                            isMockExam={isMockExam}
                            setIsMockExam={setIsMockExam}
                            endOfQuiz={endOfQuiz}
                            setEndOfQuiz={setEndOfQuiz}
                            mockExam={mockExam}
                            setMockExam={setMockExam}
                            mockExamAnswers={mockExamAnswers}
                            setMockExamAnswers={setMockExamAnswers}
                            setLearningStarted={setLearningStarted}
                            learningStarted={learningStarted}
                            nextDisabled={nextDisabled}
                            setNextDisabled={setNextDisabled}
                            taskIndex={taskIndex}
                            setTaskIndex={setTaskIndex}
                            clearExamTimer={clearExamTimer}
                            setMockExamCompleted={setMockExamCompleted}
                            mockExamCompleted={mockExamCompleted}
                        />
                    </div>

                    <ErrorBoundary>
                        <div
                            className="course-page__content"
                            ref={contentRef}
                            style={
                                (view == 'video' && activeComponent == 'Course') ||
                                (selectedOption == 'Flashcard' && flashcards.length > 0)
                                    ? { border: 'none' }
                                    : null
                            }
                        >
                            {/* <Suspense fallback={<Loading type="text" />}>{renderActiveComponent()}</Suspense> */}
                            {loading ? <Loading type="text" /> : renderActiveComponent()}
                            {/* {renderActiveComponent()} */}
                            <ToastContainer />
                        </div>
                    </ErrorBoundary>
                </div>
            </PageLayout>
            <>
                <animated.div
                    id="chatbot-icon"
                    style={{ transform: springProps.scale.to((s) => `scale(${s})`) }}
                    onClick={() => {
                        setOpenTutor(!openTutor);
                        set({ scale: 0.7 });
                        setTimeout(() => {
                            set({ scale: 1 });
                        }, 150);
                    }}
                >
                    {openTutor ? (
                        <animated.div style={{ ...springs }}>
                            <FaAngleDoubleDown />
                        </animated.div>
                    ) : (
                        <animated.div style={{ ...springs }}>
                            <FaRobot style={{ marginBottom: '5px' }} />
                        </animated.div>
                    )}
                </animated.div>

                {openTutor ? <TutorRevamp course_name={data?.name} /> : null}
            </>
        </>
    );
};

export default withRouter(CoursePage);
