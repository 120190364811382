import React, { useEffect, useState, useContext } from 'react';
import { getInstructorDashboardData } from '../../../services/instructors';
import { showError, utcToLocalDateTime } from '../../../Utils/helper';
import HomeIcon from '@mui/icons-material/Home';
import Loader from '../../../Common/Loader';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/AuthContext';
import FlashcardFavorites from './FlashcardFavorites';
import QuizFavorites from './QuizFavorites';
import DashboardHome from './DashboardHome';
import Course from '../Course/Course';
import Connect from '../Connect/Connect';
import './InstructorDashboard.css';
import ReportedIssues from '../ReportedIssues';
function InstructorDashboard() {
    const { t } = useTranslation();
    const { user, loadingUser } = useContext(UserContext);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('home');
    const [selectedCourse, setSelectedCourse] = useState({});
    const [reportedIssues, setReportedIssues] = useState([]);

    async function toGetDashboardData() {
        setLoading(true);
        const resp = await getInstructorDashboardData();
        if (resp.success) {
            setData(resp.data);
            let setter = []
            if (resp.data.reported_ai_quiz_issues.length > 0) {
                resp.data.reported_ai_quiz_issues.forEach(issue => {
                    setter.push(issue);
                });
            }
            if (resp.data.reported_flashcard_issues.length > 0) {
                resp.data.reported_flashcard_issues.forEach(issue => {
                    setter.push(issue);
                });
            }
            setReportedIssues(setter);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    useEffect(() => {
        toGetDashboardData();
    }, []);

    return (
        <React.Fragment>
            {loading ? (
                <Loader />
            ) : (
                <div className="instructor-dashboard_container">
                    <div className="instructor-dashboard">
                        <div className="instructor-dashboard_sidebar">
                            <h2>{t('instructorDashboard.myDashboard')}</h2>

                            <div className="instructor-dashboard_sidebar_courses">
                                <h3 className="instructor-dashboard_sidebar_courses_title">{t('instructorDashboard.myCourses')}</h3>
                                <div id="instructor-dashboard_sidebar_courses_list">
                                    {data?.recent_courses?.map((course, index) => <span onClick={() => {
                                        setSelectedCourse(course);
                                        setActiveTab('course');
                                    }} key={index}>{course.name}</span>)}
                                </div>
                            </div>

                            <h5 onClick={() => setActiveTab('connect')} className="instructor-dashboard_sidebar_connect_title">{t('instructorDashboard.connect')}</h5>

                            <h5 onClick={() => setActiveTab('reported-issues')} className="instructor-dashboard_sidebar_reported_issues_title">{t('instructorDashboard.reportedIssues')}</h5>
                        </div>

                        <div className="instructor-dashboard_content">
                            <HomeIcon className="instructor-dashboard_content_home_icon" fontSize='large' onClick={() => setActiveTab('home')}/>
                            {activeTab === 'home' && (
                                <DashboardHome 
                                    fullName={user?.full_name} 
                                    totalStudentsCount={data?.total_students_count} 
                                    totalCoursesCount={data?.courses_count} 
                                    setActiveTab={setActiveTab} 
                                />
                            )}

                            {activeTab === 'flashcard-favorites' && (
                                <FlashcardFavorites 
                                    user_id={user?.id}
                                />
                            )}

                            {activeTab === 'quiz-favorites' && (
                                <QuizFavorites 
                                    user_id={user?.id}
                                />
                            )}

                            {activeTab === 'course' && (
                                <Course 
                                    course={selectedCourse}
                                />
                            )}

                            {activeTab === 'reported-issues' && (
                                <ReportedIssues reportedIssues={reportedIssues} />
                            )}

                            {activeTab === 'connect' && (
                                <Connect />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default InstructorDashboard;