import './CourseQuizzes.css';
import { useState, useEffect } from 'react';
import { fetchQuizzesByCourseId, createQuizQuestion, deleteQuizQuestion, updateQuizQuestion } from '../../../services/instructorDashboard';
import { getCourseById } from '../../../services/instructors';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

function CourseQuizzes({ course_id }) {
    const { t } = useTranslation();
    const [newQuizQuestion, setNewQuizQuestion] = useState({
        question: '',
        answer: '',
        incorrect_answer1: '',
        incorrect_answer2: '',
        incorrect_answer3: '',
        explanation: '',
        chapter_id: 0,
        course_id: course_id
    });
    const [quizzes, setQuizzes] = useState([]);
    const [courseChapters, setCourseChapters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);

    let buttonDisabled = 
        newQuizQuestion.question === '' || 
        newQuizQuestion.answer === '' || 
        newQuizQuestion.incorrect_answer1 === '' || 
        newQuizQuestion.incorrect_answer2 === '' || 
        newQuizQuestion.incorrect_answer3 === '' || 
        newQuizQuestion.explanation === '' || 
        newQuizQuestion.chapter_id === 0 || 
        loading || 
        refreshing ? true : false;

    async function fetchCourseChapters() {
        const resp = await getCourseById(course_id);
        if (resp.success) {
            let setter = [];
            for (let i = 0; i < resp.data.chapter.length; i++) {
                let chapter = resp.data.chapter[i];
                setter.push(chapter);
            }
            setCourseChapters(setter);
            setNewQuizQuestion({
                ...newQuizQuestion,
                chapter_id: setter[0].id
            });
        }
        setLoading(false);
    }

    async function fetchQuizzes() {
        const resp = await fetchQuizzesByCourseId(course_id);

        if (resp.success) {
            console.log(resp.data)
            setQuizzes(resp.data);
            if (courseChapters.length === 0) {
                fetchCourseChapters();
            }
        }
        if (quizzes.length > 0) {
            setRefreshing(false);
        }
    }

    async function openAllQuizzes() {
        if (quizzes.length === 0) {
            await Swal.fire({
                title: t('instructorDashboard.quizQuestions'),
                text: t('instructorDashboard.noQuizzesFound'),
                confirmButtonColor: '#0B4475',
                customClass: {
                    title: 'custom-swal-title'
                }
            });
            return;
        }

        // Add these functions to handle the button clicks
        window.handleUpdateQuizQuestion = handleUpdateQuizQuestion;
        window.handleDeleteQuizQuestion = handleDeleteQuizQuestion;

        const quizHtml = quizzes.map(quiz => `
            <div class="quiz-details">
                <div class="quiz-item">
                    <strong>Chapter:</strong> 
                    <span>${quiz.chapter.name}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.question')}:</strong> 
                    <span>${quiz.question}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.correctAnswer')}:</strong> 
                    <span>${quiz.answer}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.incorrectAnswer1')}:</strong> 
                    <span>${quiz.incorrect_answer1}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.incorrectAnswer2')}:</strong> 
                    <span>${quiz.incorrect_answer2}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.incorrectAnswer3')}:</strong> 
                    <span>${quiz.incorrect_answer3}</span>
                </div>
                <div class="quiz-item">
                    <strong>${t('instructorDashboard.explanation')}:</strong> 
                    <span>${quiz.explanation}</span>
                </div>
                <div class="quiz-actions" style="display: flex; justify-content: flex-end; gap: 10px; margin-top: 10px;">
                    <button 
                        onclick='window.handleUpdateQuizQuestion(${JSON.stringify(quiz).replace(/'/g, "&apos;")})' 
                        style="background: none; border: none; cursor: pointer; color: #0B4475;"
                    >
                        <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                        </svg>
                    </button>
                    <button 
                        onclick='window.handleDeleteQuizQuestion(${JSON.stringify(quiz).replace(/'/g, "&apos;")})' 
                        style="background: none; border: none; cursor: pointer; color: #d33;"
                    >
                        <svg style="width: 24px; height: 24px;" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                        </svg>
                    </button>
                </div>
                <hr class="quiz-divider">
            </div>
        `).join('');

        await Swal.fire({
            title: t('instructorDashboard.allQuizzes'),
            html: `<div class="quiz-list-container">${quizHtml}</div>`,
            customClass: {
                popup: 'large-swal-container',
                title: 'custom-swal-title'
            },
            width: '800px',
            confirmButtonText: t('instructorDashboard.close'),
            confirmButtonColor: '#0B4475'
        });

        // Clean up the window functions
        delete window.handleUpdateQuizQuestion;
        delete window.handleDeleteQuizQuestion;
    }

    async function handleCreateQuizQuestion() {
        setRefreshing(true);
        const resp = await createQuizQuestion(newQuizQuestion);
        if (resp.success) {
            fetchQuizzes();
            setNewQuizQuestion({
                question: '',
                answer: '',
                incorrect_answer1: '',
                incorrect_answer2: '',
                incorrect_answer3: '',
                explanation: '',
                chapter_id: courseChapters[0].id,
                course_id: course_id
            });
            Swal.fire({
                title: t('instructorDashboard.success'),
                text: t('instructorDashboard.quizQuestionCreated'),
                icon: "success",
                confirmButtonColor: "#0B4475"
            });
        } else {
            setRefreshing(false);
            Swal.fire({
                title: t('instructorDashboard.error'),
                text: t('instructorDashboard.failedToCreateQuizQuestion'),
                icon: "error",
                confirmButtonColor: "#0B4475"
            });
        }
    }

    async function handleUpdateQuizQuestion(quiz) {
        const { value: formValues } = await Swal.fire({
            title: t('instructorDashboard.editQuizQuestion'),
            html: `
                <div class="input-group">
                    <label for="swal-input1">${t('instructorDashboard.question')}:</label>
                    <input id="swal-input1" class="swal2-input" value="${quiz.question}" placeholder="${t('instructorDashboard.question')}">
                </div>
                <div class="input-group">
                    <label for="swal-input2">${t('instructorDashboard.correctAnswer')}:</label>
                    <input id="swal-input2" class="swal2-input" value="${quiz.answer}" placeholder="${t('instructorDashboard.correctAnswer')}">
                </div>
                <div class="input-group">
                    <label for="swal-input3">${t('instructorDashboard.incorrectAnswer1')}:</label>
                    <input id="swal-input3" class="swal2-input" value="${quiz.incorrect_answer1}" placeholder="${t('instructorDashboard.incorrectAnswer1')}">
                </div>
                <div class="input-group">
                    <label for="swal-input4">${t('instructorDashboard.incorrectAnswer2')}:</label>
                    <input id="swal-input4" class="swal2-input" value="${quiz.incorrect_answer2}" placeholder="${t('instructorDashboard.incorrectAnswer2')}">
                </div>
                <div class="input-group">
                    <label for="swal-input5">${t('instructorDashboard.incorrectAnswer3')}:</label>
                    <input id="swal-input5" class="swal2-input" value="${quiz.incorrect_answer3}" placeholder="${t('instructorDashboard.incorrectAnswer3')}">
                </div>
                <div class="input-group">
                    <label for="swal-input6">${t('instructorDashboard.explanation')}:</label>
                    <input id="swal-input6" class="swal2-input" value="${quiz.explanation}" placeholder="${t('instructorDashboard.explanation')}">
                </div>
            `,
            focusConfirm: false,
            customClass: {
                popup: 'large-swal-container',
                input: 'large-swal-input',
                title: 'custom-swal-title'
            },
            width: '800px',
            showCancelButton: true,
            confirmButtonText: t('instructorDashboard.confirm'),
            confirmButtonColor: '#0B4475',
            cancelButtonColor: '#d33',
            preConfirm: () => {
                return [
                    document.getElementById("swal-input1").value,
                    document.getElementById("swal-input2").value,
                    document.getElementById("swal-input3").value,
                    document.getElementById("swal-input4").value,
                    document.getElementById("swal-input5").value,
                    document.getElementById("swal-input6").value
                ];
            }
        });
        
        if (formValues) {
            setRefreshing(true);
            const resp = await updateQuizQuestion(quiz.id, {
                question: formValues[0],
                answer: formValues[1],
                incorrect_answer1: formValues[2],
                incorrect_answer2: formValues[3],
                incorrect_answer3: formValues[4],
                explanation: formValues[5]
            });
            if (resp.success) {
                fetchQuizzes();
                Swal.fire({
                    title: t('instructorDashboard.success'),
                    text: t('instructorDashboard.quizQuestionUpdated'),
                    icon: "success",
                    confirmButtonColor: "#0B4475"
                });
            }
        }
    }

    async function handleDeleteQuizQuestion(quiz) {
        Swal.fire({
            title: t('instructorDashboard.areYouSure'),
            text: t('instructorDashboard.youWontBeAbleToRevertThis'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0B4475",
            cancelButtonColor: "#d33",
            confirmButtonText: t('instructorDashboard.confirm')
        }).then(async(result) => {
            if (result.isConfirmed) {
                setRefreshing(true);
                const resp = await deleteQuizQuestion(quiz.id);
                if (resp.success) {
                    fetchQuizzes();
                    Swal.fire({
                        title: t('instructorDashboard.deleted'),
                        text: t('instructorDashboard.quizQuestionDeleted'),
                        icon: "success",
                        confirmButtonColor: "#0B4475"
                    });
                }
            }
        });
    }

    useEffect(() => {
        fetchQuizzes();
    }, []);

    return (
        <div className="course-quizzes">
            <h1>{t('instructorDashboard.createQuizQuestion')}</h1>

            <div className="quiz-create">
                <div className="quiz-create-question">
                    <span>{t('instructorDashboard.q')}: </span>
                    <input 
                        type="text" 
                        placeholder={t('instructorDashboard.question')} 
                        value={newQuizQuestion.question}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            question: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-correct-answer">
                    <span>✓: </span>
                    <input 
                        type="text" 
                        placeholder="Correct Answer" 
                        value={newQuizQuestion.answer}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            answer: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-incorrect-answer">
                    <span>✗: </span>
                    <input 
                        type="text" 
                        placeholder="Incorrect Answer 1" 
                        value={newQuizQuestion.incorrect_answer1}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            incorrect_answer1: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-incorrect-answer">
                    <span>✗: </span>
                    <input 
                        type="text" 
                        placeholder="Incorrect Answer 2" 
                        value={newQuizQuestion.incorrect_answer2}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            incorrect_answer2: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-incorrect-answer">
                    <span>✗: </span>
                    <input 
                        type="text" 
                        placeholder="Incorrect Answer 3" 
                        value={newQuizQuestion.incorrect_answer3}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            incorrect_answer3: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-explanation">
                    <span>{t('instructorDashboard.e')}: </span>
                    <input 
                        type="text" 
                        placeholder={t('instructorDashboard.explanation')} 
                        value={newQuizQuestion.explanation}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            explanation: e.target.value
                        })}
                    />
                </div>
                <div className="quiz-create-footer">
                    <select
                        value={newQuizQuestion.chapter_id}
                        onChange={(e) => setNewQuizQuestion({
                            ...newQuizQuestion,
                            chapter_id: parseInt(e.target.value)
                        })}
                    >
                        {loading ? (
                            <option>{t('instructorDashboard.loadingChapters')}</option>
                        ) : (
                            courseChapters.map((chapter, index) => (
                                <option value={chapter.id} key={index}>
                                    {chapter.name}
                                </option>
                            ))
                        )}
                    </select>
                    <button disabled={buttonDisabled} onClick={handleCreateQuizQuestion} style={{ backgroundColor: buttonDisabled ? 'grey' : '#0B4475' }}>{t('instructorDashboard.create')}</button>
                </div>
            </div>
            
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button 
                    onClick={openAllQuizzes} 
                    disabled={loading || refreshing} 
                    style={{ backgroundColor: loading || refreshing ? 'grey' : '#0B4475' }} 
                    className="fetch-quizzes-button"
                >
                    {t('instructorDashboard.allQuizzes')}
                </button>
                {(loading || refreshing) && (
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        height: '100%',
                        marginTop: '30px'
                    }}>
                        <CircularProgress />
                    </Box>
                )}
            </div>
        </div>
    );
}

export default CourseQuizzes;