/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { userActions } from '../../containers/user/actions/me';
import { countryAction } from '../../containers/Utils/actions/country';
import { profileActions } from '../../containers/profile/actions/profile';
import { useDispatch, useSelector } from 'react-redux';
import { CartContext } from '../context/CartContext';
import PageLayout from '../Layouts/PageLayout';
import { showSuccess, showError } from '../Utils/helper';
import { ToastContainer } from 'react-toastify';
import Select, { createFilter } from 'react-select';
import { messages } from '../../constants/message';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Common/Loader';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ResetPasswordModal from '../modals/ResetPassword';

function Profile() {
    const { t, i18n } = useTranslation();
    const [file, setFile] = useState(null);
    const hiddenFileInput = React.useRef(null);
    const [profile, setProfile] = useState({
        id: '',
        first_name: '',
        last_name: '',
        country_id: '',
        mobile_number: '',
        email: '',
        img_path: '',
        thumbnail: '',
        active: '',
        email_verified_at: '',
        password: '',
        otp: '',
        otp_expiry: '',
        remember_token: '',
        created_at: '',
        updated_at: '',
        deleted_at: '',
        about_us: '',
        cv_file: '',
        cv_ext: '',
        country_code: '+', // Default country code
    });
    const dispatch = useDispatch();
    const me = useSelector((state) => state.me);
    const profile_reducer = useSelector((state) => state.profile);
    const country = useSelector((state) => state.country);
    const [countryName, setCountryName] = useState({
        value: 112,
        label: 'Kuwait',
    });
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [countryError, setCountryError] = useState(null);
    const [aboutMeError, setAboutMeError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [cvError, setCVError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = (oldPassword, newPassword) => {
        console.log('Old Password:', oldPassword);
        console.log('New Password:', newPassword);
        // Add your password reset logic here
    };

    const { setCartCount, setCartData } = useContext(CartContext);

    useEffect(() => {
        dispatch(userActions.getUser());
        dispatch(countryAction.getCountry('student'));
    }, []);

    useEffect(() => {
        if (!me.loading && !me.error && !error && !country.loading) {
            setProfile((prevProfile) => ({
                ...prevProfile,
                ...me.user.data,
                country_code: me.user.data.country_code || prevProfile.country_code,
            }));
            setError(true);
            let prefilledCountry = country.country.find((item) => item.value === me.user.data.country_id);

            prefilledCountry = {
                label: t('countries.' + prefilledCountry.label, prefilledCountry.label),
                value: prefilledCountry.value,
            };
            setCountryName(prefilledCountry);
        }
    }, [me, country]);

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = function () {
            setFile(reader.result);
        };
        reader.readAsDataURL(file);
    };

    function handleLoginChange(e) {
        const { name, value } = e.target;
        setProfile((inputs) => ({ ...inputs, [name]: value }));
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    function handleFileChange(e) {
        const { name, files, value } = e.target;
        let fileExt = value.split('.');
        let fileExtReverse = fileExt.reverse();
        setProfile((inputs) => ({ ...inputs, cv_ext: fileExtReverse[0] }));
        getBase64(files[0]).then((data) => {
            setProfile((inputs) => ({ ...inputs, [name]: data }));
        });
    }

    useEffect(() => {
        if (!profile_reducer.error && !profile_reducer.loading && !profile_reducer.data) {
            showSuccess(t('alertMessage.profileUpdatedSuccess'));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }, [profile_reducer]);

    function updateProfile() {
        let count = 0;
        let cvFormats = ['pdf', 'doc', 'docx'];
        setFirstNameError('');
        setLastNameError('');
        setMobileError('');
        setAboutMeError('');

        if (profile.first_name?.trim().length <= 2) {
            setFirstNameError(messages.FNAME_ERROR);
            count++;
        } else {
            setFirstNameError(null);
        }
        if (profile.last_name?.length <= 2) {
            setLastNameError(messages.LNAME_ERROR);
            count++;
        } else {
            setLastNameError(null);
        }
        if (countryName === undefined) {
            setCountryError('Choose a country');
            count++;
        } else {
            setCountryError(null);
        }

        if (profile.country_code === null || profile.country_code?.trim().length <= 0) {
            setMobileError('Select country code');
            count++;
        } else {
            setMobileError(null);
        }

        if (profile.mobile_number === null || profile.mobile_number.trim().length <= 0) {
            setMobileError('Enter a valid mobile number');
            count++;
        } else {
            setMobileError(null);
        }

        if (!profile.about_us && me.user.data.type === 'instructor') {
            setAboutMeError('Please enter about us details');
            count++;
        } else {
            setAboutMeError(null);
        }

        if (count === 0) {
            delete profile.thumbnail;
            const obj = {
                ...profile,
                thumbnail: file !== null ? file : profile.img_path,
                country_id: countryName.value,
            };
            delete obj.id;
            delete obj.otp_expiry;
            delete obj.active;
            delete obj.created_at;
            delete obj.deleted_at;
            delete obj.email_verified_at;
            delete obj.otp;
            delete obj.remember_token;
            delete obj.password;
            delete obj.updated_at;
            delete obj.type;
            delete obj.img_path;

            dispatch(profileActions.updateProfile(obj, setCartCount, setCartData, me));
        }
    }

    const customStyles = {
        padding: '5px 0px',
        borderRadius: '11px',
        borderColor: 'red',
    };

    const currentYear = new Date().getFullYear();
    const futureYearsRange = 5000; // Number of future years to include
    const years = Array.from({ length: currentYear + futureYearsRange - 1985 + 1 }, (_, i) => ({
        label: (1985 + i).toString(),
        value: 1985 + i,
    }));

    return (
        <>
            {!me.loading && !me.error ? (
                <PageLayout me={me}>
                    <div className="row mb-5 mx-0">
                        <div className="col-12 profile_header d-none d-md-flex"></div>
                    </div>

                    <section className="profile-container container" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                        <div className="row mb-0 mb-md-5 justify-content-between">
                            <div className="col-12 mb-md-0 mb-5 col-md-3 profile_header">
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={hiddenFileInput}
                                    onChange={handleChange}
                                />
                                {file || me?.user?.data?.img_path ? (
                                    <img
                                        src={file !== null ? file : process.env.REACT_APP_ASSETS + profile.img_path}
                                        className="upload_circle"
                                        onClick={handleClick}
                                        alt=""
                                    />
                                ) : (
                                    <div className="upload_circle" onClick={handleClick}></div>
                                )}
                            </div>
                            <div className="col-12 col-md-9">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.firstName')}</label>
                                        <input
                                            type="text"
                                            className="form-control profile_input"
                                            value={profile?.first_name}
                                            onChange={handleLoginChange}
                                            name="first_name"
                                            style={{
                                                border: firstNameError ? '1px solid red' : '1px solid #1c1c1c',
                                            }}
                                        />
                                        {firstNameError && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{firstNameError}</p>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.lastName')}</label>
                                        <input
                                            type="text"
                                            className="form-control profile_input"
                                            value={profile?.last_name}
                                            onChange={handleLoginChange}
                                            name="last_name"
                                            style={{
                                                border: lastNameError ? '1px solid red' : '1px solid #1c1c1c',
                                            }}
                                        />
                                        {lastNameError && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{lastNameError}</p>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.selectYourCountry')}</label>
                                        <Select
                                            name="form-field-name"
                                            className="react_select"
                                            value={countryName}
                                            onChange={(selected) => {
                                                setCountryName(selected);
                                            }}
                                            options={country.country.map((item) => ({
                                                label: t('countries.' + item.label, item.label),
                                                value: item.value,
                                            }))}
                                            styles={customStyles}
                                            isClearable
                                            isSearchable
                                            filterOption={createFilter({ ignoreAccents: false })}
                                        />
                                        {countryError && (
                                            <p style={{ color: 'red', marginTop: '5px' }}>{countryError}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.mobileNumber')}</label>
                                        <div className="d-flex">
                                            <PhoneInput
                                                country={'us'}
                                                value={profile.mobile_number}
                                                onChange={(phone, country) => {
                                                    setProfile({
                                                        ...profile,
                                                        mobile_number: `+${phone.replace(/^\+/, '')}`, // Ensure the number starts with a '+' and remove any duplicate
                                                        country_code: `${country.dialCode}`, // Ensure country code is updated
                                                    });
                                                }}
                                                inputStyle={{
                                                    width: '100%',
                                                    borderTopRightRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}
                                            />
                                            {mobileError && (
                                                <p style={{ color: 'red', marginTop: '5px' }}>{mobileError}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.email')}</label>
                                        <input
                                            type="email"
                                            className="form-control profile_input "
                                            placeholder="jayesh@gmail.com"
                                            readOnly
                                            value={me?.user?.data?.email}
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>{t('common.credit')}</label>
                                        <input
                                            className="form-control profile_input "
                                            placeholder="0"
                                            readOnly
                                            value={me?.user?.data?.credit_wallet.credit_balance}
                                        />
                                    </div>
                                </div>

                                {me.user.data.type === 'instructor' && (
                                    <div className="row mb-0 mb-md-5">
                                        <div className="col-12 col-md-8 mb-4 mb-md-0">
                                            <label>{t('common.uploadCV')}</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                name="cv_file"
                                                onChange={handleFileChange}
                                                required
                                            />
                                            <span>
                                                <a
                                                    href={process.env.REACT_APP_ASSETS + profile.cv_path}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View CV
                                                </a>
                                            </span>
                                            {cvError && <p style={{ color: 'red', marginTop: '5px' }}>{cvError}</p>}
                                        </div>
                                    </div>
                                )}

                                {me.user.data.type === 'instructor' && (
                                    <div className="row mb-0 mb-md-5">
                                        <div className="col-12 col-md-12 mb-4 mb-md-0">
                                            <label>About me</label>
                                            <textarea
                                                style={{
                                                    border: aboutMeError ? '1px solid red' : '1px solid #1c1c1c',
                                                }}
                                                value={profile?.about_us}
                                                onChange={handleLoginChange}
                                                rows="3"
                                                name="about_us"
                                                className="form-control profile_input"
                                            ></textarea>
                                            {aboutMeError && (
                                                <p style={{ color: 'red', marginTop: '5px' }}>{aboutMeError}</p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <div className="row mb-0 mb-md-5">
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>Date Of Birth</label>
                                        <input type="date" className="form-control" required />
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>University Degree</label>
                                        <input
                                            type="text"
                                            className="form-control profile_input"
                                            value={profile?.university_degree}
                                            onChange={handleLoginChange}
                                            name="university_degree"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 mb-4 mb-md-0">
                                        <label>Batch Year</label>
                                        <Select
                                            name="batch_year"
                                            className="react_select"
                                            options={years}
                                            styles={customStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb_50">
                            <div className="col-12 save-button-container d-flex justify-content-center">
                                <button className="btn_primary" onClick={updateProfile}>
                                    {t('common.save')}
                                </button>
                                {errorMsg !== '' && <p>{errorMsg}</p>}
                            </div>
                            <div>
                                <button className="reset-btn" onClick={handleOpenModal}>
                                    Reset Password
                                </button>
                                <ResetPasswordModal
                                    isOpen={isModalOpen}
                                    onClose={handleCloseModal}
                                    onSubmit={handleSubmit}
                                />
                            </div>
                        </div>
                    </section>

                    <ToastContainer />
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default Profile;
