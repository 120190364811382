import './ReportedIssues.css';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';


function ReportedIssues({ reportedIssues }) {
    const { t } = useTranslation();
    async function handleViewIssue(issue) {
        let contentHtml;
        
        if (issue.flashcard) {
            contentHtml = `
                <div class="progress-details">
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.type')}:</strong>
                        <span>${t('instructorDashboard.flashcard')}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.reason')}:</strong>
                        <span>${issue.reasons}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.course')}:</strong>
                        <span>${issue.course.name}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.chapter')}:</strong>
                        <span>${issue.chapter.name}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.question')}:</strong>
                        <span>${issue.flashcard.question}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.answer')}:</strong>
                        <span>${issue.flashcard.answer}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.upvotes')}:</strong>
                        <span>${issue.flashcard.upvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.downvotes')}:</strong>
                        <span>${issue.flashcard.downvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.created')}:</strong>
                        <span>${issue.created_at ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                    </div>
                </div>
            `;
        } else if (issue.ai_quiz) {
            contentHtml = `
                <div class="progress-details">
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.type')}:</strong>
                        <span>${t('instructorDashboard.quiz')}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.reason')}:</strong>
                        <span>${issue.reasons}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.course')}:</strong>
                        <span>${issue.course.name}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.chapter')}:</strong>
                        <span>${issue.chapter.name}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.question')}:</strong>
                        <span>${issue.ai_quiz.question}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.correctAnswer')}:</strong>
                        <span>${issue.ai_quiz.answer}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer1')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer1}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer2')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer2}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer3')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer3}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.explanation')}:</strong>
                        <span>${issue.ai_quiz.explanation}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.upvotes')}:</strong>
                        <span>${issue.ai_quiz.upvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.downvotes')}:</strong>
                        <span>${issue.ai_quiz.downvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.created')}:</strong>
                        <span>${issue.created_at ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                    </div>
                </div>
            `;
        }

        await Swal.fire({
            title: t('instructorDashboard.reportedIssueDetails'),
            html: contentHtml,
            customClass: {
                popup: 'large-swal-container',
                title: 'custom-swal-title'
            },
            width: '800px',
            confirmButtonText: t('instructorDashboard.close'),
            confirmButtonColor: '#0B4475'
        });
    }

    return (
        <div className="all-reported-issues-container">
            <h1>{t('instructorDashboard.allReportedIssues')}</h1>
            
            {reportedIssues.length > 0 ? (
                <div className="reported-issues-container">
                    {reportedIssues
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((issue, index) => (
                            <div className="reported-issue" key={index}>
                                <span className="reported-issue-user">{issue.user.first_name} {issue.user.last_name}</span>
                                <span className="reported-issue-date">{issue.created_at !== null ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                                <span className="reported-issue-type">{issue.flashcard ? t('instructorDashboard.flashcard') : t('instructorDashboard.quiz')}</span>
                                <button className="reported-issue-view" onClick={() => handleViewIssue(issue)}>{t('instructorDashboard.view')}</button>
                            </div>
                        ))}
                </div>
            ) : (
                <div className="reported-issues-empty">
                    <h3>{t('instructorDashboard.noReportedIssuesFound')}</h3>
                </div>
            )}
        </div>
    );
}

export default ReportedIssues;