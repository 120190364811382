import { getAuthDataCall, postAuthDataCall, putAuthDataCall, deleteAuthDataCall } from '../utils';

// Study Guides

export const getStudyGuidesByUserId = async (userId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/${userId}`);
    return resp;
};

export const getFullStudyGuidesByUserId = async (userId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/details/${userId}`);
    return resp;
};

export const getFullStudyGuideByCourseId = async (userId, courseId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/details/${userId}/course?course_id=${courseId}`);
    return resp;
};

// export const getAllStudyGuides = async () => {
//     const resp = await getAuthDataCall(`/auth/study_guides`);
//     return resp;
// };

export const createStudyGuide = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides`, payload);
    return resp;
};

export const updateStudyGuide = async (studyGuideId, payload) => {
    const resp = await putAuthDataCall(`/auth/study_guides/${studyGuideId}`, payload);
    return resp;
};

export const deleteStudyGuide = async (studyGuideId) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/${studyGuideId}`);
    return resp;
};

// ------------------------------------------------------------

// Study Guide Tasks

export const getStudyGuideTasks = async (studyGuideId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/tasks/${studyGuideId}`);
    return resp;
};

export const createStudyGuideTask = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/tasks`, payload);
    return resp;
};

export const createBulkStudyGuideTasks = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/tasks/bulk`, payload);
    return resp;
};

export const updateStudyGuideTask = async (taskId, payload) => {
    const resp = await putAuthDataCall(`/auth/study_guides/tasks/${taskId}`, payload);
    return resp;
};

export const deleteStudyGuideTask = async (taskId) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/tasks/${taskId}`);
    return resp;
};

export const resetStudyGuideTasksByChapterId = async (user_id, chapter_id) => {
    const resp = await putAuthDataCall(`/auth/study_guides/tasks/reset-chapter/${user_id}/${chapter_id}`);
    return resp;
}

// ------------------------------------------------------------

// Study Guide Assessments

export const getStudyGuideAssessments = async (studyGuideId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/assessments/${studyGuideId}`);
    return resp;
};

export const createStudyGuideAssessment = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/assessments`, payload);
    return resp;
};

export const updateStudyGuideAssessment = async (assessmentId, payload) => {
    const resp = await putAuthDataCall(`/auth/study_guides/assessments/${assessmentId}`, payload);
    return resp;
};

export const deleteStudyGuideAssessment = async (assessmentId) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/assessments/${assessmentId}`);
    return resp;
};

// ------------------------------------------------------------

// Study Guide Assessment Questions

export const getStudyGuideAssessmentQuestions = async (assessmentId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/assessments/questions/${assessmentId}`);
    return resp;
};

export const createStudyGuideAssessmentQuestion = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/assessments/questions`, payload);
    return resp;
};

export const createBulkStudyGuideAssessmentQuestions = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/assessments/questions/bulk`, payload);
    return resp;
};

export const updateStudyGuideAssessmentQuestion = async (questionId, payload) => {
    const resp = await putAuthDataCall(`/auth/study_guides/assessments/questions/${questionId}`, payload);
    return resp;
};

export const deleteStudyGuideAssessmentQuestion = async (questionId) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/assessments/questions/${questionId}`);
    return resp;
};

// ------------------------------------------------------------

// Study Guide Assessment Answers

export const getStudyGuideAssessmentAnswers = async (questionId) => {
    const resp = await getAuthDataCall(`/auth/study_guides/assessments/answers/${questionId}`);
    return resp;
};

export const createStudyGuideAssessmentAnswer = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/assessments/answers`, payload);
    return resp;
};

export const createBulkStudyGuideAssessmentAnswers = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/assessments/answers/bulk`, payload);
    return resp;
};

export const updateStudyGuideAssessmentAnswer = async (answerId, payload) => {
    const resp = await putAuthDataCall(`/auth/study_guides/assessments/answers/${answerId}`, payload);
    return resp;
};

export const deleteStudyGuideAssessmentAnswer = async (answerId) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/assessments/answers/${answerId}`);
    return resp;
};

// ------------------------------------------------------------

// Favorites

export const getFavorites = async (userId) => {
    const resp = await getAuthDataCall(`/auth/flashcards/favorites/${userId}`);
    const resp2 = await getAuthDataCall(`/auth/ai_quizzes/favorites/${userId}`);
    return {resp, resp2};
};

export const deleteFavoriteFlashcard = async (favoriteId) => {
    const resp = await deleteAuthDataCall(`/auth/flashcards/favorites/${favoriteId}`);
    return resp;
};

export const deleteFavoriteQuiz = async (favoriteId) => {
    const resp = await deleteAuthDataCall(`/auth/ai_quizzes/favorites/${favoriteId}`);
    return resp;
};

export const rateFavorite = async (payload) => {
    const resp = await postAuthDataCall(`/auth/study_guides/ratings/`, payload);
    return resp;
};

// ------------------------------------------------------------

// Chapter feedback

export const submitChapterFeedback = async (payload) => {
    const resp = await postAuthDataCall(`/auth/chapter-feedback`, payload);
    return resp;
};