import './CourseHome.css';
import { useTranslation } from 'react-i18next';


function CourseHome({ setActiveTab }) {
    const { t } = useTranslation();
    return (
        <div className="course-home">
            {/* <button onClick={() => setActiveTab('materials')}>Course Materials</button> */}
            <button onClick={() => setActiveTab('flashcards')}>{t('instructorDashboard.flashcards')}</button>
            <button onClick={() => setActiveTab('quizzes')}>{t('instructorDashboard.quizzes')}</button>
            <button onClick={() => setActiveTab('student-progress')}>{t('instructorDashboard.studentProgress')}</button>
            <button onClick={() => setActiveTab('reported-issues')}>{t('instructorDashboard.reportedIssues')}</button>
        </div>
    );
}

export default CourseHome;