// Import React and necessary hooks
import React, { useState, useContext, useEffect, useRef } from 'react';

// Import animation libraries
import { animated, useSpring, useSprings } from '@react-spring/web';

// Import routing components
import { Redirect, withRouter, useLocation } from 'react-router-dom';

// Import UI components
import Modal from 'react-modal';
import PageLayout from '../Layouts/PageLayout';
import Loader from '../Common/Loader';
import { ToastContainer } from 'react-toastify';

// Import audio and video player components
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import 'video-react/dist/video-react.css'; // Import video player styles
import {
    Player,
    ControlBar,
    PlayToggle,
    ReplayControl,
    ForwardControl,
    BigPlayButton,
    PlaybackRateMenuButton,
} from 'video-react';

// Import services for API calls
import { getCourseById, lessonCompletionUpdate } from '../services/student';
import { postComments } from '../services/discussionForum';
import getTranslationFromMany from '../../services/helper'

// Import utility functions
import { showError, showSuccess } from '../Utils/helper';
import _uniqueId from 'lodash/uniqueId';

// Import context for user authentication
import { UserContext } from '../context/AuthContext';

// Import translation hook
import { useTranslation } from 'react-i18next';

// Import components for the course
import PDFReader from '../pdfReader';
import Quiz from './Quiz';
import LiveSession from './LiveSession';
import Tutor from '../AI/Tutor';
import TutorRevamp from '../AI/TutorRevamp';
import AiFeatures from '../AI/AiFeatures';

// Import accordion components for UI
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Import icons
import { BsChatTextFill } from 'react-icons/bs';
import { FaAngleDoubleDown } from 'react-icons/fa';
import { FaRobot } from 'react-icons/fa';

// Import demo styles for the accordion
import 'react-accessible-accordion/dist/fancy-example.css';

const CourseLearning = (props) => {
    // Translation hook for internationalization
    const { t, i18n } = useTranslation();

    // Create a ref to hold a reference to the player component
    // This allows direct access to the player instance for controlling playback, seeking, etc.
    const playerRef = useRef(null);

    // Access user authentication context
    const { loggedIn } = useContext(UserContext);

    // State variables
    const [modalIsOpen, setIsModalOpen] = useState(false); // Modal open/close state
    const [data, setData] = useState([]); // Data for the course
    const [loading, setLoading] = useState(false); // Loading state
    const [videoUrl, setVideoUrl] = useState(''); // URL for the video
    const [view, setView] = useState(''); // Current view type (video, quiz, etc.)
    const [videoState, setVideoState] = useState(null); // State of the video player
    const [currentLesson, setCurrentLesson] = useState(null); // Current lesson data
    const [counter, setCounter] = useState(false); // Counter state
    const [videoEnd, setVideoEnd] = useState(false); // Video end state
    const [check, setCheck] = useState([]); // Array to track completed lessons
    const [question, setQuestion] = useState(''); // Question to ask instructor
    const course_id = props.location.state; // Course ID from props
    const [isDownloadable, setIsDownloadable] = useState(false); // Downloadable state
    const [uniqueId, setUniqueId] = useState(''); // Unique ID for components
    const [instructorToAsk, setInstructorToAsk] = useState(null); // Selected instructor for questions
    const [height, setHeight] = useState(0); // Height for layout adjustments
    const heightRef = useRef(null); // Ref for height measurement
    const [openTutor, setOpenTutor] = useState(false); // Tutor visibility state
    const { setChatbotAvailable } = props; // Function to set chatbot availability



    // Styles for AI badge
    const AIBadgeStyles = {
        color: 'white',
        borderRadius: '16px',
        fontSize: '15px',
        background: 'linear-gradient(90deg, rgba(148,0,173,1) 0%, rgba(230,157,108,1) 100%)',
        padding: '0px 10px 0px 10px',
        marginLeft: '10px',
    };

    // Effect to manage chatbot availability
    // useEffect(() => {
    //     setChatbotAvailable(false);
    //     return () => {
    //         setChatbotAvailable(true);
    //     };
    // }, []);

    // Function to open the modal
    function openModal() {
        setIsModalOpen(true);
    }

    // Function to close the modal
    function closeModal() {
        setIsModalOpen(false);
    }

    // Effect to redirect if not logged in
    useEffect(() => {
        if (!loggedIn) {
            <Redirect to="/" />;
        }
    }, [loggedIn]);

    // Function to fetch course data by ID
    async function toGetCourseById() {
        setLoading(true);
        const resp = await getCourseById(course_id);
        if (resp.success) {
            setData(resp.data);
            if (resp?.data?.chapter[0]?.lessons[0]?.media[0]?.is_downloadable === 1) {
                setIsDownloadable(true);
            }
            setVideoUrl(resp?.data?.chapter[0]?.lessons[0]?.media[0]?.url);
            setCurrentLesson(resp?.data?.chapter[0]?.lessons[0]);
            setView(
                resp?.data?.chapter[0]?.lessons[0]?.quiz?.length > 0
                    ? 'quiz'
                    : resp?.data?.chapter[0]?.lessons[0]?.media[0]?.media_type
            );
            setLoading(false);
        } else {
            showError('Something went wrong');
            setLoading(false);
        }
    }

    // Effect to prevent right-click context menu and fetch course data
    useEffect(() => {
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        });
        toGetCourseById();
    }, []);

    // Effect to subscribe to video player state changes
    useEffect(() => {
        if (playerRef.current) {
            playerRef.current.subscribeToStateChange((e) => {
                setVideoState(e);
            });
        }
    });

    // Effect to handle video completion
    useEffect(() => {
        if (videoState && videoState?.ended && !videoEnd) {
            lessonCompletionUpdateApi();
            setVideoEnd(true);
        }
    }, [videoState]);

    // Effect to set height based on a reference element
    useEffect(() => {
        if (heightRef.current) {
            setHeight(heightRef.current.clientHeight);
        }
    });

    // Function to update lesson completion status
    async function lessonCompletionUpdateApi() {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: currentLesson?.chapter_id,
            lesson_id: currentLesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCounter(false);
            setCheck([...check, resp?.data?.lesson_id]);
            // showSuccess(t('alertMessage.lessonCompletedSuccess'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to mark a lesson as completed
    async function markAsCompleted(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck([...check, resp?.data?.lesson_id]);
            showSuccess(t('alertMessage.lessonCompleted'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to unmark a lesson as completed
    async function unmarkComplete(_lesson) {
        let payload = {
            course_id: parseInt(course_id),
            chapter_id: _lesson?.chapter_id,
            lesson_id: view === 'quiz' ? _lesson?.id : _lesson?.media[0]?.lesson_id,
        };
        const resp = await lessonCompletionUpdate(payload);
        if (resp.success) {
            setCheck(check.filter((item) => item !== resp?.data?.lesson_id));
            showSuccess(t('alertMessage.success'));
        } else {
            showError(t('alertMessage.wrong'));
        }
    }

    // Function to ask a doubt to the instructor
    async function askDoubt() {
        if (question.length > 0) {
            const resp = await postComments(instructorToAsk, {
                comment: question,
            });
            if (resp.success) {
                showSuccess(t('alertMessage.qAskedSuccess'));
                closeModal();
            } else {
                showError(t('alertMessage.wrong'));
            }
        }
    }

    // Function to handle instructor selection for questions
    const handleChooseQAInstructor = (event) => {
        setInstructorToAsk(event.target.value);
    };

    // Animation for the tutor icon
    const springs = useSpring({
        transform: openTutor ? 'rotate(360deg)' : 'rotate(0deg)',
    });

    // Spring properties for scaling
    const [springProps, set] = useSpring(() => ({ scale: 1 }));

    // console.log('course data', common.overview);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <PageLayout>
                        <div className="container-fluid ptb_50" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                            <h1 className="page_title text-left">{data?.name}</h1>
                            {/* <h2 className="topic_name">Course Topic Name</h2> */}
                            <div className="course_wrapper" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                {view === 'video' ? (
                                    <div className="course_video">
                                        <Player src={process.env.REACT_APP_CONTENT_URL + videoUrl} ref={playerRef}>
                                            <BigPlayButton position="center" />
                                            <ControlBar autoHide={false}>
                                                <PlayToggle />
                                                <ReplayControl seconds={10} order={2.2} />
                                                <ForwardControl seconds={10} order={3.2} />
                                                <PlaybackRateMenuButton rates={[2, 1, 1.75, 1.5]} />
                                            </ControlBar>
                                        </Player>
                                    </div>
                                ) : view === 'pdf' ? (
                                    <PDFReader videoUrl={videoUrl} isDownloadable={isDownloadable} />
                                ) : view === 'image' ? (
                                    <img
                                        src={process.env.REACT_APP_CONTENT_URL + videoUrl}
                                        class="responsive-image"
                                        alt={data?.name}
                                    />
                                ) : view === 'audio' ? (
                                    <div className="course_video">
                                        <AudioPlayer
                                            autoPlay
                                            src={process.env.REACT_APP_ASSETS + videoUrl}
                                            onEnded={() => lessonCompletionUpdateApi()}
                                            showSkipControls={false}
                                            loop={false}
                                        />
                                    </div>
                                ) : view === 'live-session' ? (
                                    <>
                                        <LiveSession
                                            name={currentLesson?.name}
                                            url={currentLesson?.live_session[0]?.URL}
                                            date={currentLesson?.live_session[0]?.session_date}
                                        />
                                    </>
                                ) : (
                                    <Quiz
                                        key={uniqueId}
                                        quiz={currentLesson}
                                        course_id={course_id}
                                        markAsCompleted={markAsCompleted}
                                        uniqueId={uniqueId}
                                    />
                                )}

                        
                                {/* testing below this line */}
                                <div className="course_overview">
                                    <div className="mt_20">
                                        <ul
                                            className="nav nav-tabs mb_50"
                                            id="myTab"
                                            role="tablist"
                                            // style={{flexWrap: 'nowrap'}}
                                        >
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link active"
                                                    id="Overview-tab"
                                                    data-toggle="tab"
                                                    href="#Overview"
                                                    role="tab"
                                                    aria-controls="Overview"
                                                    aria-selected="true"
                                                >
                                                    {t('common.overview')}
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    className="nav-link"
                                                    id="courseContent-tab"
                                                    data-toggle="tab"
                                                    href="#courseContent"
                                                    role="tab"
                                                    aria-controls="courseContent"
                                                    aria-selected="false"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {t('course.courseContent')}
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="ace-tab"
                                                    data-toggle="tab"
                                                    href="#ace"
                                                    role="tab"
                                                    aria-controls="ace"
                                                    aria-selected="false"
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                    >
                                                        {t('aifeatures.ace')}
                                                        <span style={AIBadgeStyles}>AI</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="connect-tab"
                                                    data-toggle="tab"
                                                    href="#connect"
                                                    role="tab"
                                                    aria-controls="connect"
                                                    aria-selected="false"
                                                >
                                                    {t('common.connect')}
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div
                                                className="tab-pane fade show active"
                                                id="Overview"
                                                role="tabpanel"
                                                aria-labelledby="Overview-tab"
                                            >
                                                <h3>{t('common.aboutCourse')}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: t('dynamic', {
                                                            en: data?.description?.replace(/['"]+/g, ''),
                                                            ar: getTranslationFromMany(
                                                                data?.translation,
                                                                'description',
                                                                data?.description?.replace(/['"]+/g, '')
                                                            ),
                                                        }),
                                                    }}
                                                />

                                                <h3 className="mb_10 mt_20">{t('common.learningObjectives')}</h3>
                                                <ul className="circle_tick_list">
                                                    {data?.course_objectives?.map((obj) => (
                                                        <li key={obj?.id}>
                                                            {t('dynamic', {
                                                                en: obj?.objective,
                                                                ar: obj?.translation?.value
                                                                    ? obj?.translation?.value
                                                                    : obj?.objective,
                                                            })}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="courseContent"
                                                role="tabpanel"
                                                aria-labelledby="courseContent-tab"
                                            >
                                                <Accordion>
                                                    {data?.chapter?.map((_chapter) => {
                                                        return (
                                                            <AccordionItem uuid={_chapter?.id}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {t('dynamic', {
                                                                            en: _chapter?.name,
                                                                            ar: _chapter?.translation?.value
                                                                                ? _chapter?.translation?.value
                                                                                : _chapter?.name,
                                                                        })}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="accordion-item-panel">
                                                                    {_chapter?.lessons?.map((_lesson) => {
                                                                        return (
                                                                            <div
                                                                                className={`topic_row ptb_10 plr_30 ${
                                                                                    currentLesson?.id === _lesson?.id
                                                                                        ? 'active_chapter'
                                                                                        : 'inactive'
                                                                                }`}
                                                                            >
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    id={_lesson?.id}
                                                                                    checked={
                                                                                        _lesson?.student_update_lesson !==
                                                                                            null ||
                                                                                        check.includes(_lesson?.id)
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            document.getElementById(
                                                                                                _lesson?.id
                                                                                            ).checked !== false
                                                                                        ) {
                                                                                            markAsCompleted(_lesson);
                                                                                        } else {
                                                                                            unmarkComplete(_lesson);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                <div
                                                                                    className="topic_info"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            _lesson?.type === 'quiz' ||
                                                                                            _lesson?.type ===
                                                                                                'live-session'
                                                                                        ) {
                                                                                            setCurrentLesson(_lesson);
                                                                                            setView(_lesson?.type);
                                                                                        } else {
                                                                                            setVideoUrl(
                                                                                                _lesson?.media[0]?.url
                                                                                            );
                                                                                            setCurrentLesson(_lesson);
                                                                                            setVideoEnd(false);
                                                                                            setView(
                                                                                                _lesson?.media[0]
                                                                                                    .media_type
                                                                                            );
                                                                                            setIsDownloadable(
                                                                                                _lesson?.media[0]
                                                                                                    .is_downloadable
                                                                                            );
                                                                                        }
                                                                                        setUniqueId(
                                                                                            'Prefixing-' + _lesson?.id
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <h6>
                                                                                        {t('dynamic', {
                                                                                            en: _lesson?.name,
                                                                                            ar: _lesson?.translation
                                                                                                ?.value
                                                                                                ? _lesson?.translation
                                                                                                      ?.value
                                                                                                : _lesson?.name,
                                                                                        })}
                                                                                    </h6>
                                                                                    <div className="topic_time">
                                                                                       
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        );
                                                    })}
                                                </Accordion>
                                            </div>

                                            <div
                                                className="tab-pane fade"
                                                id="connect"
                                                role="tabpanel"
                                                aria-labelledby="connect-tab"
                                            >
                                                {data?.questions_answers?.length > 0 && (
                                                    <Accordion>
                                                        {data?.question_answers?.map((_item) => (
                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {_item?.question}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <p>{_item?.answer}</p>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        ))}
                                                    </Accordion>
                                                )}

                                                <div>
                                                    <div>
                                                        <div
                                                            className="lrf_modal"
                                                            dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}
                                                        >
                                                            <h2
                                                                className="text-left"
                                                                style={{
                                                                    marginBottom: '0px',
                                                                }}
                                                            >
                                                                {t('common.askYourQuestion')}
                                                            </h2>
                                                            <div className="form-group">
                                                                <div
                                                                    style={{
                                                                        padding: '10px',
                                                                    }}
                                                                >
                                                                    <select onChange={handleChooseQAInstructor}>
                                                                        <option value="" selected disabled hidden>
                                                                            Select an Instructor
                                                                        </option>
                                                                        {data?.instructors?.map((instructor) => (
                                                                            <option
                                                                                key={instructor.id}
                                                                                value={instructor.id}
                                                                            >
                                                                                {instructor.full_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="form_input">
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        placeholder={t('common.typeMessage')}
                                                                        value={question}
                                                                        onChange={(e) => setQuestion(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="course_btns mt_30"
                                                                dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}
                                                            >
                                                                <button
                                                                    className="btn_secondary big_btn"
                                                                    data-dismiss="modal"
                                                                >
                                                                    <span className="btn_text" onClick={closeModal}>
                                                                        {t('common.cancel')}
                                                                    </span>
                                                                </button>
                                                                <button
                                                                    className="btn_primary big_btn"
                                                                    onClick={() => askDoubt()}
                                                                >
                                                                    <span className="btn_text">
                                                                        {t('common.submit')}
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="ace"
                                                role="tabpanel"
                                                aria-labelledby="ace-tab"
                                            >
                                                <AiFeatures course_id={course_id} course={data?.name} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                height: height - 1000 + 'px',
                            }}
                            className="fixing_div"
                        />
                        <ToastContainer />
                    </PageLayout>
                </>
            )}
            <animated.div
                id="chatbot-icon"
                style={{ transform: springProps.scale.to((s) => `scale(${s})`) }}
                onClick={() => {
                    setOpenTutor(!openTutor);
                    set({ scale: 0.7 });
                    setTimeout(() => {
                        set({ scale: 1 });
                    }, 150);
                }}
            >
                {openTutor ? (
                    <animated.div style={{ ...springs }}>
                        <FaAngleDoubleDown />
                    </animated.div>
                ) : (
                    <animated.div style={{ ...springs }}>
                        <FaRobot style={{ marginBottom: '5px' }} />
                    </animated.div>
                )}
            </animated.div>

            {openTutor ? <TutorRevamp course_name={data?.name} /> : null}
        </>
    );
};

export default withRouter(CourseLearning);
