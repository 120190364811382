import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getStudentList } from '../../../services/discussionForum';
import { showError } from '../../../Utils/helper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { ar_messages, messages } from '../../../../constants/message';
import Chat from './Chat';
import './Connect.css';

function Connect() {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    async function getStudents() {
        setLoading(true);
        const resp = await getStudentList();
        if (resp.success) {
            setData(resp?.data?.data);
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    useEffect(() => {
        getStudents();
    }, []);

    const handleConnect = (student) => {
        setSelectedStudent(student);
    };

    const handleBack = () => {
        setSelectedStudent(null);
    };

    return (
        <div className="connect-container">
            {!selectedStudent ? (
                <>
                    <h1>{t('common.myStudents')}</h1>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {data?.length === 0 ? (
                                <div className="p-4 text-center">
                                    <h2>{i18n.language == 'ar' ? ar_messages.NO_RESULTS_FOUND : messages.NO_RESULTS_FOUND}</h2>
                                </div>
                            ) : (
                                <div className="connect-list">
                                    {data?.map((item) => (
                                        <div className="connect-item" key={item?.id}>
                                            <span className="connect-item-name">{`${item?.first_name} ${item?.last_name}`}</span>
                                            <button onClick={() => handleConnect(item)}>{t('instructorDashboard.connect')}</button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </>
            ) : (
                <div className="chat-wrapper">
                    <button 
                        onClick={handleBack}
                        className="back-button"
                    >
                        ← {t('instructorDashboard.backToStudents')}
                    </button>
                    <Chat studentData={selectedStudent} />
                </div>
            )}
        </div>
    );
}

export default Connect;