import './QuizFavorites.css';

import { useState, useEffect } from 'react';
import { getQuizFavorites } from '../../../services/instructorDashboard/index.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';


function QuizFavorites({ user_id }) {
    const { t } = useTranslation();
    const [quizFavorites, setQuizFavorites] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchQuizFavorites() {
        setLoading(true);
        const res = await getQuizFavorites(user_id);
        if (res.success) {
            let setter = [];
            for (let i = 0; i < res.data.length; i++) {
                const quiz = res.data[i].ai_quiz;
                setter.push(quiz);
            }
            setQuizFavorites(setter);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchQuizFavorites();
    }, []);

    return (
        <div className="quiz-favorites-container">
            <h1>{t('instructorDashboard.quizFavorites')}</h1>

            <div className="quiz-favorites-list">
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && quizFavorites.length === 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <h1>{t('instructorDashboard.noQuizFavoritesFound')}</h1>
                    </Box>
                )}
                {!loading && quizFavorites.length > 0 && quizFavorites.map((quiz) => (
                    <div className="quiz-favorites-item">
                        <span><span className="quiz-favorites-item-label">{t('instructorDashboard.question')}: </span>{quiz.question}</span>
                        <span className="correct-answer"><span className="quiz-favorites-item-label">{t('instructorDashboard.a')}: </span>{quiz.answer}</span>
                        <span className="incorrect-answer"><span className="quiz-favorites-item-label">{t('instructorDashboard.b')}: </span>{quiz.incorrect_answer1}</span>
                        <span className="incorrect-answer"><span className="quiz-favorites-item-label">{t('instructorDashboard.c')}: </span>{quiz.incorrect_answer2}</span>
                        <span className="incorrect-answer"><span className="quiz-favorites-item-label">{t('instructorDashboard.d')}: </span>{quiz.incorrect_answer3}</span>

                        <div className="quiz-favorites-item-votes">
                            <span><span className="quiz-favorites-item-label">{t('instructorDashboard.upvotes')}: </span>{quiz.upvotes}</span>
                            <span><span className="quiz-favorites-item-label">{t('instructorDashboard.downvotes')}: </span>{quiz.downvotes}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default QuizFavorites;