import React, { useEffect, useState, useContext } from 'react';
import { icons } from '../../constants/icons';
import PageLayout from '../../components/Layouts/PageLayout';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { getAuthDataCall, getDataCall } from '../services/utils';
import Loader from '../Common/Loader';
import { UserContext } from '../context/AuthContext';
import { Spinner, addCourseToCart, addFreeChapter, addToWishListData } from '../Utils/helper';
import { CartContext } from '../context/CartContext';
import Cookies from 'js-cookie';
import Suggestions from './Suggestions';
import DetailCard from './DetailCard';
import { ToastContainer } from 'react-toastify';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import Login from '../modals/Login';
import Signup from '../modals/Signup';
import ForgotPassword from '../modals/ForgotPassword';
import InstructorSignup from '../modals/InstructorSignup';
import browser from 'swiper/src/modules/browser/browser';
import MetaData from '../MetaData';
import { useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../services/helper';
import { useSelector } from 'react-redux';
import EmptyCartModal from '../modals/EmptyCartModal';
import SelectCountryModal from '../modals/SelectCountryModal';
import { grantFreeChapterAccess } from '../services/checkout';

function CourseDetails(props) {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState();
    const [courseSuggestionData, setCourseSuggestionData] = useState([]);
    const { user, loadingUser, loggedIn } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [loadingCart, setLoadingCart] = useState(false);
    const [freeLoading, setFreeLoading] = useState(false);
    const { cartCount, setCartCount, setCartData, cartData } = useContext(CartContext);
    const [show, setShow] = useState(false);
    let helperProps = {
        cartCount,
        setCartCount,
        setCartData,
        loggedIn,
        setLoadingCart,
        cartData,
        t,
    };
    const course_id = props.match.params.id;
    const userCountry = useSelector((state) => state.unauthedUserInfo.country);
    const userCountryLocalStorage = JSON.parse(localStorage.getItem('unauthedUserCountry'));
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [geolocationError, setGeolocationError] = useState(null);
    const [recCourses, setRecCourses] = useState(null);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
            },
            (error) => {
                setGeolocationError(error);
            }
        );
    }, []);

    const [openSelectCountryModal, closeSelectCountryModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <SelectCountryModal
                    closeModal={closeSelectCountryModal}
                    setRecCourses={setRecCourses}
                    setShow={setShow}
                />
            </ReactModal>
        );
    });

    const [openEmptyCartModal, closeEmptyCartModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <EmptyCartModal closeModal={closeEmptyCartModal} setShow={setShow} loggedIn={loggedIn} />
            </ReactModal>
        );
    });

    async function courseDetail(url) {
        setLoading(true);
        const resp = loggedIn ? await getAuthDataCall(url) : await getDataCall(url);
        if (resp.success) {
            setData(resp);
        }
        let _url;
        if (loggedIn) {
            _url = `v1/categoryCourses/${resp?.data?.category_id}`;
        } else if (userCountry !== null && userCountry.id !== null) {
            _url = `v1/categoryCourses/${resp?.data?.category_id}?countryId=${userCountry.id}`;
        } else if (userCountryLocalStorage !== null) {
            _url = `v1/categoryCourses/${resp?.data?.category_id}?countryId=${userCountryLocalStorage.id}`;
        } else if (geolocationError?.code === 1 || latitude === null || longitude === null) {
            // user denied location
            setLoading(false);
            openSelectCountryModal();
        } else {
            _url = `v1/categoryCourses/${resp?.data?.category_id}?lat=${latitude}&long=${longitude}`;
        }
        const courseSuggestion = loggedIn ? await getAuthDataCall(_url) : await getDataCall(_url);
        if (resp.success) {
            setCourseSuggestionData(courseSuggestion);
        }

        setLoading(false);
    }

    useEffect(() => {
        courseDetail(`/v1/course/${course_id}`);
    }, [course_id, user]);
    //authentication forms

    const [openLoginModal, closeLoginModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeLoginModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Login forgotOpen={openForgotPasswordModal} loginClose={closeLoginModal} signupOpen={openSignupModal} />
            </ReactModal>
        );
    });
    const [openSignupModal, closeSignupModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeSignupModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Signup closeSignup={closeSignupModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });
    const [openInstructorSignupModal, closeInstructorSignupModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeInstructorSignupModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <InstructorSignup closeSignup={closeInstructorSignupModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });
    const [openForgotPasswordModal, closeForgotPasswordModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeForgotPasswordModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <ForgotPassword closeForgot={closeForgotPasswordModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });

    const BuyChapter = (props) => {
        const { loggedIn, data, chapter, helperProps } = props;
        console.log(chapter);
        return (
            <React.Fragment>
                <button
                    className={
                        Number(chapter.price) !== undefined && Number(chapter.price) !== 0
                            ? 'btn_primary small_btn'
                            : 'btn_success small_btn'
                    }
                    onClick={async () => {
                        if (!loggedIn) {
                            openLoginModal();
                            return;
                        }
                        let payload = {
                            course_id: data?.data?.id,
                            chapter_id: chapter?.id,
                            chapters: [chapter?.id],
                        };

                        if (Number(chapter.price) === 0) {
                            setFreeLoading(chapter.id);
                            await addFreeChapter(payload, helperProps, {
                                success: t('alertMessage.freeCourseAdded'),
                                fail: t('alertMessage.failed'),
                            });
                            const resp = await getAuthDataCall(`/v1/course/${course_id}`);
                            setData(resp);
                            setFreeLoading(null);
                        } else {
                            addCourseToCart(
                                payload,
                                helperProps,
                                'loading-chapter',
                                null,
                                {
                                    addToCartSuccess: t('alertMessage.addToCartMsg'),
                                    courseExist: t('alertMessage.courseExist'),
                                },
                                openEmptyCartModal
                            );
                        }
                    }}
                >
                    {Number(chapter.price) !== undefined && Number(chapter.price) !== 0 ? (
                        data?.data?.country_id === 112 ? (
                            t('course.buy_chapter', { price: chapter.price })
                        ) : data?.data?.country_id === 17 ? (
                            t('course.buy_chapter_in_bd', {
                                price: chapter.price,
                            })
                        ) : (
                            t('course.buy_chapter_in_jd', {
                                price: chapter.price,
                            })
                        )
                    ) : freeLoading === chapter.id ? (
                        <Spinner />
                    ) : (
                        t('common.free')
                    )}
                </button>
            </React.Fragment>
        );
    };

    return (
        <>
            <MetaData
                title={data?.data?.name}
                description={t('dynamic', {
                    en: data?.data?.description?.replace(/['"]+/g, ''),
                    ar: getTranslationFromMany(
                        data?.data?.translation,
                        'description',
                        data?.data?.description?.replace(/['"]+/g, '')
                    ),
                })}
                image={process.env.REACT_APP_ASSETS + data?.data?.course_image}
            />
            {!loadingUser && !loading && data !== undefined ? (
                <PageLayout me={user}>
                    <section className="course_top ptb_50" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                        <div className="container">
                            <div className="course_inner">
                                <div className="course_intro">
                                    <h2 className="course_title mb_10">
                                        {t('dynamic', {
                                            en: data?.data?.name,
                                            ar: getTranslationFromMany(
                                                data?.data?.translation,
                                                'name',
                                                data?.data?.name
                                            ),
                                        })}
                                    </h2>
                                    {/* <h3 className="course_subtitle mb_20">{t('courseAuthors.'+data?.data?.instructor?.first_name+' '+data?.data?.instructor?.last_name, data?.data?.instructor?.first_name+' '+data?.data?.instructor?.last_name)}</h3> */}
                                    <h3 className="course_subtitle mb_20">
                                        <span>
                                            {t(
                                                'courseAuthors.' +
                                                    data?.data?.instructor?.first_name +
                                                    ' ' +
                                                    data?.data?.instructor?.last_name,
                                                data?.data?.instructor?.first_name +
                                                    ' ' +
                                                    data?.data?.instructor?.last_name
                                            )}
                                            {data?.data?.instructors?.length > 1 && ', '}
                                            {data?.data?.instructors?.map(
                                                (instructor, index) =>
                                                    instructor?.id !== data?.data?.instructor.id &&
                                                    t(
                                                        'courseAuthors.' +
                                                            instructor?.first_name +
                                                            ' ' +
                                                            instructor?.last_name,
                                                        instructor?.first_name + ' ' + instructor?.last_name
                                                    ) + (index === data?.data?.instructors?.length - 1 ? ' ' : ', ')
                                            )}
                                        </span>
                                    </h3>
                                    <p className="course_description mb_20">
                                        {t('dynamic', {
                                            en: data?.data?.name,
                                            ar: getTranslationFromMany(
                                                data?.data?.translation,
                                                'name',
                                                data?.data?.name
                                            ),
                                        })}{' '}
                                        {t('common.includes')}
                                    </p>
                                    <ul className="course_stat">
                                        {data?.data?.enrolled && data?.data?.show_students ? (
                                            <li>
                                                <figure>
                                                    <img src={icons.icon_students} alt="" />
                                                </figure>
                                                <span>
                                                    {t('common.students', {
                                                        students: data?.data?.enrolled,
                                                    })}
                                                </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {data?.data?.video_duration ? (
                                            <li>
                                                <figure>
                                                    <img src={icons.icon_video} alt="" />
                                                </figure>
                                                <span>
                                                    {t('course.course_duration', {
                                                        hours: data?.data?.video_duration?.hours,
                                                        minutes: data?.data?.video_duration?.minutes,
                                                    })}
                                                </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                        {data?.data?.exercises ? (
                                            <li>
                                                <figure>
                                                    <img src={icons.icon_exercises} alt="" />
                                                </figure>
                                                <span>
                                                    {t('course.exercises', {
                                                        exercise: data?.data?.exercises,
                                                    })}
                                                </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}

                                        {data?.data?.pdf_count ? (
                                            <li>
                                                <figure>
                                                    <img src={icons.icon_pdf} alt="" />
                                                </figure>
                                                <span>
                                                    {t('common.documents', {
                                                        documents: data?.data?.pdf_count,
                                                    })}
                                                </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}

                                        {data?.data?.audio_duration ? (
                                            <li>
                                                <figure>
                                                    <img src={icons.icon_audio_new} alt="" />
                                                </figure>
                                                <span>
                                                    {t('course.course_duration', {
                                                        hours: data?.data?.audio_duration?.hours,
                                                        minutes: data?.data?.audio_duration?.minutes,
                                                    })}
                                                </span>
                                            </li>
                                        ) : (
                                            ''
                                        )}
                                    </ul>
                                    <div className="course_btns mt_30">
                                        {user?.type === 'instructor' ? null : loadingCart !== 'wishlist-loading' ? (
                                            <button
                                                className="btn_secondary big_btn"
                                                onClick={() =>
                                                    addToWishListData(
                                                        {
                                                            course_id: data?.data?.id,
                                                        },
                                                        helperProps
                                                    )
                                                }
                                            >
                                                {/* <span className="btn_text">Add to wishlist</span> */}
                                                <div className="btn_icon">
                                                    <img src={icons.icon_heart} alt="" />
                                                </div>
                                            </button>
                                        ) : (
                                            <Spinner />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="course_bottom ptb_100" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
                        <div className="container">
                            <div className="course_details">
                                <DetailCard data={data} helperProps={helperProps} openLoginModal={openLoginModal} />
                                <div className="course_tabs pl_100">
                                    <ul className="nav nav-tabs mb_50" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link active"
                                                id="Curriculum-tab"
                                                data-toggle="tab"
                                                href="#Curriculum"
                                                role="tab"
                                                aria-controls="Curriculum"
                                                aria-selected="true"
                                            >
                                                {t('common.curriculum')}
                                            </a>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <a
                                                className="nav-link "
                                                id="Overview-tab"
                                                data-toggle="tab"
                                                href="#Overview"
                                                role="tab"
                                                aria-controls="Overview"
                                                aria-selected="false"
                                            >
                                                {t('common.overview')}
                                            </a>
                                        </li>
                                        {user?.type === 'instructor' ? null : (
                                            <li className="nav-item" role="presentation">
                                                <a
                                                    className="nav-link"
                                                    id="Instructor-tab"
                                                    data-toggle="tab"
                                                    href="#Instructor"
                                                    role="tab"
                                                    aria-controls="Instructor"
                                                    aria-selected="false"
                                                >
                                                    {t('common.instructor')}
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="Curriculum"
                                            role="tabpanel"
                                            aria-labelledby="Curriculum-tab"
                                        >
                                            <div className="curriculum">
                                                <h3 className="hide_desktop">{t('common.curriculum')}</h3>
                                                {data?.data?.chapter.map((chapter, index) => (
                                                    <div className="curriculum_row mb_20" key={index}>
                                                        <div className="curriculum_thumbnail">
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_ASSETS +
                                                                    data?.data?.course_image
                                                                }
                                                                alt=""
                                                            />
                                                            {/* <div className="play_button">
                                                                <img src={icons.icon_circle_play} alt="" />
                                                            </div> */}
                                                        </div>
                                                        <div className="curriculum_title">
                                                            <h2>
                                                                {t('dynamic', {
                                                                    en: chapter.name,
                                                                    ar: chapter?.translation?.value
                                                                        ? chapter?.translation?.value
                                                                        : chapter.name,
                                                                })}
                                                            </h2>
                                                            {chapter?.video_duration ? (
                                                                <span>
                                                                    <figure>
                                                                        <img src={icons.icon_video} alt="" />
                                                                    </figure>{' '}
                                                                    {t('course.course_duration', {
                                                                        hours: chapter?.video_duration?.hours,
                                                                        minutes: chapter?.video_duration?.minutes,
                                                                    })}
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {chapter?.audio_duration ? (
                                                                <span>
                                                                    <figure>
                                                                        <img src={icons.icon_audio_new} alt="" />
                                                                    </figure>{' '}
                                                                    {t('course.course_duration', {
                                                                        hours: chapter?.audio_duration?.hours,
                                                                        minutes: chapter?.audio_duration?.minutes,
                                                                    })}
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {chapter?.pdf_count ? (
                                                                <span>
                                                                    <figure>
                                                                        <img src={icons.icon_pdf} alt="" />
                                                                    </figure>{' '}
                                                                    {t('common.documents', {
                                                                        documents: chapter?.pdf_count,
                                                                    })}{' '}
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {chapter?.exercises ? (
                                                                <span>
                                                                    <figure>
                                                                        <img src={icons.icon_exercises} alt="" />
                                                                    </figure>{' '}
                                                                    {t('course.exercises', {
                                                                        exercise: chapter?.exercises,
                                                                    })}{' '}
                                                                    <br />
                                                                </span>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                        {user?.type === 'instructor' ? null : loggedIn ? (
                                                            <>
                                                                {chapter?.user_purchased_chapter === true ? (
                                                                    <Link
                                                                        className="btn_primary small_btn"
                                                                        to={{
                                                                            pathname: '/course',
                                                                            state: data?.data?.id,
                                                                        }}
                                                                    >
                                                                        {t('common.continueLearning')}
                                                                    </Link>
                                                                ) : (
                                                                    <BuyChapter
                                                                        loggedIn={loggedIn}
                                                                        data={data}
                                                                        chapter={chapter}
                                                                        helperProps={helperProps}
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <BuyChapter
                                                                loggedIn={loggedIn}
                                                                data={data}
                                                                chapter={chapter}
                                                                helperProps={helperProps}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div
                                            className="tab-pane fade "
                                            id="Overview"
                                            role="tabpanel"
                                            aria-labelledby="Overview-tab"
                                        >
                                            <h3>{t('common.aboutCourse')}</h3>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: t('dynamic', {
                                                        en: data?.data?.description?.replace(/['"]+/g, ''),
                                                        ar: getTranslationFromMany(
                                                            data?.data?.translation,
                                                            'description',
                                                            data?.data?.description?.replace(/['"]+/g, '')
                                                        ),
                                                    }),
                                                }}
                                            />
                                            <h3 className="mb_10 mt_20">{t('common.learningObjectives')}</h3>
                                            <ul className="circle_tick_list">
                                                {data?.data?.course_objectives.map((obj) => (
                                                    <li key={obj.id}>
                                                        {t('dynamic', {
                                                            en: obj?.objective,
                                                            ar: obj?.translation?.value
                                                                ? obj?.translation?.value
                                                                : obj?.objective,
                                                        })}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        {user?.type === 'instructor' ? null : (
                                            <div
                                                className="tab-pane fade"
                                                id="Instructor"
                                                role="tabpanel"
                                                aria-labelledby="Instructor-tab"
                                            >
                                                <h3 className="hide_desktop">{t('common.instructor')}</h3>
                                                <div className="instructor p_30 mb_10">
                                                    <div className="instructor_detail mb_30">
                                                        <div className="instructor_photo">
                                                            {/* default_avatar */}

                                                            <img
                                                                src={
                                                                    data?.data?.instructor?.img_path
                                                                        ? process.env.REACT_APP_ASSETS +
                                                                          data?.data?.instructor?.img_path
                                                                        : icons.default_avatar
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="instructor_info">
                                                            <h2 className="mb_20">{`${data?.data?.instructor?.first_name} ${data?.data?.instructor?.last_name}`}</h2>
                                                            <ul className="course_stat">
                                                                <li>
                                                                    {data?.data?.enrolled &&
                                                                    data?.data?.show_students ? (
                                                                        <figure>
                                                                            <img src={icons.icon_eye_orange} alt="" />
                                                                        </figure>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                    {data?.data?.enrolled &&
                                                                    data?.data?.show_students ? (
                                                                        <span>
                                                                            {t('common.students', {
                                                                                students: data?.data?.enrolled,
                                                                            })}
                                                                        </span>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <h3 className="mb_10">
                                                        {/* Dawraty Certified Mathematics Instructor */}
                                                    </h3>
                                                    <p>{data?.data?.instructor?.about_us}</p>
                                                </div>
                                                {data?.data?.instructors?.map(
                                                    (instructor) =>
                                                        instructor.id !== data?.data?.instructor?.id && (
                                                            <div className="instructor p_30 mb_10">
                                                                <div className="instructor_detail mb_30">
                                                                    <div className="instructor_photo">
                                                                        {/* default_avatar */}

                                                                        <img
                                                                            src={
                                                                                instructor?.img_path
                                                                                    ? process.env.REACT_APP_ASSETS +
                                                                                      instructor?.img_path
                                                                                    : icons.default_avatar
                                                                            }
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="instructor_info">
                                                                        <h2 className="mb_20">{`${instructor?.first_name} ${instructor?.last_name}`}</h2>
                                                                        <ul className="course_stat">
                                                                            <li>
                                                                                {data?.data?.enrolled &&
                                                                                data?.data?.show_students ? (
                                                                                    <figure>
                                                                                        <img
                                                                                            src={icons.icon_eye_orange}
                                                                                            alt=""
                                                                                        />
                                                                                    </figure>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                                {data?.data?.enrolled &&
                                                                                data?.data?.show_students ? (
                                                                                    <span>
                                                                                        {t('common.students', {
                                                                                            students:
                                                                                                data?.data?.enrolled,
                                                                                        })}
                                                                                    </span>
                                                                                ) : (
                                                                                    ''
                                                                                )}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <h3 className="mb_10">
                                                                    {/* Dawraty Certified Mathematics Instructor */}
                                                                </h3>
                                                                <p>{instructor?.about_us}</p>
                                                            </div>
                                                        )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Suggestions data={courseSuggestionData?.data?.data} course_id={course_id} />
                    <ToastContainer />
                </PageLayout>
            ) : (
                <Loader />
            )}
        </>
    );
}

export default withRouter(CourseDetails);
