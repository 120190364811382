import './CourseReportedIssues.css';
import { fetchReportedIssuesByCourseId } from '../../../services/instructorDashboard';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';


function CourseReportedIssues({ course_id }) {
    const { t } = useTranslation();
    const [reportedIssues, setReportedIssues] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchReportedIssues() {
        const resp = await fetchReportedIssuesByCourseId(course_id);
        if (resp.flashcards.success || resp.quizzes.success) {
            let setter = [];
            if (resp.flashcards.data.length > 0) {
                setter.push(...resp.flashcards.data);
            }
            if (resp.quizzes.data.length > 0) {
                setter.push(...resp.quizzes.data);
            }
            console.log('setter', setter);
            setReportedIssues(setter);
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchReportedIssues();
    }, []);

    async function handleViewIssue(issue) {
        let contentHtml;
        
        if (issue.flashcard) {
            contentHtml = `
                <div class="progress-details">
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.type')}:</strong>
                        <span>Flashcard</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.reason')}:</strong>
                        <span>${issue.reasons}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.question')}:</strong>
                        <span>${issue.flashcard.question}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.answer')}:</strong>
                        <span>${issue.flashcard.answer}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.upvotes')}:</strong>
                        <span>${issue.flashcard.upvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.downvotes')}:</strong>
                        <span>${issue.flashcard.downvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.created')}:</strong>
                        <span>${issue.created_at ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                    </div>
                </div>
            `;
        } else if (issue.ai_quiz) {
            contentHtml = `
                <div class="progress-details">
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.type')}:</strong>
                        <span>Quiz Question</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.reason')}:</strong>
                        <span>${issue.reasons}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.question')}:</strong>
                        <span>${issue.ai_quiz.question}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.correctAnswer')}:</strong>
                        <span>${issue.ai_quiz.answer}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer1')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer1}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer2')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer2}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.incorrectAnswer3')}:</strong>
                        <span>${issue.ai_quiz.incorrect_answer3}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.explanation')}:</strong>
                        <span>${issue.ai_quiz.explanation}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.upvotes')}:</strong>
                        <span>${issue.ai_quiz.upvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.downvotes')}:</strong>
                        <span>${issue.ai_quiz.downvotes}</span>
                    </div>
                    <div class="progress-item">
                        <strong>${t('instructorDashboard.created')}:</strong>
                        <span>${issue.created_at ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                    </div>
                </div>
            `;
        }

        await Swal.fire({
            title: t('instructorDashboard.reportedIssueDetails'),
            html: contentHtml,
            customClass: {
                popup: 'large-swal-container',
                title: 'custom-swal-title'
            },
            width: '800px',
            confirmButtonText: t('instructorDashboard.close'),
            confirmButtonColor: '#0B4475'
        });
    }

    return (
        <div className="course-reported-issues">
            <h1>{t('instructorDashboard.courseReportedIssues')}</h1>
            
            {loading && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
                    <CircularProgress />
                </Box>
            )}

            {reportedIssues.length > 0 && !loading && (
                <div className="reported-issues-container">
                    {reportedIssues
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                        .map((issue) => (
                            <div className="reported-issue">
                                <span className="reported-issue-user">{issue.user.first_name} {issue.user.last_name}</span>
                                <span className="reported-issue-date">{issue.created_at !== null ? issue.created_at.split('T')[0] : 'Unknown Date'}</span>
                                <span className="reported-issue-type">{issue.flashcard ? 'Flashcard' : 'Quiz'}</span>
                                <button className="reported-issue-view" onClick={() => handleViewIssue(issue)}>{t('instructorDashboard.view')}</button>
                            </div>
                        ))}
                </div>
            )}

            {reportedIssues.length === 0 && !loading && (
                <div className="reported-issues-empty">
                    <h3>{t('instructorDashboard.noReportedIssuesFound')}</h3>
                </div>
            )}
        </div>
    );
}

export default CourseReportedIssues;