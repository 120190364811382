import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/AuthContext';
import { OpenAI } from 'openai';
import { getStudentThreads, putStudentThreads, getStudentCourse } from '../../services/student';
import { updateStudyGuideTask } from '../../services/studyGuides/index';
import { getFlashcards } from '../../services/ai';
import { useTranslation } from 'react-i18next';

import './Ace.css';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});


const assistant = await openai.beta.assistants
    .retrieve(process.env.REACT_APP_FLASHCARDS_ASSISTANT_ID)
    .then((res) => res)
    .catch((err) => {
        console.error(err);
        throw err;
    });

function FlashcardGenerate({
    flashcards,
    setFlashcards,
    course_id,
    chapter,
    setActiveComponent,
    setLoading,
    // setError,
    loading,
    // error,
    studyGuide,
    setStudyGuide,
    currentTask,
    setCurrentTask,
}) {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [lockout, setLockout] = useState(false);
    const [threadID, setThreadID] = useState('');
    const [flashcardStates, setFlashcardStates] = useState([]);
    const { id: chapter_id, name: chapter_name } = chapter;

    console.log('--------FlashCardgen- Debugging:--------');
    // console.log('FlashCardgen- course_id:', course_id);
    // console.log('FlashCardgen- chapter_id:', chapter_id);
    console.log('FlashCardgen- chapter:', chapter_name);
    // console.log('FlashCardgen- open ai:', openai);
    // console.log('FlashCardgen- tread:', threadID);
    // console.log('FlashCardgen- run:', runID);
    // console.log('FlashCardgen- user:', user);

    async function getThreads() {
        const resp = await getStudentThreads(user?.id);
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error('Could not fetch threads');
        }
    }

    async function updateThreads(thread_id) {
        const resp = await putStudentThreads(user?.id, {
            thread_id: thread_id,
            to_update: 'flashcards_thread',
        });
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error('Could not update threads');
        }
    }

    // useEffect(() => {
    //     if (flashcards.length >= 5) {
    //         setTimeout(() => {
    //             updateStudyGuide();
    //         }, 5000);
    //     }
    // }, [flashcards]);

    // async function updateStudyGuide() {
    //     if (Object.keys(studyGuide).length > 0) {
    //         const resp = await updateStudyGuideTask(currentTask.id, {
    //             is_completed: true,
    //         });
    //         if (resp.success) {
    //             // Update the local studyGuide state with both task completion and stats
    //             const updatedStudyGuide = {
    //                 ...studyGuide,
    //                 tasks: studyGuide.tasks.map((t) => (t.id === currentTask.id ? { ...t, is_completed: true } : t)),
    //                 completion_stats: resp.data.completion_stats,
    //                 overall_progress: resp.data.overall_progress,
    //                 week_progress: resp.data.week_progress,
    //                 average_grade: resp.data.average_grade,
    //             };
    //             setStudyGuide(updatedStudyGuide);
    //         }
    //     }
    // }

    async function createMessage() {
        setActiveComponent('Flashcard');
        setLoading(true);
        // setError(null);

        try {
            const resp = await getFlashcards(course_id, chapter_id);
            if (resp.data.length >= 5) {
                setFlashcards(resp.data);
                setLoading(false);
                return;
            }

            openai.beta.threads.runs.list(threadID).then((res1) => {
                if (res1.body.first_id == null) {
                    openai.beta.threads.messages
                        .create(threadID, {
                            role: 'user',
                            content: `call the generateFlashcard function five times to create 5 distinct flashcards to help me study the content of ${chapter_name}.`,
                        })
                        .then((res) => {
                            runAssistant();
                            setLoading(true);
                        })
                        .catch((err) => console.log(err));
                } else {
                    openai.beta.threads.runs
                        .retrieve(threadID, res1.body.first_id)
                        .then((res) => {
                            // console.log('res', res);
                            if (res.status == 'requires_action') {
                                submitTools(res1.body.first_id);
                                // setError(false);
                            } else if (
                                res.status == 'completed' ||
                                res.status == 'expired' ||
                                res.status == 'failed' ||
                                res.status == 'incomplete'
                            ) {
                                // setError(false);
                                openai.beta.threads.messages
                                    .create(threadID, {
                                        role: 'user',
                                        content: `call the generateFlashcard function five times to create 5 distinct flashcards to help me study the content of ${chapter_name}.`,
                                    })
                                    .then((res) => {
                                        runAssistant();
                                        setLoading(true);
                                    })
                                    .catch((err) => console.log(err));
                            } else {
                                setLockout(true);
                                // setError(true);
                                setTimeout(() => {
                                    setLockout(false);
                                    createMessage();
                                    console.log('locking loop');
                                    console.log(res.status);
                                }, 5000);
                            }
                        })
                        .catch((err) => console.log('error'));
                }
            });
        } catch (err) {
            // setError('Failed to generate flashcards. Please try again.');
            setLoading(false);
        }
    }

    async function runAssistant() {
        setLoading(true);
        openai.beta.threads.runs
            .create(threadID, {
                assistant_id: assistant.id,
            })
            .then((res) => {
                setLoading(true);
                retrieveRun(res.id);
            });
    }

    async function submitTools(run_id) {
        setLoading(true);

        const runStatus = await openai.beta.threads.runs.retrieve(threadID, run_id);

        const toolCalls = runStatus.required_action.submit_tool_outputs.tool_calls;

        const args = [];
        toolCalls.map((tool) => args.push(JSON.parse(tool.function.arguments)));
        const toolIds = [];
        const outputs = [];

        toolCalls.map((tool) => toolIds.push(tool.id));
        toolIds.map((toolID) =>
            outputs.push({
                tool_call_id: toolID,
                output: 'output',
            })
        );

        openai.beta.threads.runs
            .submitToolOutputs(threadID, run_id, {
                tool_outputs: outputs,
            })
            .then((res) => generateFlashcards.apply(null, [args]));
    }

    function generateFlashcards(array) {
        let setter = array.map((flashcard, index) => ({
            ...flashcard,
            id: index,
        }));
        setFlashcards(setter);

        // Initialize flashcard states
        const initialStates = {};
        setter.forEach((flashcard) => {
            initialStates[flashcard.id] = {
                thumbsUpClicked: false,
                thumbsDownClicked: false,
                favoriteClicked: false,
            };
        });
        setFlashcardStates(initialStates);

        setLoading(false);
        setLockout(false);
    }

    function retrieveRun(run_id) {
        openai.beta.threads.runs.retrieve(threadID, run_id).then((res) => {
            if (res.status == 'in_progress' || res.status == 'queued') {
                setLoading(true);
                setTimeout(() => {
                    retrieveRun(run_id);
                    console.log('running');
                }, 6000);
            } else if (res.status == 'requires_action') {
                submitTools(run_id);
            } else if (res.status == 'completed') {
                setLockout(true);
                setTimeout(() => {
                    setLockout(false);
                }, 20000);
                // setError(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        getThreads()
            .then((res) => {
                if (res.data[0].flashcards_thread == ' ') {
                    openai.beta.threads
                        .create()
                        .then((res) => {
                            updateThreads(res.id);
                            setThreadID(res.id);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setThreadID(res.data[0].flashcards_thread);
                }
            })
            .catch((err) => console.log(err));
    }, [user]);

    // useEffect(() => {
    //     setFlashcards([]);
    // }, [chapter_name]);

    // Add useEffect to automatically trigger createMessage when chapter changes
    useEffect(() => {
        if (threadID && chapter_name) {
            createMessage();
        }
    }, [threadID, chapter_name]);

    return (
        <button
            className="ace__AiGenerate-btn"
            disabled={loading}
            style={{ opacity: loading ? 0.5 : 1 }}
            onClick={createMessage}
        >
            {loading ? 'Loading...' : 'Generate Flashcards'}
        </button>
    );
}

export default FlashcardGenerate;
