import { useEffect, useState, useRef, useContext } from 'react';
import { getAllComments, postComments } from '../../../services/discussionForum';
import { showError, utcToLocalDateTime, Spinner } from '../../../Utils/helper';
import { icons } from '../../../../constants/icons';
import { UserContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Chat.css';

function Chat({ studentData }) {
    const { t } = useTranslation();
    const messageBox = useRef(null);
    const { user } = useContext(UserContext);
    
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const arrow = (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="17.5px" height="17.5px" viewBox="0 0 535.5 535.5">
            <g id="send">
                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
            </g>
        </svg>
    );

    async function getComments() {
        setLoading(true);
        const resp = await getAllComments(studentData.id);
        if (resp.success) {
            setData(resp.data.reverse());
        } else {
            showError(t('alertMessage.wrong'));
        }
        setLoading(false);
    }

    useEffect(() => {
        getComments();
    }, []);

    async function sendMessage(e) {
        e.preventDefault();
        setMessage('');
        setLoading(true);
        if (message.length > 0) {
            let payload = {
                comment: message,
            };
            const resp = await postComments(studentData.id, payload);
            if (resp.success) {
                getComments();
            } else {
                showError(t('alertMessage.wrong'));
            }
        }
        // setLoading(false);
    }

    function handleFileChange(e) {
        const { files, value } = e.target;
        if (e.target.files[0].size / 1024 / 1024 < 30) {
            setLoading(true);
            let fileExt = value.split('.');
            let fileExtReverse = fileExt.reverse();
            getBase64(files[0]).then((data) => {
                sendAttachment(data, fileExtReverse[0]);
            });
            setLoading(false);
        } else {
            e.target.value = null;
            showError(t('alertMessage.fileNotMoreThan'));
        }
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    async function sendAttachment(data, fileExt) {
        if (data !== '' && data !== null && fileExt !== '' && fileExt !== null) {
            let payload = {
                msg_type: 'attachment',
                attachment: data,
                file_ext: fileExt,
            };
            const resp = await postComments(studentData.id, payload);
            if (resp.success) {
                getComments();
            } else {
                if (resp.status === 422) {
                    showError(t('alertMessage.fileNotMoreThan'));
                } else {
                    showError(t('alertMessage.wrong'));
                }
            }
        }
    }

    const AlwaysScrollToBottom = () => {
        const elementRef = useRef();
        useEffect(() => {
            elementRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            // Prevent page scroll
            window.scrollTo(0, 0);
        });
        return <div ref={elementRef} />;
    };

    return (
        <section id="chat-container" className="message_section">
            <div className="container">
                <div className="messages">
                    <div className="msg_header">
                        <div className="msg_profile">
                            <div>
                                {studentData?.first_name[0]}
                                {studentData?.last_name[0]}
                            </div>
                        </div>
                        <div className="msg_profile_details">
                            <div className="msg_name">
                                {studentData?.first_name} {studentData?.last_name}
                            </div>
                        </div>
                    </div>

                    <div id="chat-window" className="msg_win">
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {data?.length === 0 ? (
                                    <div className="d-flex justify-content-center align-items-center empty-chat">
                                        <h2 className="text-center">{t('common.startConversation')}</h2>
                                    </div>
                                ) : (
                                    <>
                                        {data?.map((_comment) => (
                                            <div key={_comment?.id}>
                                                {_comment?.user_id == studentData.id ? (
                                                    <div className="msg_row msg_receiver">
                                                        {_comment.attachment !== '' && _comment.attachment !== null ? (
                                                            <div className="msg_file">
                                                                <a
                                                                    href={process.env.REACT_APP_ASSETS + _comment?.attachment?.attachment_path}
                                                                    className="download_button"
                                                                    download
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <img src={icons.icon_download_black} alt="download" />
                                                                </a>
                                                                {_comment?.attachment?.attachment_file}
                                                            </div>
                                                        ) : (
                                                            <div className="msg_text">{_comment?.comment}</div>
                                                        )}
                                                        <div className="msg_date">{utcToLocalDateTime(_comment?.created_at)}</div>
                                                    </div>
                                                ) : (
                                                    <div className="msg_row msg_sender">
                                                        {_comment.attachment !== '' && _comment.attachment !== null ? (
                                                            <div className="msg_file">
                                                                <a
                                                                    href={process.env.REACT_APP_ASSETS + _comment?.attachment?.attachment_path}
                                                                    className="download_button"
                                                                    download
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <img src={icons.icon_download} alt="download" />
                                                                </a>
                                                                {_comment?.attachment?.attachment_file}
                                                            </div>
                                                        ) : (
                                                            <div className="msg_text">{_comment?.comment}</div>
                                                        )}
                                                        <div className="msg_date">{utcToLocalDateTime(_comment?.created_at)}</div>
                                                    </div>
                                                )}
                                                <AlwaysScrollToBottom />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="msg_footer">
                        <div className="msg_input">
                            <form onSubmit={sendMessage} className="grey_input w-100 mt-0">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t('common.typeMessage')}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <div className="attachment_button">
                                    <input type="file" id="attachment" onChange={handleFileChange} />
                                    <label className="form-label" htmlFor="attachment">
                                        <img src={icons.icon_attachment} alt="" />
                                    </label>
                                </div>
                                <span className="ml-2 svg_icon cursor_pointer" onClick={sendMessage}>
                                    {arrow}
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Chat;