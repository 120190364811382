import { useState, useEffect, useContext, useCallback } from 'react';
import { OpenAI } from 'openai';
import { getStudentThreads, putStudentThreads, getStudentCourse } from '../../services/student';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { UserContext } from '../../context/AuthContext';

import './Flashcard.css';
import ReportIssue from './ReportIssue';

import {
    upvoteFlashcard,
    unupvoteFlashcard,
    downvoteFlashcard,
    undownvoteFlashcard,
    favoriteFlashcard,
    // getFlashcards,
    // flashcards,
} from '../../services/ai';

import { useTranslation } from 'react-i18next';

function Flashcard({
    flashcards,

    chapter,
    course_id,
    chapter_id,
    handleActiveSidebar,
    activeSidebar,
    chapterData,

    // viewMode,
}) {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [reportIndex, setReportIndex] = useState(null); // Track which flashcard's report is shown
    const [rating, setRating] = useState(null);

    // State to track which flashcards are showing the answer
    const [showAnswers, setShowAnswers] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);

    const [flashcardStates, setFlashcardStates] = useState({});

    const handleReportClick = useCallback((index) => {
        setReportIndex(index);
    }, []);

    const handleCloseReport = () => {
        setReportIndex(null);
    };

    const toggleShowAnswer = (index) => {
        setShowAnswers((prevShowAnswers) => ({
            ...prevShowAnswers,
            [index]: !prevShowAnswers[index],
        }));
    };

    const handleRating = (type) => {
        if (rating === type) {
            setRating(null);
        } else {
            setRating(type);
        }
    };

    const handleThumbsUpClick = async (e, flashcard) => {
        e.stopPropagation();

        // Get current state for this specific flashcard
        const currentState = flashcardStates[flashcard.id] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newThumbsUpState = !currentState.thumbsUpClicked;

        setFlashcardStates((prev) => ({
            ...prev,
            [flashcard.id]: {
                ...currentState,
                thumbsUpClicked: newThumbsUpState,
                thumbsDownClicked: false,
            },
        }));

        if (newThumbsUpState) {
            await upvoteFlashcard({
                course_id,
                chapter_id,
                question: flashcard.question,
                answer: flashcard.answer,
                is_downvoted: false,
            });
        } else {
            await unupvoteFlashcard({
                course_id,
                chapter_id,
                question: flashcard.question,
                answer: flashcard.answer,
            });
        }
        handleRating('up');
    };

    const handleThumbsDownClick = async (e, flashcard) => {
        e.stopPropagation();
        const currentState = flashcardStates[flashcard.id] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newThumbsDownState = !currentState.thumbsDownClicked;

        setFlashcardStates((prev) => ({
            ...prev,
            [flashcard.id]: {
                ...currentState,
                thumbsDownClicked: newThumbsDownState,
                thumbsUpClicked: false,
            },
        }));

        if (newThumbsDownState) {
            await downvoteFlashcard({
                course_id,
                chapter_id,
                question: flashcard.question,
                answer: flashcard.answer,
                is_upvoted: false,
            });
        } else {
            await undownvoteFlashcard({
                course_id,
                chapter_id,
                question: flashcard.question,
                answer: flashcard.answer,
            });
        }
        handleRating('down');
    };

    const handleFavorite = async (e, flashcard) => {
        e.stopPropagation();
        // const newFavoriteState = !state.favoriteClicked;
        // updateState({ favoriteClicked: newFavoriteState });
        const currentState = flashcardStates[flashcard.id] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };

        const newFavoriteState = !currentState.favoriteClicked;

        setFlashcardStates((prev) => ({
            ...prev,
            [flashcard.id]: {
                ...currentState,
                favoriteClicked: newFavoriteState,
            },
        }));

        await favoriteFlashcard({
            course_id,
            chapter_id,
            user_id: user?.id,
            question: flashcard.question,
            answer: flashcard.answer,
        });
    };

    // Navigation handlers for page mode
    const handleNext = (e) => {
        e.stopPropagation(); // Prevent triggering parent click events
        if (currentIndex < flashcards.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = (e) => {
        e.stopPropagation(); // Prevent triggering parent click events
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    // Render single flashcard
    const renderFlashcard = (flashcard, index) => {
        const currentState = flashcardStates[flashcard.id] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };

        return (
            <div
                key={flashcard.id}
                className="flashcard"
                style={{ backgroundColor: showAnswers[index] ? '#138ab4' : '' }}
            >
                <div className="flashcard__header">
                    <h1>Dawraty</h1>
                </div>
                <div className="flashcard__content">
                    <div className="flashcard__content-menu">
                        <div
                            className={`flashcard__content-menu-icon ${currentState.favoriteClicked ? 'active' : ''}`}
                            onClick={(e) => handleFavorite(e, flashcard)}
                        >
                            {currentState.favoriteClicked ? (
                                <FavoriteIcon style={{ fontSize: '24px', color: '#ef1b1b' }} />
                            ) : (
                                <FavoriteBorderOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            )}
                        </div>
                    </div>
                    {/* <div className="flashcard__content-info" onClick={() => toggleShowAnswer(index)}>
                        {viewMode === 'page' && currentIndex > 0 ? (
                            <div className="flashcard__content-info-icon" onClick={handlePrevious}>
                                <ArrowBackIosNewOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        ) : (
                            <div className="flashcard__content-info-placeholder"></div> // Placeholder for alignment
                        )}

                        <h1 className="flashcard__content-info-content">
                            {showAnswers[index] ? flashcard.answer : flashcard.question}
                        </h1>

                        {viewMode === 'page' && currentIndex < flashcards.length - 1 && (
                            <div className="flashcard__content-info-icon" onClick={handleNext}>
                                <ArrowForwardIosOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        )}
                    </div> */}
                    <div className="flashcard__content-info" onClick={() => toggleShowAnswer(index)}>
                        {currentIndex > 0 ? (
                            <div className="flashcard__content-info-icon" onClick={handlePrevious}>
                                <ArrowBackIosNewOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        ) : (
                            <div className="flashcard__content-info-placeholder"></div> // Placeholder for alignment
                        )}

                        <h1 className="flashcard__content-info-content">
                            {showAnswers[index] ? flashcard.answer : flashcard.question}
                        </h1>

                        {currentIndex < flashcards.length - 1 && (
                            <div className="flashcard__content-info-icon" onClick={handleNext}>
                                <ArrowForwardIosOutlinedIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                            </div>
                        )}
                    </div>

                    <div className="flashcard__content-rates">
                        <div
                            className={`flashcard__content-rates-icon ${currentState.thumbsUpClicked ? 'active' : ''}`}
                            onClick={(e) => handleThumbsUpClick(e, flashcard)}
                        >
                            <ThumbUpAltOutlinedIcon
                                style={{
                                    fontSize: '20px',
                                    color: currentState.thumbsUpClicked ? '#0b4374' : '#ffffff',
                                }}
                            />
                        </div>
                        <div
                            className={`flashcard__content-rates-icon ${currentState.thumbsDownClicked ? 'active' : ''}`}
                            onClick={(e) => handleThumbsDownClick(e, flashcard)}
                        >
                            <ThumbDownAltOutlinedIcon
                                style={{
                                    fontSize: '20px',
                                    color: currentState.thumbsDownClicked ? '#0b4374' : '#ffffff',
                                }}
                            />
                        </div>
                        <div className="flashcard__content-rates-icon" onClick={() => handleReportClick(index)}>
                            <GppMaybeOutlinedIcon style={{ fontSize: '20px', color: '#ffffff' }} />
                        </div>
                    </div>
                    {reportIndex === index && (
                        <ReportIssue
                            onClose={handleCloseReport}
                            questionInfo={{
                                course_id: course_id,
                                chapter_id: chapter_id,
                                user_id: user?.id,
                                question_id: flashcard?.id || null,
                                question: flashcard.question,
                                answer: flashcard.answer,
                            }}
                            type="flashcard"
                        />
                    )}
                </div>

                <div className="flashcard__counter">
                    <div className="flashcard__counter-content">
                        <h4 className="flashcard__counter-content-numerator">{index + 1}</h4>
                        <span>/</span>
                        <h4 className="flashcard__counter-content-denominator">{flashcards.length}</h4>
                    </div>
                </div>
            </div>
        );
    };

    // ** list mode ** //
    return (
        // <div className="flashcard-list" style={{ overflowY: viewMode === 'list' ? 'auto' : 'hidden' }}>
        //     {viewMode === 'list'
        //         ? flashcards.map((flashcard, index) => renderFlashcard(flashcard, index))
        //         : renderFlashcard(flashcards[currentIndex], currentIndex)}
        // </div>
        <>{renderFlashcard(flashcards[currentIndex], currentIndex)}</>
    );
}

export default Flashcard;
