import { useEffect, useState } from 'react';
import './StudyGuide.css';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import getTranslationFromMany from '../../../services/helper';
import { createBulkStudyGuideTasks, createStudyGuide, updateStudyGuideTask, getFullStudyGuideByCourseId, updateStudyGuide, deleteStudyGuide } from '../../services/studyGuides/index';

import default_avatar from '../../../assets/image/default_avatar.jpg';

export function StudyGuideDashboard({
    user, 
    courseIsPurchased, 
    setCourseIsPurchased, 
    course_name, 
    studyGuide, 
    studyGuideAvailable, 
    setStudyGuideAvailable, 
    studyGuideLoading, 
    setStudyGuideLoading,
    setStudyGuide,
    setView,
    setActiveComponent,
    handleLessonClick,
    handleTaskClick,
    courseData,
    selectedItem,
    setSelectedItem,
    setOpenChapters,
    selectedChapter,
    setSelectedChapter,
    selectedOption,
    setSelectedOption,
    currentTask,
    setCurrentTask,
    summaryReportStats,
    setSummaryReportStats,
    isMockExam,
    setIsMockExam
}) {
    const { t, i18n } = useTranslation();
    // {t('aboutUs.bannerButton')}

    const now = Date.now()
    const date = new Date(now)

    const history = useHistory();

    const today = `${date.toLocaleString('default', { weekday: 'long' })}, ${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}`

    let topic_count = 0

    useEffect(() => {
        if (Object.keys(studyGuide).length > 0) {
            setSummaryReportStats({
                quiz: studyGuide?.completion_stats.quiz,
                lesson: studyGuide?.completion_stats.lesson,
                flashcards: studyGuide?.completion_stats.flashcards,
                mock_exam: studyGuide?.completion_stats.mock_exam,
                lesson_quiz: studyGuide?.completion_stats.lesson_quiz,
                overall_progress: studyGuide?.overall_progress,
                week_progress: studyGuide?.week_progress,
                average_grade: studyGuide?.average_grade,
                stats_loaded: true
            })
        }
    }, [studyGuide])

    async function markTaskAsCompleted(task, status) {
        const resp = await updateStudyGuideTask(task.id, {is_completed: status});
        if (resp.success) {
            if (status) {
                toast.success(t('studyGuide.tasks.taskMarkedCompleted'));
            } else {
                toast.success(t('studyGuide.tasks.taskMarkedIncomplete'));
            }
            setSummaryReportStats({
                quiz: resp.data.completion_stats.quiz,
                lesson: resp.data.completion_stats.lesson,
                flashcards: resp.data.completion_stats.flashcards,
                mock_exam: resp.data.completion_stats.mock_exam,
                lesson_quiz: resp.data.completion_stats.lesson_quiz,
                overall_progress: resp.data.overall_progress,
                week_progress: resp.data.week_progress,
                average_grade: resp.data.average_grade,
                stats_loaded: true
            })
        } else {
            toast.error(t('studyGuide.tasks.errorMarking'));
        }
    }

    function handleCheckboxClick(task) {
        if (!task.is_completed) {
            Swal.fire({
                title: t('studyGuide.tasks.markCompleted'),
                text: `${task.task_name}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#0B4374",
                cancelButtonColor: "#d33",
                confirmButtonText: t('studyGuide.tasks.confirm')
            }).then((result) => {
                if (result.isConfirmed) {
                    // Update the studyGuide state
                    setStudyGuide(prevGuide => ({
                        ...prevGuide,
                        tasks: prevGuide.tasks.map(t => 
                            t.id === task.id ? { ...t, is_completed: true } : t
                        )
                    }));

                    markTaskAsCompleted(task, true);

                    Swal.fire({
                        title: t('studyGuide.tasks.success'),
                        text: t('studyGuide.tasks.taskMarkedCompleted'),
                        icon: "success",
                        confirmButtonColor: "#0B4374",
                    });
                }
            });
        } 
        // else {
        //     Swal.fire({
        //         title: "Mark as incomplete?",
        //         text: `${task.task_name}`,
        //         icon: "warning",
        //         showCancelButton: true,
        //         confirmButtonColor: "#0B4374",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Confirm"
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             // Update the studyGuide state
        //             setStudyGuide(prevGuide => ({
        //                 ...prevGuide,
        //                 tasks: prevGuide.tasks.map(t => 
        //                     t.id === task.id ? { ...t, is_completed: false } : t
        //                 )
        //             }));

        //             markTaskAsCompleted(task, false);

        //             Swal.fire({
        //                 title: "Success!",
        //                 text: "Task marked as incomplete",
        //                 icon: "success",
        //                 confirmButtonColor: "#0B4374",
        //             });
        //         }
        //     });
        // }
    }

    function handleTaskRedirect(task) {
        setCurrentTask(task);
        if (task.type == 'lesson') {
            // Search through chapters and lessons to find matching lesson_id
            for (const chapter of courseData.chapter) {
                const foundLesson = chapter.lessons.find(lesson => lesson.id === task.lesson_id);
                if (foundLesson) {
                    handleTaskClick(foundLesson, task);
                    break;
                }
            }
        } else {
            for (const chapter of courseData.chapter) {
                // Extract chapter number from task name (e.g., "AI Flashcards - Chapter 1" -> "1")
                const chapterMatch = task.task_name.match(/Chapter (\d+)/);
                if (chapterMatch) {
                    const chapterNumber = parseInt(chapterMatch[1]);
                    // Chapter numbers in array are 0-based, so we subtract 1
                    if (chapterNumber === courseData.chapter.indexOf(chapter) + 1) {
                        if (task.type === 'flashcards') {
                            setSelectedOption('Flashcard')
                        } else if (task.type === 'quiz') {
                            setIsMockExam(false)
                            setSelectedOption('Quiz')
                        } else if (task.type === 'mock_exam') {
                            setIsMockExam(true)
                            setSelectedOption('Quiz')
                        }
                        setSelectedChapter({
                            name: chapter.name,
                            id: chapter.id,
                        });
                        setSelectedItem('ace')
                        break;
                    }
                }
            }

        }
    }

    function getProgressStatus() {
        if (!studyGuide?.current_week) return '';
        
        const previousWeeksTasks = studyGuide.tasks.filter(task => 
            task.week_number < studyGuide.current_week
        );

        const hasIncompletePreviousTasks = previousWeeksTasks.some(task => 
            !task.is_completed
        );

        return hasIncompletePreviousTasks ? t('studyGuide.dashboard.behind') : t('studyGuide.dashboard.onTrack');
    }

    function formatDate(dateString) {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('default', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        });
    }

    function resetStudyGuide() {
        Swal.fire({
            title: 'Reset Study Guide?',
            text: 'Warning: This will reset all of your progress. This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0b4374',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Reset',
            cancelButtonText: 'Cancel',
            customClass: {
                htmlContainer: 'swal2-text-center'
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const loadingSwal = Swal.fire({
                    title: 'Resetting Study Guide...',
                    customClass: {
                        title: 'swal2-title-custom'
                    },
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });
    
                try {
                    const resp = await deleteStudyGuide(studyGuide.id);
                    if (resp.success) {
                        await loadingSwal.close();
                        await Swal.fire({
                            title: 'Success!',
                            text: 'Your study guide has been reset.',
                            icon: 'success',
                            confirmButtonText: 'Continue',
                            confirmButtonColor: '#0b4374',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            customClass: {
                                htmlContainer: 'swal2-text-center'
                            }
                        }).then((result) => {
                            if (result.isConfirmed) {
                                history.push('/student-learning?courses');
                            }
                        });
                    }
                } catch (error) {
                    await loadingSwal.close();
                    await Swal.fire({
                        title: 'Error',
                        text: 'Failed to reset study guide. Please try again.',
                        icon: 'error',
                        confirmButtonColor: '#0b4374',
                    });
                }
            }
        });
    }

    return (
        <div className="study-guide-dashboard">
            <ToastContainer />
            <div className="study-guide-dashboard_main_content">
                <h4>{t('studyGuide.dashboard.welcomeDashboard')}</h4>
                <div className="study-guide-dashboard_top_banner">
                    <h4>{t('studyGuide.dashboard.hello')}, {user?.first_name}</h4>
                    <h5>{t('studyGuide.dashboard.todayIs')} {today}</h5>
                </div>
                
                <h4>{t('studyGuide.dashboard.summaryReport')}</h4>

                {!courseIsPurchased && (
                    <div className="study-guide-dashboard_purchase_message">
                    <LockOutlinedIcon className='lock-icon'/>
                    <p>
                        {t('studyGuide.dashboard.purchaseMessage', { courseName: course_name })}
                    </p>
                    {/* <button>{t('studyGuide.dashboard.startNow')}</button> */}
                    </div>
                )}

                {studyGuideAvailable && (
                    <div>
                    <div className="study-guide-dashboard_summary_report_stats">
                        <div className="stat_item">
                            <div className="stat_item_outer_ring">
                                <div className="stat_item_inner_ring">
                                    <span>{summaryReportStats.lesson}</span>
                                </div>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.lessonsCompletedBreak')}</span>
                        </div>

                        <div className="stat_item">
                            <div className="stat_item_outer_ring">
                                <div className="stat_item_inner_ring">
                                    <span>{summaryReportStats.flashcards}</span>
                                </div>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.aiFlashcardsCompletedBreak')}</span>
                        </div>

                        {/* <div className="stat_item">
                            <div className="stat_item_outer_ring">
                                <div className="stat_item_inner_ring">
                                    <span>{summaryReportStats.lesson_quiz}</span>
                                </div>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.lessonQuizzesBreak')}</span>
                        </div> */}

                        <div className="stat_item">
                            <div className="stat_item_outer_ring">
                                <div className="stat_item_inner_ring">
                                    <span>{summaryReportStats.quiz}</span>
                                </div>
                            </div>
                            <span style={{textAlign: 'center',whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.aiQuizzesBreak')}</span>
                        </div>

                        <div className="stat_item">
                            <div className="stat_item_outer_ring">
                                <div className="stat_item_inner_ring">
                                    <span>{summaryReportStats.mock_exam}</span>
                                </div>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.mockExams')}</span>
                        </div>
                    </div>

                    <div className="study-guide-dashboard_topics">
                        <span>{t('studyGuide.dashboard.examDate')}: {formatDate(studyGuide?.exam_date)}</span>
                        <span>{t('studyGuide.dashboard.completionGoal')}: {formatDate(studyGuide?.completion_goal)}</span>
                        <span>{t('studyGuide.dashboard.currentWeek')}: {studyGuide?.current_week}</span>
                        <span>{t('studyGuide.dashboard.progress')}: {getProgressStatus()}</span>
                    </div>

                    <div className="study-guide-dashboard-progress">
                        <div id="average_grade" className="progress_item">
                            <div className="progress_item_outer_ring">
                                <div className="progress_item_inner_ring">
                                    <span>{summaryReportStats.average_grade}</span>
                                </div>
                            </div>
                            <span>{t('studyGuide.dashboard.averageGrade')}</span>
                        </div>

                        <div className="progress_item">
                            <div className="progress_item_outer_ring">
                                <div className="progress_item_inner_ring">
                                    <span>{summaryReportStats.overall_progress}</span>
                                </div>
                            </div>
                            <span>{t('studyGuide.dashboard.overallProgress')}</span>
                        </div>

                        <div className="progress_item">
                            <div className="progress_item_outer_ring">
                                <div className="progress_item_inner_ring">
                                    <span>{summaryReportStats.week_progress}</span>
                                </div>
                            </div>
                            <span>{t('studyGuide.dashboard.weekProgress')}</span>
                        </div>
                    </div>
                    </div>
                )}

                {courseIsPurchased && !studyGuideAvailable && !studyGuideLoading && (
                    <div className="study-guide-dashboard_no_study_guide_message">
                        <MenuBookOutlinedIcon className='menu-book-icon'/>
                        <p>
                            {t('studyGuide.dashboard.setupMessage')}
                        </p>
                    </div>
                )}

                {studyGuideLoading && (
                    <div className="study-guide-dashboard_no_study_guide_message">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    </div>
                )}
            </div>

            <div className="study-guide-dashboard_sidebar">
            <div className="study-guide-dashboard_sidebar_avatar_container">
                {/* <img className="study-guide-dashboard_sidebar_avatar" src={user?.img_path == null ? default_avatar : user?.img_path}/> */}
                <h4>{user?.first_name} {user?.last_name}</h4>
            </div>

            <div className="study-guide-dashboard_sidebar_date_container">
                <h4 className="study-guide-dashboard_sidebar_date">
                    {date.toLocaleString('default', { month: 'long' })} {date.getDate()}
                </h4>

                {studyGuideAvailable && studyGuide?.start_date && (
                        <span style={{display: 'block', fontSize: '0.9em', marginTop: '5px'}}>
                            {t('studyGuide.dashboard.currentWeek')} {studyGuide.current_week}
                        </span>
                    )}
            </div>

            {!courseIsPurchased && (
                <p className="study-guide-dashboard_sidebar_purchase_message">
                    {t('studyGuide.dashboard.purchaseMessageHelp')}
                </p>
            )}

            {studyGuideAvailable && (
                <div className="study-guide-dashboard_sidebar_schedule">
                    <div className="study-guide-dashboard_sidebar_schedule_header">
                        <h4>{t('studyGuide.dashboard.schedule')}</h4>
                        {/* <h4>{t('studyGuide.dashboard.seeAll')}</h4> */}
                        <button onClick={resetStudyGuide} className="reset-study-guide-button">Reset Guide</button>
                    </div>

                    <div className="schedule_list">
                        {[...Array(studyGuide.weeks)].map((_, weekIndex) => {
                            const weekNumber = weekIndex + 1;
                            const weekTasks = studyGuide.tasks.filter(task => task.week_number === weekNumber);
                            
                            return (
                                <div key={weekNumber} className='schedule_item'>
                                    <div className='week-number'>
                                        <span>{t('studyGuide.dashboard.week')} {weekNumber}</span>
                                    </div>

                                    <div className="schedule_item_details">
                                        <div className="schedule_item_tasks">
                                            {weekTasks
                                                .filter(task => task.type !== 'quiz')
                                                .map(task => (
                                                    <div key={task.id} className="task-item">
                                                        <div className="task-header">
                                                            <div 
                                                                style={{
                                                                    backgroundColor: task.is_completed ? '#0B4374' : null
                                                                }} 
                                                                className='checkbox'
                                                            />
                                                            <span 
                                                                className='schedule_item_details_task_name' 
                                                                onClick={() => handleTaskRedirect(task)}
                                                                style={{
                                                                    textDecoration: task.is_completed ? 'line-through' : 'none'
                                                                }}
                                                            >
                                                                {task.task_name}
                                                            </span>
                                                        </div>
                                                        <span className="task-status">
                                                            {task.is_completed 
                                                                ? t('studyGuide.dashboard.completed') 
                                                                : t('studyGuide.dashboard.inProgress')}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {courseIsPurchased && !studyGuideAvailable && (
                <p className="study-guide-dashboard_sidebar_no_study_guide_message">
                    {t('studyGuide.dashboard.noStudyGuideMessage')}
                </p>
            )}
            </div>

        </div>
    );
}

function SetupMessage({content, type, setSetupStep, setNewStudyGuide, newStudyGuide, handleCompleteSetup}) {
    const { t, i18n } = useTranslation();

    const [examDate, setExamDate] = useState(null);
    const [completionGoal, setCompletionGoal] = useState(null);
    const hoursPerWeek = [1, 2, 3, 4, 5, 6, 7];

    return (
        <div className="study-guide-setup_message">
            <div className="ai-icon-container">
                <AutoAwesomeOutlinedIcon className='ai-icon'/>
            </div>
            <div className='connector' />
            <div className='study-guide-setup_message_text'>
                <p>
                    {content}
                </p>
                {type == 'enroll' && (
                    <button onClick={() => setSetupStep(prev => [...prev, 1])}>{t('studyGuide.setup.enroll')}</button>
                )}
                {type == 'exam-date' && (
                    <div className='study-guide-setup_message_exam_date'>
                        <input type="date" onChange={(event) => setExamDate(event.target.value)} min={new Date().toISOString().split('T')[0]}/>
                        <button disabled={!examDate} style={{backgroundColor: examDate ? '#1580DC' : 'grey'}} onClick={() => {
                            setNewStudyGuide(prev => ({...prev, exam_date: examDate}))
                            setSetupStep(prev => [...prev, 2])
                            }}>{t('studyGuide.setup.next')}</button>
                    </div>
                )}
                {/* {type == 'hours-per-week' && (
                    <div className='study-guide-setup_message_hours_per_week'>
                        {hoursPerWeek.map((hour) => (
                            <button onClick={() => {
                                setNewStudyGuide(prev => ({...prev, hours_per_week: hour}))
                                setSetupStep(prev => [...prev, 3])
                            }}>{hour}</button>
                        ))}
                    </div>
                )} */}
                {type == 'completion-goal' && (
                    <div className='study-guide-setup_message_completion_goal'>
                        <input type="date" onChange={(event) => setCompletionGoal(event.target.value)} min={new Date().toISOString().split('T')[0]} max={newStudyGuide?.exam_date}/>
                        <button disabled={!completionGoal} style={{backgroundColor: completionGoal ? '#1580DC' : 'grey'}} onClick={() => {
                            setNewStudyGuide(prev => ({...prev, completion_goal: completionGoal}))
                            setSetupStep(prev => [...prev, 3])
                            }}>{t('studyGuide.setup.next')}</button>
                    </div>
                )}
                {type == 'complete' && (
                    <button onClick={() => {
                        handleCompleteSetup()
                        setSetupStep(prev => [...prev, 4])
                    }}>{t('studyGuide.setup.completeSetup')}</button>
                )}
            </div>
        </div>
    )
}

function StudyGuide({
    courseData, 
    courseIsPurchased,
    setActiveComponent,
    activeComponent,
    studyGuide,
    setStudyGuide,
    studyGuideAvailable, 
    setStudyGuideAvailable, 
    user, 
    loading,
    setLoading,
    studyGuideLoading, 
    setStudyGuideLoading,
    summaryReportStats,
    setSummaryReportStats,
    currentTask,
    setCurrentTask,
    handleTaskClick,
    setSelectedItem,
    setSelectedChapter,
    setSelectedOption,
    flashcards,
    quiz,
    learningStarted,
    setLearningStarted,
    taskIndex,
    setTaskIndex,
    nextDisabled,
    setNextDisabled,
    studyGuideCompleted,
    setStudyGuideCompleted,
    examTaken,
    setExamTaken,
    finalGradeSubmitted,
    setFinalGradeSubmitted,
    isMockExam,
    setIsMockExam,
    mockExam,
    setMockExam,
    mockExamAnswers,
    setMockExamAnswers,
    clearExamTimer,
    mockExamCompleted,
    setMockExamCompleted
    }) {
    const { t, i18n } = useTranslation();

    const [setupStep, setSetupStep] = useState([0]);
    const [newStudyGuide, setNewStudyGuide] = useState({
        user_id: user?.id,
        course_id: courseData?.id,
        weeks: 0,
        start_date: null,
        exam_date: null,
        completion_goal: null,
        hours_per_week: 0
    });
    const [studyGuideCreated, setStudyGuideCreated] = useState(false);
    const [tasksCreated, setTasksCreated] = useState(false);
    const [courseDataRetrieved, setCourseDataRetrieved] = useState(false);
    const [studyGuideTasks, setStudyGuideTasks] = useState([]);
    const [courseLessons, setCourseLessons] = useState([]);

    useEffect(() => {
        if (Object.keys(studyGuide).length > 0) {
            setSummaryReportStats({
                quiz: studyGuide?.completion_stats.quiz,
                lesson: studyGuide?.completion_stats.lesson,
                flashcards: studyGuide?.completion_stats.flashcards,
                mock_exam: studyGuide?.completion_stats.mock_exam,
                lesson_quiz: studyGuide?.completion_stats.lesson_quiz,
                overall_progress: studyGuide?.overall_progress,
                week_progress: studyGuide?.week_progress,
                average_grade: studyGuide?.average_grade,
                stats_loaded: true
            })
        }
    }, [studyGuide])

    function getSchedule() {
        let schedule = {
            prevTask: null,
            currentTask: null,
            nextTask: null,
        }
        const task = studyGuide?.tasks.find(task => task.is_completed == false);
        const index = studyGuide?.tasks.findIndex(task => task.is_completed == false);

        if (index > 0) {
            schedule.prevTask = studyGuide?.tasks[index - 1].task_name;
        }
        if (task) {
            schedule.currentTask = task.type === 'quiz' ? studyGuide?.tasks[index + 1]?.task_name : task.task_name;
        }
        if (index < studyGuide?.tasks.length - 1) {
            schedule.nextTask = task?.type === 'quiz' 
                ? studyGuide?.tasks[index + 2]?.task_name 
                : studyGuide?.tasks[index + 1]?.task_name;
        }
   
        return schedule
    }

    function handleNextTask(task) {
        setCurrentTask(task);

        if (task.type == 'lesson') {
            // Search through chapters and lessons to find matching lesson_id
            for (const chapter of courseData.chapter) {
                const foundLesson = chapter.lessons.find(lesson => lesson.id === task.lesson_id);
                if (foundLesson) {
                    handleTaskClick(foundLesson, task);
                    break;
                }
            }
        } else {
            for (const chapter of courseData.chapter) {
                // Extract chapter number from task name (e.g., "AI Flashcards - Chapter 1" -> "1")
                const chapterMatch = task.task_name.match(/Chapter (\d+)/);
                if (chapterMatch) {
                    const chapterNumber = parseInt(chapterMatch[1]);
                    // Chapter numbers in array are 0-based, so we subtract 1
                    if (chapterNumber === courseData.chapter.indexOf(chapter) + 1) {
                        if (task.type === 'flashcards') {
                            setSelectedOption('Flashcard')
                        } else if (task.type === 'quiz') {
                            // First show the Swal alert
                            const result = Swal.fire({
                                title: 'Pop Quiz!',
                                text: 'You are about to take a pop quiz. Are you ready?',
                                icon: 'info',
                                confirmButtonText: 'Begin',
                                confirmButtonColor: '#0B4374',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    setIsMockExam(false);
                                    setSelectedOption('Quiz');
                                    setSelectedItem('ace')
                                }
                            });
                        } else if (task.type === 'mock_exam') {
                            setIsMockExam(true)
                            setSelectedOption('Quiz')
                        }
                        setSelectedChapter({
                            name: chapter.name,
                            id: chapter.id,
                        });
                        if (task.type == 'mock_exam' || task.type == 'flashcards') {
                            setSelectedItem('ace')
                        }
                        break;
                    }
                }
            }
        }
    }

    async function handleStartLearning() {
        clearExamTimer();
        setIsMockExam(false);
        setMockExam([]);
        setMockExamAnswers([]);
        setMockExamCompleted(false);

        if (!learningStarted) {
            setLearningStarted(true);
        }


        if (currentTask.type == 'flashcards' || currentTask.type == 'lesson') {
            const loadingSwal = Swal.fire({
                title: t('studyGuide.dashboard.loading'),
                customClass: {
                    title: 'swal2-title-custom'
                },
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });
            const resp = await updateStudyGuideTask(currentTask.id, {
                is_completed: true
            });
            if (resp.success) {
                const updatedStudyGuide = {
                    ...studyGuide,
                    tasks: studyGuide.tasks.map((t) => (t.id === currentTask.id ? { ...t, is_completed: true } : t)),
                    completion_stats: resp.data.completion_stats,
                    overall_progress: resp.data.overall_progress,
                    week_progress: resp.data.week_progress,
                    average_grade: resp.data.average_grade,
                };
                setStudyGuide(updatedStudyGuide);
                await loadingSwal.close();
                
                // Use the updatedStudyGuide instead of the stale studyGuide state
                const nextTask = updatedStudyGuide.tasks.find(task => task.is_completed === false);
                if (nextTask) {
                    setCurrentTask(nextTask);
                    handleNextTask(nextTask);
                }
            }
        } else {
            const task = studyGuide?.tasks.find(task => task.is_completed == false);
            if (task) {
                setCurrentTask(task);
                handleNextTask(task);
            }
        }


    }

    function handleSkipTask() {
        clearExamTimer();
        setIsMockExam(false);
        setMockExam([]);
        setMockExamAnswers([]);
        setMockExamCompleted(false);
        let currentIndex = 0;

        const incompleteTasks = studyGuide.tasks.filter(task => !task.is_completed);
        if (Object.keys(currentTask).length > 0) {
            currentIndex = incompleteTasks.findIndex(task => task.id === currentTask.id);
        } else {
            currentIndex = studyGuide?.tasks.findIndex(task => task.is_completed == false);
        }
        let nextTask = incompleteTasks[currentIndex + 1];

        if (nextTask) {
            setCurrentTask(nextTask);
            handleTaskNavigation(nextTask);
        } else {
            let searchIndex = currentIndex + 2; // Start looking from two tasks ahead
            while (searchIndex < incompleteTasks.length) {
                const potentialNextTask = incompleteTasks[searchIndex];
                if (!potentialNextTask.is_completed) {
                    setCurrentTask(potentialNextTask);
                    handleTaskNavigation(potentialNextTask);
                    break;
                }
                searchIndex++;
            }
        }
    }

    // Helper function to handle task navigation
    function handleTaskNavigation(task) {
        clearExamTimer();
        setIsMockExam(false);
        setMockExam([]);
        setMockExamAnswers([]);
        setMockExamCompleted(false);

        if (task.type === 'lesson') {
            // Search through chapters and lessons to find matching lesson_id
            for (const chapter of courseData.chapter) {
                const foundLesson = chapter.lessons.find(lesson => lesson.id === task.lesson_id);
                if (foundLesson) {
                    handleTaskClick(foundLesson, task);
                    break;
                }
            }
        } else {
            for (const chapter of courseData.chapter) {
                // Extract chapter number from task name (e.g., "AI Flashcards - Chapter 1" -> "1")
                const chapterMatch = task.task_name.match(/Chapter (\d+)/);
                if (chapterMatch) {
                    const chapterNumber = parseInt(chapterMatch[1]);
                    // Chapter numbers in array are 0-based, so we subtract 1
                    if (chapterNumber === courseData.chapter.indexOf(chapter) + 1) {
                        if (task.type === 'flashcards') {
                            setSelectedOption('Flashcard')
                        } else if (task.type === 'quiz') {
                            // First show the Swal alert
                            const result = Swal.fire({
                                title: 'Pop Quiz!',
                                text: 'You are about to take a pop quiz. Are you ready?',
                                icon: 'info',
                                confirmButtonText: 'Begin',
                                confirmButtonColor: '#0B4374',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                allowEnterKey: false
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    setIsMockExam(false);
                                    setSelectedOption('Quiz');
                                    setSelectedItem('ace')
                                }
                            });
                        } else if (task.type === 'mock_exam') {
                            setIsMockExam(true)
                            setSelectedOption('Quiz')
                        }
                        setSelectedChapter({
                            name: chapter.name,
                            id: chapter.id,
                        });
                        if (task.type == 'mock_exam' || task.type == 'flashcards') {   
                            setSelectedItem('ace')
                        }
                        break;
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (Object.keys(courseData).length > 0 && !courseDataRetrieved) {
            setNewStudyGuide(prev => ({...prev, course_id: courseData?.id}))
            getCourseLessons();
            setCourseDataRetrieved(true);
        }
    }, [courseData])

    useEffect(() => {
        if (studyGuideCreated) {
            submitStudyGuide();
        }
    }, [studyGuideCreated])

    useEffect(() => {
        if (tasksCreated) {
            submitStudyGuideTasks();
            setTasksCreated(false);
        }
    }, [tasksCreated])

    async function toGetStudyGuide() {
        const resp = await getFullStudyGuideByCourseId(user?.id, courseData?.id);
        if (resp.success) {
            setStudyGuide(resp?.data);
            setSetupStep([])
            setStudyGuideLoading(false);
            setStudyGuideAvailable(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    async function submitStudyGuide() {
        setStudyGuideLoading(true);
        const resp = await createStudyGuide(newStudyGuide);
        if (resp.success) {
            setStudyGuideCreated(false);
            generateStudyGuideTasks(resp.data?.id);
        } else {
            console.log('study guide:error')
            setStudyGuideCreated(false);
            setStudyGuideLoading(false);
            console.log(resp)
        }
    }

    async function submitStudyGuideTasks() {
        const resp = await createBulkStudyGuideTasks({tasks: studyGuideTasks});
        if (resp.success) {
            setStudyGuideLoading(true);
            toGetStudyGuide();
            setTasksCreated(false);
        } else {
            console.log('study guide tasks: error')
            setStudyGuideLoading(false);
            setTasksCreated(false);
            console.log(resp)
        }
    }

    async function handleCompleteSetup() {
        // Get current UTC date without time component
        const now = new Date();
        const startDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()))
            .toISOString()
            .split('T')[0];
        
        const weeks = calculateWeeks(newStudyGuide.completion_goal, startDate);
        
        setNewStudyGuide(prev => ({
            ...prev, 
            start_date: startDate,
            weeks: weeks
        }));

        setStudyGuideCreated(true);
    }

    async function handleExpectedGrade() {
        Swal.fire({
            title: t('studyGuide.exam.expectedGrade'),
            icon: 'question',
            input: 'range',
            inputLabel: t('studyGuide.exam.expectedGradeLabel'),
            inputValue: 100,
            inputAttributes: {
                min: "0",
                max: "100",
                step: "1"
            },
            showCancelButton: true,
            confirmButtonText: t('studyGuide.exam.submit'),
            cancelButtonText: t('studyGuide.exam.cancel'),
            confirmButtonColor: "#0B4374",
            cancelButtonColor: "#d33",
        }).then(async(result) => {
            if (result.isConfirmed) {
                const resp = await updateStudyGuide(studyGuide.id, {
                    expected_grade: result.value
                });
                if (resp.success) {
                    setExamTaken(true);
                }
            }
        })
    }

    async function handleFinalGrade() {
        Swal.fire({
            title: t('studyGuide.exam.finalGrade'),
            icon: 'question',
            input: 'range',
            inputLabel: t('studyGuide.exam.finalGradeLabel'),
            inputValue: 100,
            inputAttributes: {
                min: "0",
                max: "100",
                step: "1"
            },
            showCancelButton: true,
            confirmButtonText: t('studyGuide.exam.submit'),
            cancelButtonText: t('studyGuide.exam.cancel'),
            confirmButtonColor: "#0B4374",
            cancelButtonColor: "#d33",
        }).then(async(result) => {
            if (result.isConfirmed) {
                const resp = await updateStudyGuide(studyGuide.id, {
                    final_grade: result.value
                });
                if (resp.success) {
                    setFinalGradeSubmitted(true);
                }
            }
        })
    }

    function calculateWeeks(completionGoal, startDate) {
        // Convert string dates to Date objects
        const start = new Date(startDate);
        const end = new Date(completionGoal);
        
        // Calculate the difference in days
        const diffTime = end - start;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        
        // Convert days to weeks and round up to nearest whole week
        const diffWeeks = Math.ceil(diffDays / 7);
        
        return diffWeeks;
    }

    function getCourseLessons() {
        for (let i = 0; i < courseData?.chapter.length; i++) {
            for (let j = 0; j < courseData?.chapter[i].lessons.length; j++) {
                setCourseLessons(prev => [...prev, courseData?.chapter[i].lessons[j]])
            }
        }
    }

    function generateStudyGuideTasks(studyGuideId) {
        const totalWeeks = newStudyGuide.weeks;
        let setter = [];
        
        // Calculate lessons per week to distribute evenly
        const lessonsPerWeek = Math.ceil(courseLessons.length / totalWeeks);
        
        // Group lessons by chapter for middle quiz placement
        const chapterLessons = {};
        courseLessons.forEach(lesson => {
            if (!chapterLessons[lesson.chapter_id]) {
                chapterLessons[lesson.chapter_id] = [];
            }
            chapterLessons[lesson.chapter_id].push(lesson);
        });
        
        // Distribute all lessons across weeks
        courseLessons.forEach((lesson, index) => {
            const weekNumber = Math.floor(index / lessonsPerWeek) + 1;
            
            // Check if this lesson is the last in its chapter
            const isLastInChapter = courseData.chapter.some(chapter => {
                if (chapter.id !== lesson.chapter_id) return false;
                const lastLessonInChapter = chapter.lessons[chapter.lessons.length - 1];
                return lastLessonInChapter?.id === lesson.id;
            });

            const chapter_number = courseData.chapter.findIndex(chapter => 
                chapter.id === lesson.chapter_id) + 1;

            // Add lesson task with chapter_id
            setter.push({
                study_guide_id: studyGuideId,
                week_number: weekNumber,
                task_name: lesson.name,
                is_completed: false,
                type: 'lesson',
                lesson_id: lesson.id,
                chapter_id: lesson.chapter_id
            });

            // Add quiz in middle of chapter
            const lessonsInChapter = chapterLessons[lesson.chapter_id];
            const isFirstLesson = lessonsInChapter.indexOf(lesson) === 0;
            
            // For 2-lesson chapters, add quiz after first lesson
            if (lessonsInChapter.length === 2 && isFirstLesson) {
                setter.push({
                    study_guide_id: studyGuideId,
                    week_number: weekNumber,
                    task_name: `AI Quiz - Chapter ${chapter_number}`,
                    is_completed: false,
                    lesson_id: null,
                    type: 'quiz',
                    chapter_id: lesson.chapter_id
                });
            }
            // For chapters with more than 2 lessons, add quiz after middle lesson
            else if (lessonsInChapter.length > 2) {
                const middleIndex = Math.floor(lessonsInChapter.length / 2);
                if (lessonsInChapter[middleIndex].id === lesson.id) {
                    setter.push({
                        study_guide_id: studyGuideId,
                        week_number: weekNumber,
                        task_name: `AI Quiz - Chapter ${chapter_number}`,
                        is_completed: false,
                        lesson_id: null,
                        type: 'quiz',
                        chapter_id: lesson.chapter_id
                    });
                }
            }

            // Only add AI tasks if this is the last lesson in a chapter
            if (isLastInChapter) {
                // Add corresponding flashcard task with chapter_id
                setter.push({
                    study_guide_id: studyGuideId,
                    week_number: weekNumber,
                    task_name: `AI Flashcards - Chapter ${chapter_number}`,
                    is_completed: false,
                    lesson_id: null,
                    type: 'flashcards',
                    chapter_id: lesson.chapter_id
                });

                // Add corresponding quiz task with chapter_id
                setter.push({
                    study_guide_id: studyGuideId,
                    week_number: weekNumber,
                    task_name: `AI Mock Exam - Chapter ${chapter_number}`,
                    is_completed: false,
                    lesson_id: null,
                    type: 'mock_exam',
                    chapter_id: lesson.chapter_id
                });
            }
        });

        setStudyGuideTasks(setter);
        setTasksCreated(true);
    }

    function handleDashboardRedirect() {
        clearExamTimer();
        setIsMockExam(false);
        setMockExam([]);
        setMockExamAnswers([]);
        setMockExamCompleted(false);
        setCurrentTask({});

        setActiveComponent('StudyGuide');
        setTaskIndex(1);
    }

    function handleFavoritesRedirect() {
        clearExamTimer();
        setIsMockExam(false);
        setMockExam([]);
        setMockExamAnswers([]);
        setMockExamCompleted(false);
        setCurrentTask({});
        setActiveComponent('Favorites');
        setTaskIndex(1);
    }

    return (
        <div className="studyGuide" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
            {!courseIsPurchased && !loading && (
                <div className="study-guide-setup_message">
                    <div className="ai-icon-container">
                        <AutoAwesomeOutlinedIcon className='ai-icon'/>
                    </div>
                    <div className='connector' />
                    <div className='study-guide-setup_message_text'>
                        <p>
                            {t('studyGuide.setup.purchaseAccess', { courseName: courseData?.name })}
                        </p>
                        {/* <button>{t('studyGuide.setup.enroll')}</button> */}
                    </div>
                </div>
            )}

            {courseIsPurchased && !studyGuideAvailable && !loading && !studyGuideLoading && (
                <SetupMessage 
                    content={t('studyGuide.setup.congratsMessage', { courseName: courseData?.name })}
                    type="enroll"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )}

            {setupStep.includes(1) && !studyGuideLoading && (
                <SetupMessage 
                    content={t('studyGuide.setup.examSchedule')}
                    type="exam-date"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )}

            {/* {setupStep.includes(2) && !studyGuideLoading && (
                <SetupMessage 
                    content={t('studyGuide.setup.studyHours')}
                    type="hours-per-week"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )} */}

            {setupStep.includes(2) && !studyGuideLoading && (
                <SetupMessage 
                    content={t('studyGuide.setup.completionDate')}
                    type="completion-goal"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )}

            {setupStep.includes(3) && !studyGuideLoading && (
                <SetupMessage 
                    content={t('studyGuide.setup.readyToStart')}
                    type="complete"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )}

            {setupStep.includes(4) && (
                <SetupMessage 
                    content={t('studyGuide.setup.generating')}
                    type="loading"
                    setSetupStep={setSetupStep}
                    setNewStudyGuide={setNewStudyGuide}
                    newStudyGuide={newStudyGuide}
                    handleCompleteSetup={handleCompleteSetup}
                />
            )}

            {studyGuideAvailable && !studyGuideLoading && (
                <div className='study-guide-dashboard_sidebar_left_side'>
                    <div className='study-guide-dashboard_sidebar_progress'>
                        <div className='study-guide-dashboard_sidebar_progress_item'>
                            <div className='study-guide-dashboard_sidebar_progress_item_circle'>
                                <span>{summaryReportStats?.average_grade}</span>
                            </div>
                            <span>{t('studyGuide.dashboard.averageGrade')}</span>
                        </div>

                        <div className='study-guide-dashboard_sidebar_progress_item'>
                            <div className='study-guide-dashboard_sidebar_progress_item_circle'>
                                <span>{summaryReportStats?.overall_progress}</span>
                            </div>
                            <span>{t('studyGuide.dashboard.overallProgress')}</span>
                        </div>

                        <div className='study-guide-dashboard_sidebar_progress_item'>
                            <div className='study-guide-dashboard_sidebar_progress_item_circle'>
                                <span>{summaryReportStats?.week_progress}</span>
                            </div>
                            <span>{t('studyGuide.dashboard.weekProgress')}</span>
                        </div>
                    </div>

                    <div className='study-guide-dashboard_sidebar_left_side_schedule'>
                        <h4>{t('studyGuide.dashboard.schedule')}</h4>
                        {getSchedule().prevTask == null ? null : 
                            <h6>{t('studyGuide.dashboard.previous')}: {getSchedule().prevTask}</h6>}
                        {getSchedule().currentTask == null ? null : 
                            <h5>{t('studyGuide.dashboard.current')}: {getSchedule().currentTask}</h5>}
                        {getSchedule().nextTask == null ? null : 
                            <h6>{t('studyGuide.dashboard.next')}: {getSchedule().nextTask}</h6>}
                    </div>

                    <div className="study-guide-dashboard_sidebar_left_side_buttons" style={learningStarted ? {flexDirection: 'column'} : {flexDirection: 'row'}}>
                        <div className="study-guide-dashboard_sidebar_left_side_buttons_container">
                            <button onClick={handleDashboardRedirect}>{t('studyGuide.dashboard.dashboard')}</button>
                            <button onClick={handleFavoritesRedirect}>{t('studyGuide.dashboard.favorites')}</button>
                        </div>
                        {!studyGuideCompleted && (
                            !learningStarted && !studyGuide.tasks.some(task => task.is_completed) ? (
                                <button onClick={handleStartLearning}>{t('studyGuide.actions.startLearning')}</button>
                            ) : (
                                <div className="study-guide-dashboard_sidebar_left_side_buttons_container">
                                    {activeComponent !== 'StudyGuide' ? (
                                        <>
                                            <button onClick={handleSkipTask}>{t('studyGuide.actions.skipTask')}</button>
                                            <button 
                                                onClick={handleStartLearning}
                                                disabled={nextDisabled} 
                                                style={{backgroundColor: nextDisabled ? 'gray' : 'var(--primary)'}}
                                            >
                                                {t('studyGuide.actions.nextTask')}
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={handleSkipTask}>{t('studyGuide.actions.skipTask')}</button>
                                            <button onClick={handleStartLearning}>{t('studyGuide.actions.currentTask')}</button>
                                        </>
                                    )}
                                </div>
                            )
                        )}

                        {studyGuideCompleted && !finalGradeSubmitted && (
                            !examTaken ? (
                                <button onClick={handleExpectedGrade}>{t('studyGuide.dashboard.completeStudyGuide')}</button>
                            ) : (
                                <button onClick={handleFinalGrade}>{t('studyGuide.setup.submitGrade')}</button>
                            )
                        )}
                    </div>

                    <div className="study-guide-dashboard_sidebar_left_side_summary">
                        <div className="study-guide-dashboard_sidebar_left_side_summary_item">
                            <div className="study-guide-dashboard_sidebar_left_side_summary_item_circle">
                                <span>{summaryReportStats?.lesson}</span>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.lessonsCompletedBreak')}</span>
                        </div>

                        <div className="study-guide-dashboard_sidebar_left_side_summary_item">
                            <div className="study-guide-dashboard_sidebar_left_side_summary_item_circle">
                                <span>{summaryReportStats?.flashcards}</span>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.aiFlashcardsCompletedBreak')}</span>
                        </div>

                        <div className="study-guide-dashboard_sidebar_left_side_summary_item">
                            <div className="study-guide-dashboard_sidebar_left_side_summary_item_circle">
                                <span>{summaryReportStats?.quiz}</span>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.aiQuizzesBreak')}</span>
                        </div>

                        {/* <div className="study-guide-dashboard_sidebar_left_side_summary_item">
                            <div className="study-guide-dashboard_sidebar_left_side_summary_item_circle">
                                <span>{summaryReportStats?.lesson_quiz}</span>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.lessonQuizzesBreak')}</span>
                        </div> */}

                        <div className="study-guide-dashboard_sidebar_left_side_summary_item">
                            <div className="study-guide-dashboard_sidebar_left_side_summary_item_circle">
                                <span>{summaryReportStats?.mock_exam}</span>
                            </div>
                            <span style={{textAlign: 'center', whiteSpace: 'pre-line'}}>{t('studyGuide.dashboard.mockExams')}</span>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default StudyGuide;

