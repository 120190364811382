import './Course.css';

import { useEffect, useState } from 'react';

import CourseHome from './CourseHome';
import CourseMaterials from './CourseMaterials';
import CourseFlashcards from './CourseFlashcards';
import CourseQuizzes from './CourseQuizzes';
import CourseStudentProgress from './CourseStudentProgress';
import CourseReportedIssues from './CourseReportedIssues';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function Course({ course }) {

    const [activeTab, setActiveTab] = useState('home');

    useEffect(() => {
        setActiveTab('home');
    }, [course]);

    return (
        <div className="course">
            <div className="course-header">
                {activeTab !== 'home' && <KeyboardBackspaceIcon className="course-back-icon" fontSize='large' onClick={() => setActiveTab('home')}/>}
                <h1>{course.name}</h1>
            </div>


            <div className="course-content">
                {activeTab === 'home' && (
                    <CourseHome setActiveTab={setActiveTab}/>
                )}
                {/* {activeTab === 'materials' && (
                    <CourseMaterials course_id={course.id}/>
                )} */}
                {activeTab === 'flashcards' && (
                    <CourseFlashcards course_id={course.id} course={course}/> 
                )}
                {activeTab === 'quizzes' && (
                    <CourseQuizzes course_id={course.id} />
                )}
                {activeTab === 'student-progress' && (
                    <CourseStudentProgress course_id={course.id} />
                )}
                {activeTab === 'reported-issues' && (
                    <CourseReportedIssues course_id={course.id}/>
                )}
            </div>
        </div>
    );
}

export default Course;