import React, { useContext, useState, useEffect } from 'react';
import { icons } from '../../constants/icons';
import { useHistory, Link } from 'react-router-dom';
import { CartContext } from '../context/CartContext';
import { UserContext } from '../context/AuthContext';
import { addToWishListData, addCourseToCart, Spinner, showError, showSuccess, addFreeCourse } from '../Utils/helper';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import EmptyCartModal from '../modals/EmptyCartModal';
import getTranslationFromMany from '../../services/helper';
import { deleteWishlist } from '../services/courses/index'; // Import the deleteWishlist function
import Add from '@mui/icons-material/Add';
import Login from '../modals/Login';
import Signup from '../modals/Signup';
import ForgotPassword from '../modals/ForgotPassword';

function Card(props) {
    const { t, i18n } = useTranslation();
    const { cartCount, setCartCount, setCartData, cartData } = useContext(CartContext);
    const { loggedIn } = useContext(UserContext);
    const {
        id,
        title,
        tutor,
        price,
        thumbnail,
        isFree,
        isWishlist: initialIsWishlist, // Renaming the prop to use as initial state
        data,
        isPurchased,
        translation = {},
        instructorsList,
    } = props;
    const history = useHistory();
    const [loadingCart, setLoadingCart] = useState(false);
    const [isWishlist, setIsWishlist] = useState(initialIsWishlist); // Local state to track wishlist status
    const [show, setShow] = useState(false);

    const [openLoginModal, closeLoginModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeLoginModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Login forgotOpen={openForgotPasswordModal} loginClose={closeLoginModal} signupOpen={openSignupModal} />
            </ReactModal>
        );
    });
    const [openSignupModal, closeSignupModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeSignupModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <Signup closeSignup={closeSignupModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });
    const [openForgotPasswordModal, closeForgotPasswordModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <button
                    type="button"
                    className="close"
                    onClick={() => {
                        closeForgotPasswordModal();
                        setShow(false);
                    }}
                >
                    <img src={icons.icon_close} alt="close" />
                </button>
                <ForgotPassword closeForgot={closeForgotPasswordModal} openLogin={openLoginModal} />
            </ReactModal>
        );
    });

    useEffect(() => {
        setIsWishlist(initialIsWishlist); // Update local state if prop changes
    }, [initialIsWishlist]);

    let helperProps = {
        cartCount,
        setCartCount,
        setCartData,
        loggedIn,
        setLoadingCart,
        cartData,
        t,
    };
    const countryId = data.country_id;

    const [openEmptyCartModal, closeEmptyCartModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <EmptyCartModal closeModal={closeEmptyCartModal} setShow={setShow} loggedIn={loggedIn} />
            </ReactModal>
        );
    });

    function redirect(id) {
        history.push(`/course-details/${id}`);
    }

    const handleToggleWishlist = async () => {
        setLoadingCart('wishlist-loading');
        try {
            if (isWishlist) {
                const resp = await deleteWishlist(id);
                if (resp.success) {
                    showSuccess(t('alertMessage.removedFromWishList'));
                    setIsWishlist(false); // Update state to reflect removal from wishlist
                } else {
                    showError(t('alertMessage.wrong'));
                }
            } else {
                await addToWishListData({ course_id: id }, helperProps);
                setIsWishlist(true); // Update state to reflect addition to wishlist
            }
        } finally {
            setLoadingCart(false); // Reset the loading state after operation
        }
    };

    const handleAddToCart = async () => {
        if (isFree) {
            if (!loggedIn) {
                openLoginModal();
                return;
            }
            setLoadingCart('cart-loading');
            await addFreeCourse({ course_id: id }, helperProps, {
                success: t('alertMessage.freeCourseAdded'),
                fail: t('alertMessage.failed'),
            });
            history.push('/student-learning?courses');
        } else {
            addCourseToCart(
                { course_id: id },
                helperProps,
                'cart-loading',
                data,
                {
                    addToCartSuccess: t('alertMessage.addToCartMsg'),
                    courseExist: t('alertMessage.courseExist'),
                },
                openEmptyCartModal
            );
        }
    };

    return (
        <>
            <div className="col-12 col-lg-3 col-md-4 col-sm-6">
                <div className="course_box mb_50">
                    <div onClick={() => redirect(id)}>
                        <div className="course_thumbnail">
                            <img
                                src={thumbnail ? process.env.REACT_APP_ASSETS + thumbnail : icons.course_thumbnail}
                                alt=""
                            />
                        </div>
                        <h2 className="course_box_title" title={title}>
                            {t('dynamic', {
                                en: title,
                                ar: getTranslationFromMany(translation, 'name', title),
                            })}
                        </h2>
                        <div className="cp_wrapper">
                            {isFree === true ? (
                                <div className="price_discount">{t('common.free')}</div>
                            ) : !price?.on_sale ? (
                                countryId === 112 ? (
                                    <div className="price_discount">
                                        {t('course.price_in_kd', { price: price?.old_price })}
                                    </div>
                                ) : countryId === 17 ? (
                                    <div className="price_discount">
                                        {t('course.price_in_bd', { price: price?.old_price })}
                                    </div>
                                ) : (
                                    <div className="price_discount">
                                        {t('course.price_in_jd', { price: price?.old_price })}
                                    </div>
                                )
                            ) : countryId === 112 ? (
                                <>
                                    <div className="price_discount">
                                        {t('course.price_in_kd', { price: price?.new_price })}
                                    </div>
                                    <div className="price_strike">
                                        {t('course.price_in_kd', { price: price?.old_price })}
                                    </div>
                                </>
                            ) : countryId === 17 ? (
                                <>
                                    <div className="price_discount">
                                        {t('course.price_in_bd', { price: price?.new_price })}
                                    </div>
                                    <div className="price_strike">
                                        {t('course.price_in_bd', { price: price?.old_price })}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="price_discount">
                                        {t('course.price_in_jd', { price: price?.new_price })}
                                    </div>
                                    <div className="price_strike">
                                        {t('course.price_in_jd', { price: price?.old_price })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {isPurchased === null || !isPurchased?.is_purchased ? (
                        <div className="courses_btn" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
                            {loadingCart !== 'cart-loading' ? (
                                <button
                                    className="big_btn"
                                    style={{
                                        background: '#0b4475',
                                        border: 'none',
                                        borderRadius: '5px',
                                    }}
                                    onClick={handleAddToCart}
                                >
                                    {isFree ? (
                                        <Add style={{ color: 'white' }} />
                                    ) : (
                                        <div className="btn_icon">
                                            <img src={icons.icon_cart_white} alt="" />
                                        </div>
                                    )}
                                </button>
                            ) : (
                                <Spinner />
                            )}
                            {loadingCart === 'wishlist-loading' ? (
                                <Spinner />
                            ) : (
                                <button
                                    className="big_btn border-none bg-white"
                                    style={{
                                        border: `2px solid rgb(11, 68, 117)`,
                                        background: isWishlist ? 'rgb(11, 68, 117)' : 'white',
                                        color: isWishlist ? 'white' : 'rgb(11, 68, 117)',
                                        outline: 'none', // Remove default outline
                                    }}
                                    onClick={handleToggleWishlist}
                                >
                                    <div className="btn_icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill={isWishlist ? 'rgb(11, 68, 117)' : 'none'}
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                                        </svg>
                                    </div>
                                </button>
                            )}
                        </div>
                    ) : (
                        <Link
                            className="btn_primary btn-block py-3"
                            to={{
                                pathname: '/course',
                                state: id,
                            }}
                        >
                            {t('common.continueLearning')}
                        </Link>
                    )}
                </div>
            </div>
            <ToastContainer />
        </>
    );
}

export default Card;
