import { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { UserContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import {
    upvoteQuizQuestion,
    unupvoteQuizQuestion,
    downvoteQuizQuestion,
    undownvoteQuizQuestion,
    favoriteQuizQuestion,
    getQuizQuestions,
} from '../../services/ai';

import { createStudyGuideAssessment, createBulkStudyGuideAssessmentQuestions, createBulkStudyGuideAssessmentAnswers, updateStudyGuideTask, resetStudyGuideTasksByChapterId, submitChapterFeedback } from '../../services/studyGuides/index';

import ReportIssue from './ReportIssue';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './Quiz.css';

function shuffleArray(array) {
    const shuffled = [...array]; // Create a copy to avoid mutating the original
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
}

function Quiz({ 
    chapter_id, 
    course_id, 
    quiz, 
    setCreateNewQuiz, 
    studyGuide, 
    setStudyGuide, 
    currentTask, 
    setCurrentTask, 
    isMockExam, 
    setIsMockExam, 
    endOfQuiz, 
    setEndOfQuiz, 
    mockExam, 
    setMockExam,
    mockExamAnswers,
    setMockExamAnswers,
    setSelectedOption,
    setSelectedItem,
    handleMockExamStart,
    setActiveComponent,
    setExamTimer,
    clearExamTimer,
    activeComponent,
    setMockExamCompleted,
    mockExamCompleted
}) {
    const { user } = useContext(UserContext);
    const { t } = useTranslation();

    const [selectedAnswers, setSelectedAnswers] = useState(Array(quiz.length).fill(null));
    const [submitted, setSubmitted] = useState(false);
    const [chapterFeedback, setChapterFeedback] = useState({
        rating: 0,
        comment: ''
    });

    // State to track interactions for each quiz question
    const [quizStates, setQuizStates] = useState({});
    const [reportIndex, setReportIndex] = useState(null);

    // Memoize the shuffled answers for each question
    const shuffledQuestions = useMemo(() => {
        return quiz.map(questionData => {
            const answers = shuffleArray([
                questionData.correct_answer1,
                questionData.incorrect_answer1,
                questionData.incorrect_answer2,
                questionData.incorrect_answer3
            ]);
            return {
                ...questionData,
                shuffledAnswers: answers
            };
        });
    }, [quiz]); // Only re-shuffle when quiz changes

    const handleThumbsUp = async (e, question, index) => {
        e.stopPropagation(); // Prevent the card flip event
        // Safely access the current state with default values
        const currentState = quizStates[index] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
            // rating: null,
        };
        const newThumbsUpState = !currentState.thumbsUpClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                thumbsUpClicked: newThumbsUpState,
                thumbsDownClicked: false,
            },
        }));

        try {
            if (newThumbsUpState) {
                // remove upvote
                await unupvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            } else {
                await upvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    is_downvoted: quizStates[index].thumbsDownClicked === true ? true : false,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling thumbs up:', error);
        }
    };

    const handleThumbsDown = async (e, question, index) => {
        e.stopPropagation(); // Prevent the card flip event

        const currentState = quizStates[index] || {
            thumbsUpClicked: false,
            thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newThumbsDownState = !currentState.thumbsDownClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                thumbsDownClicked: newThumbsDownState,
                thumbsUpClicked: false,
            },
        }));

        try {
            if (newThumbsDownState) {
                // remove upvote
                await downvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    is_upvoted: quizStates[index].thumbsUpClicked === true ? true : false,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            } else {
                await undownvoteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    question: question.question1,
                    answer: question.correct_answer1,

                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling thumbs down:', error);
        }
    };

    const handleFavorite = async (e, question, index) => {
        e.stopPropagation();

        // Safely access the current state with default values
        const currentState = quizStates[index] || {
            // thumbsUpClicked: false,
            // thumbsDownClicked: false,
            favoriteClicked: false,
        };
        const newFavoriteState = !currentState.favoriteClicked;

        setQuizStates((prev) => ({
            ...prev,
            [index]: {
                ...prev[index],
                favoriteClicked: newFavoriteState,
            },
        }));
        try {
            if (newFavoriteState) {
                await favoriteQuizQuestion({
                    course_id: course_id,
                    chapter_id: chapter_id,
                    user_id: user?.id,
                    question: question.question1,
                    answer: question.correct_answer1,
                    incorrect_answer1: question.incorrect_answer1,
                    incorrect_answer2: question.incorrect_answer2,
                    incorrect_answer3: question.incorrect_answer3,
                    explanation: question.explanation,
                });
            }
        } catch (error) {
            console.error('Error handling favorite:', error);
        }
    };

    const handleAnswerSelect = (questionIndex, answer) => {
        if (submitted) return; // Prevent changing answers after submission

        if (isMockExam) {
            setMockExamAnswers((prevMockExamAnswers) => {
                const newMockExamAnswers = [...prevMockExamAnswers];
                if (mockExam.length > 5) {
                    newMockExamAnswers[questionIndex + 5] = answer;
                } else {
                    newMockExamAnswers[questionIndex] = answer;
                }
                return newMockExamAnswers;
            });
        } else {
            setSelectedAnswers((prevSelectedAnswers) => {
                const newSelectedAnswers = [...prevSelectedAnswers];
                newSelectedAnswers[questionIndex] = answer;
                return newSelectedAnswers;
            });
        }
    };

    const handleMockExamModal = async() => {
        const score = mockExamAnswers.reduce((total, answer, index) => {
            return answer === mockExam[index].correct_answer1 ? total + 1 : total;
        }, 0);

        const percentage = Math.round((score / 10) * 100);


        const result = await Swal.fire({
            title: t('studyGuide.mock_exam.title'),
            html: `
                <div style="margin-bottom: 20px">
                    <h2 style="color: ${percentage >= 70 ? '#4CAF50' : '#f44336'}">
                        ${t('studyGuide.mock_exam.score')} ${score}/${10} (${percentage}%)
                    </h2>
                    <p style="margin-top: 10px">${t('studyGuide.mock_exam.whatNext')}</p>
                </div>
            `,
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: t('studyGuide.mock_exam.continueStudyGuide'),
            denyButtonText: t('studyGuide.mock_exam.redoPreviousChapter'),
            cancelButtonText: t('studyGuide.mock_exam.review'),
            confirmButtonColor: '#0b4374',
            denyButtonColor: '#757575',
            cancelButtonColor: '#757575',
            allowOutsideClick: false,
            allowEscapeKey: false
        });

        if (result.isConfirmed) {
            const loadingSwal = Swal.fire({
                title: t('studyGuide.dashboard.loading'),
                customClass: {
                    title: 'swal2-title-custom'
                },
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });

            try {
                const resp = await createStudyGuideAssessment({
                    study_guide_id: studyGuide.id,
                    type: 'mock_exam',
                    score: calculateScore() / 10,
                    max_score: 1.0,
                    lesson_quiz_id: null
                });
        
                if (resp.success) {
                    const resp2 = await createBulkStudyGuideAssessmentQuestions({
                        questions: [
                            {
                                assessment_id: resp.data.id,
                                question: quiz[0].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[1].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[2].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[3].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[4].question1,
                            },
                        ]
                    });
    
                    if (resp2.success) {
                        const resp3 = await createBulkStudyGuideAssessmentAnswers({
                            answers: [
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].correct_answer1,
                                    is_correct: true,
                                    user_selected: selectedAnswers[index] === quiz[index].correct_answer1
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer1,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer1
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer2,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer2
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer3,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer3
                                }))
                            ]
                        });
    
                        if (resp3.success) {
                            const resp4 = await updateStudyGuideTask(currentTask.id, {
                                is_completed: true
                            });
    
                            if (resp4.success) {
                                const updatedStudyGuide = {
                                    ...studyGuide,
                                    tasks: studyGuide.tasks.map(t => 
                                        t.id === currentTask.id ? { ...t, is_completed: true } : t
                                    ),
                                    completion_stats: resp4.data.completion_stats,
                                    overall_progress: resp4.data.overall_progress,
                                    week_progress: resp4.data.week_progress,
                                    average_grade: resp4.data.average_grade
                                };
                                
                                await loadingSwal.close();
                                
                                const feedbackResult = await Swal.fire({
                                    title: t('studyGuide.feedback.title'),
                                    html: `
                                        <div class="feedback-container">
                                            <div class="rating-container">
                                                <label>${t('studyGuide.feedback.ratingLabel')}</label>
                                                <input 
                                                    type="range" 
                                                    id="rating" 
                                                    min="1" 
                                                    max="5" 
                                                    value="${chapterFeedback.rating || 3}"
                                                    oninput="document.getElementById('ratingValue').textContent = this.value"
                                                >
                                                <span id="ratingValue">${chapterFeedback.rating || 3}</span>/5
                                            </div>
                                            <div class="comment-container">
                                                <label>${t('studyGuide.feedback.commentLabel')}</label>
                                                <textarea 
                                                    id="feedback" 
                                                    class="swal2-textarea" 
                                                    placeholder="${t('studyGuide.feedback.commentPlaceholder')}"
                                                >${chapterFeedback.comment}</textarea>
                                            </div>
                                        </div>
                                    `,
                                    showCancelButton: true,
                                    confirmButtonText: t('studyGuide.feedback.submit'),
                                    cancelButtonText: t('studyGuide.feedback.skip'),
                                    confirmButtonColor: '#0b4374',
                                    cancelButtonColor: '#757575',
                                    customClass: {
                                        popup: 'swal2-feedback-popup',
                                        title: 'swal2-title-custom'
                                    },
                                    preConfirm: () => {
                                        const rating = parseInt(document.getElementById('rating').value);
                                        const comment = document.getElementById('feedback').value.trim();
                                        
                                        if (rating === 0) {
                                            Swal.showValidationMessage(t('studyGuide.feedback.ratingRequired'));
                                            return false;
                                        }
                                        
                                        if (!comment) {
                                            Swal.showValidationMessage(t('studyGuide.feedback.commentRequired'));
                                            return false;
                                        }
                                        
                                        return { rating, comment };
                                    },
                                    didOpen: () => {
                                        document.getElementById('rating').value = chapterFeedback.rating || 3;
                                        document.getElementById('feedback').value = chapterFeedback.comment || '';
                                    }
                                });

                                if (feedbackResult.isConfirmed) {
                                    const SubmittingSwal = Swal.fire({
                                        title: t('studyGuide.feedback.submitting'),
                                        customClass: {
                                            title: 'swal2-title-custom'
                                        },
                                        showConfirmButton: false,
                                        allowOutsideClick: false,
                                        allowEscapeKey: false
                                    });
                                    setChapterFeedback({
                                        rating: feedbackResult.value.rating,
                                        comment: feedbackResult.value.comment
                                    });

                                    const resp = await submitChapterFeedback({
                                        user_id: user.id,
                                        course_id: course_id,
                                        chapter_id: currentTask.chapter_id,
                                        rating: feedbackResult.value.rating,
                                        feedback: feedbackResult.value.comment
                                    });

                                    if (resp.success) {
                                        SubmittingSwal.close();
                                        await Swal.fire({
                                            title: t('studyGuide.feedback.submitSuccess'),
                                            confirmButtonText: t('studyGuide.tasks.confirm'),
                                            confirmButtonColor: '#0b4374',
                                        });

                                        setIsMockExam(false);
                                        setMockExam([]);
                                        setMockExamAnswers([]);
                                        setStudyGuide(updatedStudyGuide);
                                        setActiveComponent('StudyGuide');
                                    }
                                    } else {
                                        setIsMockExam(false);
                                        setMockExam([]);
                                        setMockExamAnswers([]);
                                        setStudyGuide(updatedStudyGuide);
                                        setActiveComponent('StudyGuide');
                                    }
                            }
                        }
                    }
                }
            } catch (error) {
                await loadingSwal.close();
                console.error('Error:', error);
                await Swal.fire({
                    title: t('common.error'),
                    text: t('common.errorMessage'),
                    icon: 'error',
                    confirmButtonColor: '#0b4374',
                });
            }
        } else if (result.isDenied) {
            const loadingSwal = Swal.fire({
                title: t('studyGuide.dashboard.loading'),
                customClass: {
                    title: 'swal2-title-custom'
                },
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false
            });

            clearExamTimer();
            setIsMockExam(false);
            setMockExam([]);
            setMockExamAnswers([]);
            setMockExamCompleted(false);

            try {
                const resp = await resetStudyGuideTasksByChapterId(user.id, currentTask.chapter_id);
                if (resp.success) {
                    const updatedStudyGuide = {
                        ...studyGuide,
                        tasks: studyGuide.tasks.map(t => 
                            t.chapter_id === currentTask.chapter_id ? { ...t, is_completed: false } : t
                        )
                    };
                    await loadingSwal.close();
                    
                    setStudyGuide(updatedStudyGuide);
                    setActiveComponent('StudyGuide');
                }
            } catch (error) {
                await loadingSwal.close();
                console.error('Error:', error);
                await Swal.fire({
                    title: t('common.error'),
                    text: t('common.errorMessage'),
                    icon: 'error',
                    confirmButtonColor: '#0b4374',
                });
            }
        }
    }

    const handleMockExamSubmit = async () => {

        setMockExamCompleted(true);

        clearExamTimer();

        handleMockExamModal();

    };

    const handleSubmit = async () => {
        setSubmitted(true);

        if (Object.keys(studyGuide).length > 0 && (currentTask.type == 'quiz' || currentTask.type == 'mock_exam')) {
            if (isMockExam) {
                handleMockExamSubmit();
            } else {
                const SubmittingSwal = Swal.fire({
                    title: t('studyGuide.feedback.submitting'),
                    customClass: {
                        title: 'swal2-title-custom'
                    },
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false
                });

                const resp = await createStudyGuideAssessment({
                    study_guide_id: studyGuide.id,
                    type: 'quiz',
                    score: calculateScore() / quiz.length,
                    max_score: 1.0,
                    lesson_quiz_id: null
                });
        
                if (resp.success) {
                    const resp2 = await createBulkStudyGuideAssessmentQuestions({
                        questions: [
                            {
                                assessment_id: resp.data.id,
                                question: quiz[0].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[1].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[2].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[3].question1,
                            },
                            {
                                assessment_id: resp.data.id,
                                question: quiz[4].question1,
                            },
                        ]
                    });
        
                    if (resp2.success) {
                        const resp3 = await createBulkStudyGuideAssessmentAnswers({
                            answers: [
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].correct_answer1,
                                    is_correct: true,
                                    user_selected: selectedAnswers[index] === quiz[index].correct_answer1 ? true : false
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer1,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer1 ? true : false
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer2,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer2 ? true : false
                                })),
                                ...resp2.data.map((question, index) => ({
                                    question_id: question.id,
                                    answer_text: quiz[index].incorrect_answer3,
                                    is_correct: false,
                                    user_selected: selectedAnswers[index] === quiz[index].incorrect_answer3 ? true : false
                                }))
                            ]
                        });
        
                        if (resp3.success) {
    
                            const resp4 = await updateStudyGuideTask(currentTask.id, {
                                is_completed: true
                            });
    
                            if (resp4.success)  {
                                // Update the local studyGuide state with both task completion and stats
                                const updatedStudyGuide = {
                                    ...studyGuide,
                                    tasks: studyGuide.tasks.map(t => 
                                        t.id === currentTask.id ? { ...t, is_completed: true } : t
                                    ),
                                    completion_stats: resp4.data.completion_stats,
                                    overall_progress: resp4.data.overall_progress,
                                    week_progress: resp4.data.week_progress,
                                    average_grade: resp4.data.average_grade
                                    };
                                    setStudyGuide(updatedStudyGuide);
                                    SubmittingSwal.close();
                            }
    
                        }
                    }
                }
                SubmittingSwal.close();
            }

        }
    };

    const calculateScore = () => {

        if (isMockExam) {
            return mockExamAnswers.reduce((score, answer, index) => {
                if (answer === mockExam[index].correct_answer1) {
                    // Correct answer is the first element
                    return score + 1;
                }
                return score;
            }, 0);
        } else {
            return selectedAnswers.reduce((score, answer, index) => {
                if (answer === quiz[index].correct_answer1) {
                    // Correct answer is the first element
                    return score + 1;
                }
                return score;
            }, 0);
        }
    };

    const handleReportIssue = async (e, index) => {
        e.stopPropagation(); // Prevent the card flip event
        setReportIndex(index);
    };

    useEffect(() => {
        if (isMockExam && mockExam.length == 5) {
            handleMockExamStart();
        }
    }, [isMockExam]);

    return (
        <div className="quiz">
            {submitted && isMockExam && mockExam.length > 5 && (
                mockExam.slice(0, 5).map((questionData, index) => {
                    const {
                        correct_answer1,
                        explanation,
                        question1,
                        incorrect_answer1,
                        incorrect_answer2,
                        incorrect_answer3
                    } = questionData;

                    const shuffledAnswers = [
                        correct_answer1,
                        incorrect_answer1,
                        incorrect_answer2,
                        incorrect_answer3
                    ];

                    const currentState = quizStates[index] || {
                        thumbsUpClicked: false,
                        thumbsDownClicked: false,
                        favoriteClicked: false,
                    };

                    return (
                        <div key={index} className="quiz-main">
                            <div className="quiz__content">
                                <div className="quiz__content-title">
                                    <span className="quiz-question-text">
                                        {index + 1}. {question1}
                                    </span>
                                </div>
                                <div className="quiz-answers">
                                    {shuffledAnswers.map((answer, i) => {
                                        let answerClass = '';
                                        if (submitted) {
                                            if (answer === correct_answer1) {
                                                answerClass = 'correct';
                                            } else if (mockExamAnswers[index] === answer) {
                                                answerClass = 'incorrect';
                                            }
                                        }

                                        return (
                                            <div
                                                key={i}
                                                className={`quiz-answer ${answerClass}`}
                                            >
                                                {String.fromCharCode(65 + i)}. {answer}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div>
                                    <p
                                        style={{
                                            color: mockExamAnswers[index] === correct_answer1 ? 'limegreen' : 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {t('studyGuide.mock_exam.explanation')} {explanation}
                                    </p>
                                    <div className="quiz__content-rates">
                                        <div
                                            className={`quiz__content-menu-icon ${
                                                currentState.favoriteClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleFavorite(e, questionData, index)}
                                        >
                                            {currentState.favoriteClicked ? (
                                                <FavoriteIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                                            ) : (
                                                <FavoriteBorderOutlinedIcon
                                                    style={{ fontSize: '24px', color: '#0b4374' }}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className={`quiz__content-rates-icon ${
                                                currentState.thumbsUpClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleThumbsUp(e, questionData, index)}
                                        >
                                            <ThumbUpAltOutlinedIcon
                                                style={{
                                                    fontSize: '20px',
                                                    color: currentState.thumbsUpClicked ? '#ffffff' : '#0b4374',
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`quiz__content-rates-icon ${
                                                currentState.thumbsDownClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleThumbsDown(e, questionData, index)}
                                        >
                                            <ThumbDownAltOutlinedIcon
                                                style={{
                                                    fontSize: '20px',
                                                    color: currentState.thumbsDownClicked ? '#ffffff' : '#0b4374',
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="quiz__content-rates-icon"
                                            onClick={(e) => handleReportIssue(e, index)}
                                        >
                                            <GppMaybeOutlinedIcon style={{ fontSize: '20px', color: '#0b4374' }} />
                                        </div>
                                    </div>
                                </div>

                                {reportIndex === index && (
                                    <ReportIssue
                                        onClose={() => setReportIndex(null)}
                                        questionInfo={{
                                            course_id,
                                            chapter_id,
                                            user_id: user?.id,
                                            question_id: questionData.id,
                                            question: questionData.question1,
                                            answer: questionData.correct_answer1,
                                        }}
                                        type="quizzes"
                                    />
                                )}
                            </div>
                        </div>
                    );
                })
            )}

            {shuffledQuestions.map((questionData, index) => {
                const {
                    correct_answer1,
                    explanation,
                    question1,
                    shuffledAnswers
                } = questionData;

                const currentState = quizStates[index] || {
                    thumbsUpClicked: false,
                    thumbsDownClicked: false,
                    favoriteClicked: false,
                };

                return (
                    <div key={index} className="quiz-main">
                        <div className="quiz__content">
                            <div className="quiz__content-title">
                                <span className="quiz-question-text">
                                    {mockExam.length > 5 ? index + 6 : index + 1}.{question1}
                                </span>
                            </div>
                            <div className="quiz-answers">
                                {shuffledAnswers.map((answer, i) => {
                                    let answerClass = '';
                                    if (isMockExam) {
                                        if (submitted) {
                                            if (answer === correct_answer1) {
                                                answerClass = 'correct';
                                            } else if (mockExam.length > 5 ? mockExamAnswers[index + 5] == answer : mockExamAnswers[index] == answer) {
                                                answerClass = 'incorrect';
                                            }
                                        } else if (mockExam.length > 5 ? mockExamAnswers[index + 5] == answer : mockExamAnswers[index] == answer) {
                                            answerClass = 'selected';
                                        }
                                    } else {
                                        if (submitted) {
                                            if (answer === correct_answer1) {
                                                answerClass = 'correct';
                                            } else if (selectedAnswers[index] === answer) {
                                                answerClass = 'incorrect';
                                            }
                                        } else if (selectedAnswers[index] === answer) {
                                            answerClass = 'selected';
                                        }
                                    }

                                    return (
                                        <div
                                            key={i}
                                            className={`quiz-answer ${answerClass}`}
                                            onClick={() => handleAnswerSelect(index, answer)}
                                        >
                                            {String.fromCharCode(65 + i)}. {answer}
                                        </div>
                                    );
                                })}
                            </div>
                            {submitted && (
                                <div>
                                    <p
                                        style={{
                                            color: selectedAnswers[index] === correct_answer1 ? 'limegreen' : 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {t('studyGuide.mock_exam.explanation')} {explanation}
                                    </p>
                                    <div className="quiz__content-rates">
                                        <div
                                            className={`quiz__content-menu-icon ${
                                                currentState.favoriteClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleFavorite(e, questionData, index)}
                                        >
                                            {currentState.favoriteClicked ? (
                                                <FavoriteIcon style={{ fontSize: '24px', color: '#ffffff' }} />
                                            ) : (
                                                <FavoriteBorderOutlinedIcon
                                                    style={{ fontSize: '24px', color: '#0b4374' }}
                                                />
                                            )}
                                        </div>
                                        <div
                                            className={`quiz__content-rates-icon ${
                                                currentState.thumbsUpClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleThumbsUp(e, questionData, index)}
                                        >
                                            <ThumbUpAltOutlinedIcon
                                                style={{
                                                    fontSize: '20px',
                                                    color: currentState.thumbsUpClicked ? '#ffffff' : '#0b4374',
                                                    // border: '1px solid red',
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`quiz__content-rates-icon ${
                                                currentState.thumbsDownClicked ? 'active' : ''
                                            }`}
                                            onClick={(e) => handleThumbsDown(e, questionData, index)}
                                        >
                                            <ThumbDownAltOutlinedIcon
                                                style={{
                                                    fontSize: '20px',
                                                    color: currentState.thumbsDownClicked ? '#ffffff' : '#0b4374',
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="quiz__content-rates-icon"
                                            onClick={(e) => handleReportIssue(e, index)}
                                        >
                                            <GppMaybeOutlinedIcon style={{ fontSize: '20px', color: '#0b4374' }} />
                                        </div>
                                    </div>
                                </div>
                            )}

                            {reportIndex === index && (
                                <ReportIssue
                                    onClose={() => setReportIndex(null)}
                                    questionInfo={{
                                        course_id,
                                        chapter_id,
                                        user_id: user?.id,
                                        question_id: questionData.id,
                                        question: questionData.question1,
                                        answer: questionData.correct_answer1,
                                    }}
                                    type="quizzes"
                                />
                            )}
                        </div>
                    </div>
                );
            })}
            {submitted && (
                <>
                    <h4 className="quiz__score">
                        {t('studyGuide.mock_exam.score')}: {calculateScore()} / {isMockExam ? mockExam.length : quiz.length}
                    </h4>
                    {!isMockExam && currentTask.type != 'quiz' && currentTask.type != 'mock_exam' && (
                        <button
                            onClick={() => setCreateNewQuiz(true)}
                            className="secondary-button-fill quiz__generate-more-button"
                        >
                            {t('studyGuide.mock_exam.generateMore')}
                        </button>
                    )}
                    {mockExamCompleted && (
                        <button
                            onClick={() => handleMockExamModal()}
                            className="secondary-button-fill quiz__generate-more-button"
                        >
                            {t('studyGuide.mock_exam.continue')}
                        </button>
                    )}
                </>
            )}
            {!submitted && endOfQuiz && (
                <button 
                    onClick={handleSubmit} 
                    style={isMockExam ? {backgroundColor: mockExamAnswers.length < 10 ? 'grey' : '#0b4374'} : {backgroundColor: selectedAnswers.includes(null) ? 'grey' : '#0b4374'}} 
                    disabled={isMockExam ? mockExamAnswers.length < 10 : selectedAnswers.includes(null)} 
                    className="primary-button-fill quiz__check-button"
                >
                    {t('studyGuide.mock_exam.submit')}
                </button>
            )}
            {!submitted && !endOfQuiz && (
                <button 
                    onClick={() => {
                        setSelectedOption('Quiz')
                        setSelectedItem('ace')
                        setCreateNewQuiz(true)
                    }} 
                    className="primary-button-fill quiz__check-button"
                    disabled={(!isMockExam && selectedAnswers.length < 5) || (isMockExam && mockExamAnswers.length < 5)}
                    style={{backgroundColor: (!isMockExam && selectedAnswers.length < 5) || (isMockExam && mockExamAnswers.length < 5) ? 'grey' : '#0b4374'}}
                >
                    {t('studyGuide.mock_exam.next')}
                </button>
            )}

            <div className="block-20">
                <p></p>
            </div>
        </div>
    );
}
export default Quiz;
