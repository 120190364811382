import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
// import Pagination from '../../Pagination';
import Pagination from '../../Pagination';
import './PDFReader.css';

// import Loading from '../../Common/Loading';
import Loading from '../../Common/Loading';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = (props) => {
    const { videoUrl, isDownloadable } = props;
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    // Validate props
    if (!videoUrl) {
        return (
            <div className="pdf_reader pdf_reader--error">
                <h2>Content Unavailable</h2>
                <p>The PDF document you're trying to view is currently unavailable.</p>
            </div>
        );
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setIsLoading(false); // PDF is loaded
    }

    function changePage(offset) {
        setPageNumber((pageNumber) => pageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function goToPage(pageno) {
        setPageNumber(pageno);
    }

    function onLoadError(error) {
        console.error('PDF Load Error:', error);
        setIsLoading(false);
    }

    return (
        <div className="pdf_reader">
            <Document
                className="pdf_reader_document"
                file={
                    process.env.REACT_APP_ASSETS + '/api/proxy' + '?videoURL=' + videoUrl //process.env.REACT_APP_ASSETS + videoUrl,
                }
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={onLoadError}
                loading={<Loading type="text" />}
            >
                <Page pageNumber={pageNumber} />
            </Document>
            <p className="pdf_reader_page_number">
                Page {pageNumber} of {numPages}
            </p>
            <div className="buttonc pdf_reader_menu">
                <button type="button" className="btn btn_secondary" disabled={pageNumber <= 1} onClick={previousPage}>
                    Previous
                </button>
                <Pagination totalPages={numPages} currentPage={pageNumber} goToPage={goToPage} />
                <button
                    type="button"
                    className="btn btn_primary pdf-btn-secondary"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                >
                    Next
                </button>
                {isDownloadable ? (
                    <a
                        className="btn btn_secondary ml-3 pdf_reader_menu_download"
                        href={process.env.REACT_APP_ASSETS + videoUrl}
                        target="_blank"
                        download
                        rel="noreferrer"
                    >
                        Download
                    </a>
                ) : null}
            </div>
        </div>
    );
};

export default PDFReader;
