// External library imports
import { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Context and services imports
import { UserContext } from '../../context/AuthContext';

// Constants and initializations
import { OpenAI } from 'openai';

import { getDataCall, getAuthDataCall } from '../../services/utils';
import { getStudentThreads, putStudentThreads, getStudentCourse } from '../../services/student';
import {
    upvoteFlashcard,
    unupvoteFlashcard,
    downvoteFlashcard,
    undownvoteFlashcard,
    favoriteFlashcard,
    getFlashcards,
    getQuizQuestions,
} from '../../services/ai';

import FlashcardComponent from './FlashcardGenerate';
import QuizComponent from './QuizGenerate';

// Local imports
import './Ace.css';

function Ace({
    setActiveComponent,
    // setSidebarTop,
    course_id,
    setFlashcards,
    flashcards,
    courseData,
    setQuiz,
    // setViewMode,
    // viewMode,
    setChapterId,
    setLoading,
    // setError,
    loading,
    // error,
    setCreateNewQuiz,
    createNewQuiz,
    studyGuide,
    setStudyGuide,
    selectedChapter,
    setSelectedChapter,
    selectedOption,
    setSelectedOption,
    setSelectedItem,
    currentTask,
    setCurrentTask,
    isMockExam,
    setIsMockExam,
    endOfQuiz,
    setEndOfQuiz,
    mockExam,
    setMockExam,
    mockExamAnswers,
    setMockExamAnswers,
    mockExamTimeLimit,
    setMockExamTimeLimit,
    mockExamStartTime,
    setMockExamStartTime
}) {
    // UI State
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const [selectedViewMode, setSelectedViewMode] = useState('page');
    const [isFavorite, setIsFavorite] = useState(false);
    const [showError, setShowError] = useState(false); // State for error indication

    // Translation and Context
    const { t } = useTranslation();
    const { user } = useContext(UserContext);

    // const [availableChapters, setAvailableChapters] = useState([]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        // console.log('Ace handleOptionChange option:', option);
        // setActiveComponent(option);
    };

    // Handle chapter selection
    const handleChapterSelect = (chapter) => {
        setSelectedChapter({
            name: chapter.name,
            id: chapter.id,
        });
        setIsDropdownOpen(false); // Close dropdown after selection
        setChapterId(chapter.id);
    };

    //* Handle view mode change *//

    // Handle icon click (e.g., toggle favorite)
    const handleIconClick = () => {
        setIsFavorite((prev) => !prev);
        console.log('Ace handleIconClick function:', !isFavorite);
    };

    const handleGenerate = (type) => {
        if (!selectedChapter.name) {
            setShowError(true); // Show error if no chapter is selected
            setTimeout(() => setShowError(false), 3000); // Reset error state after 3 seconds
            return; // Prevent further action
        }
    };

    const renderComponent = () => {
        if (!selectedChapter.name) return null; // Don't render if no chapter is selected

        switch (selectedOption) {
            case 'Flashcard':
                return (
                    <FlashcardComponent
                        chapter={selectedChapter}
                        course_id={course_id}
                        setFlashcards={setFlashcards}
                        flashcards={flashcards}
                        setActiveComponent={setActiveComponent}
                        setLoading={setLoading}
                        // setError={setError}
                        loading={loading}
                        // error={error}
                        studyGuide={studyGuide}
                        setStudyGuide={setStudyGuide}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                    />
                );
            case 'Quiz':
                return (
                    <QuizComponent
                        course_id={course_id}
                        chapter={selectedChapter}
                        setQuiz={setQuiz}
                        setActiveComponent={setActiveComponent}
                        setLoading={setLoading}
                        // setError={setError}
                        loading={loading}
                        // error={error}
                        setCreateNewQuiz={setCreateNewQuiz}
                        createNewQuiz={createNewQuiz}
                        isMockExam={isMockExam}
                        setIsMockExam={setIsMockExam}
                        endOfQuiz={endOfQuiz}
                        setEndOfQuiz={setEndOfQuiz}
                        mockExam={mockExam}
                        setMockExam={setMockExam}
                        mockExamAnswers={mockExamAnswers}
                        setMockExamAnswers={setMockExamAnswers}
                        setSelectedItem={setSelectedItem}
                        mockExamTimeLimit={mockExamTimeLimit}
                        setMockExamTimeLimit={setMockExamTimeLimit}
                        mockExamStartTime={mockExamStartTime}
                        setMockExamStartTime={setMockExamStartTime}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="ace">
            <div className={`ace__chapter ${loading ? 'loading' : ''}`}>
                <button
                    className="ace__chapter-btn"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    disabled={loading}
                >
                    <span className={selectedChapter.name ? 'selected' : 'error'}>
                        {selectedChapter.name || 'Select a chapter'}
                    </span>
                    <KeyboardArrowDownIcon
                        style={{
                            transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s ease',
                        }}
                    />
                </button>

                {isDropdownOpen && !loading && (
                    <div className="ace__chapter-dropdown">
                        {courseData.chapter.map((chapter, index) => (
                            <div
                                key={index}
                                className={`ace__chapter-dropdown-item ${
                                    selectedChapter.id === chapter.id ? 'selected' : ''
                                }`}
                                onClick={() => handleChapterSelect(chapter)}
                            >
                                {chapter.name}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className={`ace__switch-menu ${loading ? 'loading' : ''}`}>
                <button
                    className={`ace__switch-btn ${selectedOption === 'Flashcard' ? 'active' : ''}`}
                    onClick={() => handleOptionChange('Flashcard')}
                    disabled={loading}
                >
                    Flashcard
                </button>
                <button
                    className={`ace__switch-btn ${selectedOption === 'Quiz' ? 'active' : ''}`}
                    onClick={() => handleOptionChange('Quiz')}
                    disabled={loading}
                >
                    Quiz
                </button>
            </div>

            {/* {selectedOption !== 'Quiz' && (
                <div className="ace__viewMode">
                    <h1>View Mode</h1>
                    <div className="ace__viewMode-switch">
                        <button
                            className={`ace_viewMode-btn ${viewMode === 'page' ? 'active' : ''}`}
                            onClick={() => handleViewMode('page')}
                        >
                            <ArticleOutlinedIcon
                                style={{
                                    fontSize: '24px',
                                    color: viewMode === 'page' ? 'white' : 'rgba(21, 128, 220, 0.4)',
                                }}
                            />
                        </button>

                        <button
                            className={`ace_viewMode-btn ${viewMode === 'list' ? 'active' : ''}`}
                            onClick={() => handleViewMode('list')}
                        >
                            <ViewStreamOutlinedIcon
                                style={{
                                    fontSize: '24px',
                                    color: viewMode === 'list' ? 'white' : 'rgba(21, 128, 220, 0.4)',
                                }}
                            />
                        </button>
                    </div>
                </div>
            )} */}

            <div
                className="ace__generate-section"
                onClick={() => {
                    if (!selectedChapter.name) {
                        handleGenerate(selectedOption); // Trigger handleGenerate if no chapter is selected
                    }
                }}
            >
                {renderComponent()}
            </div>

            {/* TODO: Implement necessary APIs to get this functional */}
            {/* {selectedOption !== 'Quiz' && (
                <div className="ace__save-flashcards">
                    <h1 className="ace__save-flashcards-title">Save</h1>
                    <button className="ace__save-flashcards-button">
                        Title
                        <span onClick={handleIconClick} style={{ cursor: 'pointer' }}>
                            {isFavorite ? (
                                <FavoriteRoundedIcon
                                    style={{
                                        fontSize: '24px',
                                        color: 'red', // Color for filled icon
                                    }}
                                />
                            ) : (
                                <FavoriteBorderRoundedIcon
                                    style={{
                                        fontSize: '24px',
                                        color: 'rgba(21, 128, 220, 0.4)', // Color for outlined icon
                                    }}
                                />
                            )}
                        </span>
                    </button>
                </div>
            )} */}
        </div>
    );
}

export default Ace;
