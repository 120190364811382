import { Modal } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { OpenAI } from 'openai';
import { getStudentThreads, putStudentThreads, getStudentCourse } from '../services/student';
import { getDataCall, getAuthDataCall } from '../services/utils';
// import { availableChapters, availableCourses } from "../Utils/courseIds";
import { UserContext } from '../context/AuthContext';
import ourLogo from '../../assets/image/dawraty-logo.png';
import { BsCardText } from 'react-icons/bs';
import { TfiLayoutGrid2, TfiRulerAlt } from 'react-icons/tfi';
import Cookies from 'js-cookie';
import '../../assets/styles/flashcards.css';
import {
    FaRegThumbsUp,
    FaThumbsUp,
    FaRegThumbsDown,
    FaThumbsDown,
    FaArrowCircleRight,
    FaArrowCircleLeft,
} from 'react-icons/fa';
import { MdOutlineReportProblem, MdFavoriteBorder, MdFavorite } from 'react-icons/md';
import {
    upvoteFlashcard,
    unupvoteFlashcard,
    downvoteFlashcard,
    undownvoteFlashcard,
    favoriteFlashcard,
    getFlashcards,
} from '../services/ai';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';
import ReportIssueModal from '../modals/ReportIssueModal';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});


const assistant = await openai.beta.assistants
    .retrieve(process.env.REACT_APP_FLASHCARDS_ASSISTANT_ID)
    .then((res) => res)
    .catch((err) => {
        console.error(err);
        throw err;
    });


export function Flashcard({
    flashcard,
    question,
    answer,
    carouselNumber,
    listMode,
    course_id,
    chapter_id,
    state,
    updateState,
}) {
    const { user } = useContext(UserContext);
    const [cardIsFlipped, setCardIsFlipped] = useState(false);
    const [show, setShow] = useState(false);

    const [openReportIssueModal, closeReportIssueModal] = useModal(() => {
        setShow(true);
        return (
            <ReactModal isOpen ariaHideApp={false} className="react_modal" overlayClassName="modal_overlay">
                <ReportIssueModal
                    closeModal={closeReportIssueModal}
                    questionInfo={{
                        course_id: course_id,
                        chapter_id: chapter_id,
                        user_id: user?.id,
                        question_id: flashcard?.id === undefined ? null : flashcard?.id,
                        question: question,
                        answer: answer,
                    }}
                    setShow={setShow}
                    type="flashcard"
                />
            </ReactModal>
        );
    });

    const { t } = useTranslation();

    useEffect(() => {
        setCardIsFlipped(false);
    }, [carouselNumber]);

    const handleFlip = () => {
        setCardIsFlipped(!cardIsFlipped);
    };

    const handleThumbsUp = async (e) => {
        e.stopPropagation();
        const newThumbsUpState = !state.thumbsUpClicked;
        updateState({
            thumbsUpClicked: newThumbsUpState,
            thumbsDownClicked: false,
        });

        if (newThumbsUpState) {
            await upvoteFlashcard({
                course_id,
                chapter_id,
                question,
                answer,
                is_downvoted: false,
            });
        } else {
            await unupvoteFlashcard({
                course_id,
                chapter_id,
                question,
                answer,
            });
        }
    };

    const handleThumbsDown = async (e) => {
        e.stopPropagation();
        const newThumbsDownState = !state.thumbsDownClicked;
        updateState({
            thumbsDownClicked: newThumbsDownState,
            thumbsUpClicked: false,
        });

        if (newThumbsDownState) {
            await downvoteFlashcard({
                course_id,
                chapter_id,
                question,
                answer,
                is_upvoted: false,
            });
        } else {
            await undownvoteFlashcard({
                course_id,
                chapter_id,
                question,
                answer,
            });
        }
    };

    const handleReportIssue = async (e) => {
        e.stopPropagation(); // Prevent the card flip event
        openReportIssueModal();
    };

    const handleFavorite = async (e) => {
        e.stopPropagation();
        const newFavoriteState = !state.favoriteClicked;
        updateState({ favoriteClicked: newFavoriteState });

        await favoriteFlashcard({
            course_id,
            chapter_id,
            user_id: user?.id,
            question,
            answer,
        });
    };

    return (
        <div
            className={`flip-card ${cardIsFlipped ? 'flipped' : ''}`}
            onClick={handleFlip}
            style={listMode ? { marginBottom: '72px' } : null}
        >
            <div className="flip-card-inner">
                <div className="flip-card-front">
                    <span className="dawraty-flashcard-front">DAWRATY</span>
                    <div className="card-content">
                        <span>{question}</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        <button
                            onClick={handleFavorite}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.favoriteClicked ? <MdFavorite /> : <MdFavoriteBorder />}
                        </button>
                        <button
                            onClick={handleThumbsUp}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.thumbsUpClicked ? <FaThumbsUp /> : <FaRegThumbsUp />}
                        </button>
                        <button
                            onClick={handleThumbsDown}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.thumbsDownClicked ? <FaThumbsDown /> : <FaRegThumbsDown />}
                        </button>
                        <br />
                        <button
                            onClick={handleReportIssue}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            <span>
                                <MdOutlineReportProblem />
                            </span>
                        </button>
                    </div>
                </div>
                <div className="flip-card-back">
                    <span className="dawraty-flashcard-back">DAWRATY</span>
                    <div className="card-content" style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ color: '#0b4475', fontWeight: 'bold' }}>Q. {question}</span>
                        <span style={{ color: '#0b4475' }}>A. {answer}</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: 32,
                            marginBottom: 32,
                        }}
                    >
                        <button
                            onClick={handleFavorite}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.favoriteClicked ? <MdFavorite /> : <MdFavoriteBorder />}
                        </button>
                        <button
                            onClick={handleThumbsUp}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.thumbsUpClicked ? <FaThumbsUp /> : <FaRegThumbsUp />}
                        </button>
                        <button
                            onClick={handleThumbsDown}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            {state.thumbsDownClicked ? <FaThumbsDown /> : <FaRegThumbsDown />}
                        </button>
                        <br />
                        <button
                            onClick={handleReportIssue}
                            style={{
                                color: '#0b4475',
                                border: 'none',
                                background: 'none',
                                cursor: 'pointer',
                                fontSize: '24px',
                            }}
                        >
                            <span>
                                <MdOutlineReportProblem />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Flashcards(props) {
    const { user } = useContext(UserContext);
    const { chapter, course_id, chapter_id } = props;

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [singleFlipped, setSingleFlipped] = useState(false);
    const [error, setError] = useState(false);
    const [lockout, setLockout] = useState(false);
    const [threadID, setThreadID] = useState('');
    const [flashcards, setFlashcards] = useState([]);
    const [listMode, setListMode] = useState(false);
    const [carouselNumber, setCarouselNumber] = useState(0);
    const [flashcardStates, setFlashcardStates] = useState({});

    useEffect(() => {
        getThreads()
            .then((res) => {
                // check if user has a thread for flashcards
                if (res.data[0].flashcards_thread == ' ') {
                    openai.beta.threads
                        .create()
                        .then((res) => {
                            updateThreads(res.id);
                            setThreadID(res.id);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setThreadID(res.data[0].flashcards_thread);
                }
            })
            .catch((err) => console.log(err));
    }, [user]);

    useEffect(() => {
        setFlashcards([]);
    }, [chapter]);

    async function getThreads() {
        const resp = await getStudentThreads(user?.id);
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error('Could not fetch threads');
        }
    }

    async function postFavorite() {
        /* replace body with flashcards data */
        // const resp = await favoriteFlashcard({
        //   user_id: 1,
        //   course_id: 1,
        //   chapter_id: 1,
        //   question: "testing_question",
        //   answer: "testing_answer"
        // })
    }

    async function updateThreads(thread_id) {
        const resp = await putStudentThreads(user?.id, {
            thread_id: thread_id,
            to_update: 'flashcards_thread',
        });
        if (resp.success) {
            return resp;
        } else {
            console.log(resp);
            throw new Error('Could not update threads');
        }
    }

    const updateFlashcardState = (id, newState) => {
        setFlashcardStates((prevStates) => ({
            ...prevStates,
            [id]: { ...prevStates[id], ...newState },
        }));
    };

    function generateFlashcards(array) {
        let setter = array.map((flashcard, index) => ({
            ...flashcard,
            id: index,
        }));
        setFlashcards(setter);

        // Initialize flashcard states
        const initialStates = {};
        setter.forEach((flashcard) => {
            initialStates[flashcard.id] = {
                thumbsUpClicked: false,
                thumbsDownClicked: false,
                favoriteClicked: false,
            };
        });
        setFlashcardStates(initialStates);

        setLoading(false);
        setLockout(false);
    }

    async function createMessage() {
        // get existing flashcards from backend, if empty [] either all flashcards are viewed or none exist.
        // If that is the case, generate using OpenAI API
        setLoading(true);
        const resp = await getFlashcards(course_id, chapter_id);

        if (resp.data.length >= 5) {
            //flashcards to be shown, display them and exit
            setFlashcards(resp.data);
            setLoading(false);
            return;
        }

        openai.beta.threads.runs
            .list(threadID)
            .then((res1) => {
                if (res1.body.first_id == null) {
                    openai.beta.threads.messages
                        .create(threadID, {
                            role: 'user',
                            content: `call the generateFlashcard function five times to create 5 distinct flashcards to help me study the content of ${chapter}.`,
                        })
                        .then((res) => {
                            runAssistant();
                            setLoading(true);
                        })
                        .catch((err) => console.log(err));
                } else {
                    openai.beta.threads.runs
                        .retrieve(threadID, res1.body.first_id)
                        .then((res) => {
                            console.log('res', res);
                            if (res.status == 'requires_action') {
                                submitTools(res1.body.first_id);
                                setError(false);
                            } else if (
                                res.status == 'completed' ||
                                res.status == 'expired' ||
                                res.status == 'failed' ||
                                res.status == 'incomplete'
                            ) {
                                setError(false);
                                openai.beta.threads.messages
                                    .create(threadID, {
                                        role: 'user',
                                        content: `call the generateFlashcard function five times to create 5 distinct flashcards to help me study the content of ${chapter}.`,
                                    })
                                    .then((res) => {
                                        runAssistant();
                                        setLoading(true);
                                    })
                                    .catch((err) => console.log(err));
                            } else {
                                setLockout(true);
                                setError(true);
                                setTimeout(() => {
                                    setLockout(false);
                                    createMessage();
                                    console.log('locking loop');
                                    console.log(res.status);
                                }, 5000);
                            }
                        })
                        .catch((err) => console.log('error'));
                }
            })
            .catch((err) => console.log(err));
    }

    async function runAssistant() {
        setLoading(true);
        openai.beta.threads.runs
            .create(threadID, {
                assistant_id: assistant.id,
            })
            .then((res) => {
                setLoading(true);
                retrieveRun(res.id);
            });
    }

    function retrieveRun(run_id) {
        openai.beta.threads.runs.retrieve(threadID, run_id).then((res) => {
            if (res.status == 'in_progress' || res.status == 'queued') {
                setLoading(true);
                setTimeout(() => {
                    retrieveRun(run_id);
                    console.log('running');
                }, 6000);
            } else if (res.status == 'requires_action') {
                submitTools(run_id);
            } else if (res.status == 'completed') {
                setLockout(true);
                setTimeout(() => {
                    setLockout(false);
                }, 20000);
                setError(true);
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    async function submitTools(run_id) {
        setLoading(true);

        const runStatus = await openai.beta.threads.runs.retrieve(threadID, run_id);

        const toolCalls = runStatus.required_action.submit_tool_outputs.tool_calls;

        const args = [];
        toolCalls.map((tool) => args.push(JSON.parse(tool.function.arguments)));
        const toolIds = [];
        const outputs = [];

        toolCalls.map((tool) => toolIds.push(tool.id));
        toolIds.map((toolID) =>
            outputs.push({
                tool_call_id: toolID,
                output: 'output',
            })
        );

        openai.beta.threads.runs
            .submitToolOutputs(threadID, run_id, {
                tool_outputs: outputs,
            })
            .then((res) => generateFlashcards.apply(null, [args]));
    }

    return (
        <div className="aistudy-container">
            <button
                className="generate"
                disabled={loading || lockout || threadID == '' ? true : false}
                onClick={createMessage}
            >
                {t('flashcards.generateFlashcards')}
            </button>
            <div className="view-mode">
                {t('flashcards.viewMode')}
                <div className="mode-icons">
                    <BsCardText onClick={() => setListMode(false)} style={!listMode ? { color: '#0b4475' } : null} />
                    <TfiLayoutGrid2 onClick={() => setListMode(true)} style={listMode ? { color: '#0b4475' } : null} />
                </div>
            </div>
            {listMode ? (
                <div
                    className="flashcard-list"
                    style={
                        flashcards.length <= 0 || loading || error
                            ? {
                                  overflowY: 'hidden',
                                  display: 'flex',
                                  height: 'fit-content',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                              }
                            : {
                                  overflowY: 'scroll',
                                  height: '600px',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                                  paddingTop: '20px',
                              }
                    }
                >
                    {/* {flashcards.length <= 0 && !loading && !error ? (
                        <div className="default-screen">
                            <img src={ourLogo} style={{ width: '150px' }} />
                            <span>{t('flashcards.introText1')}</span>
                            <span>{t('flashcards.introText2')}</span>
                        </div>
                    ) : null} */}
                    {loading ? (
                        <div>
                            <div class="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {flashcards.map((flashcard) => (
                                <Flashcard
                                    key={flashcard.id}
                                    flashcard={flashcard}
                                    question={flashcard.question}
                                    answer={flashcard.answer}
                                    carouselNumber={carouselNumber}
                                    listMode={listMode}
                                    course_id={course_id}
                                    chapter_id={chapter_id}
                                    state={flashcardStates[flashcard.id] || {}}
                                    updateState={(newState) => updateFlashcardState(flashcard.id, newState)}
                                />
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="flashcard-list"
                    style={
                        flashcards.length <= 0 || loading || error
                            ? {
                                  overflowY: 'hidden',
                                  display: 'flex',
                                  height: 'fit-content',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                              }
                            : { justifyContent: 'center', height: '350px' }
                    }
                >
                    {/* {flashcards.length <= 0 && !loading && !error ? (
                        <div className="default-screen">
                            <img src={ourLogo} style={{ width: '150px' }} />
                            <span>{t('flashcards.introText1')}</span>
                            <span>{t('flashcards.introText2')}</span>
                        </div>
                    ) : null} */}
                    {loading ? (
                        <div>
                            <div class="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="flashcard-single-display"
                            style={flashcards.length <= 0 ? { display: 'none' } : null}
                        >
                            <div className="flashcard-carousel">
                                <button
                                    className="carousel-arrow"
                                    onClick={() => {
                                        if (carouselNumber != 0) {
                                            setCarouselNumber(carouselNumber - 1);
                                            setSingleFlipped(false);
                                        }
                                    }}
                                >
                                    <FaArrowCircleLeft />
                                </button>
                                {flashcards[carouselNumber] && (
                                    <Flashcard
                                        question={flashcards[carouselNumber].question}
                                        flashcard={flashcards[carouselNumber]}
                                        answer={flashcards[carouselNumber].answer}
                                        carouselNumber={carouselNumber}
                                        listMode={listMode}
                                        course_id={course_id}
                                        chapter_id={chapter_id}
                                        state={flashcardStates[flashcards[carouselNumber].id] || {}}
                                        updateState={(newState) =>
                                            updateFlashcardState(flashcards[carouselNumber].id, newState)
                                        }
                                    />
                                )}
                                <button
                                    className="carousel-arrow"
                                    onClick={() => {
                                        if (carouselNumber < flashcards.length - 1) {
                                            setCarouselNumber(carouselNumber + 1);
                                            setSingleFlipped(false);
                                        }
                                    }}
                                >
                                    <FaArrowCircleRight />
                                </button>
                            </div>
                            <span className="carousel-number">
                                {carouselNumber + 1} of {flashcards.length}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Flashcards;
