import { useEffect, useState, useRef, useContext } from 'react';
import { animated, useSpring, useSprings } from '@react-spring/web';
import { postDataCall } from '../services/utils';
import { getStudentThreads, putStudentThreads } from '../services/student';
import { UserContext } from '../context/AuthContext';
import { OpenAI } from 'openai';

import { useTranslation } from 'react-i18next';

import { AiFillHome } from 'react-icons/ai';
import { IoIosHelpCircle } from 'react-icons/io';
import { IoIosChatboxes } from 'react-icons/io';
import { IoSend } from 'react-icons/io5';
import { TbCircleLetterD } from 'react-icons/tb';
import { IoIosArrowBack } from 'react-icons/io';
import logo from '../../assets/image/logo_resized.png';

const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
});

const assistant = await openai.beta.assistants
    .retrieve(process.env.REACT_APP_TUTOR_ID)
    .then((res) => res)
    .catch((err) => {
        console.error(err);
        throw err;
    });

function Home({ setSelectedPage, course_name }) {
    const { t } = useTranslation();

    const springs = useSpring({
        from: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 0%)' },
        to: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 100%)' },
        config: { duration: 400 },
    });

    return (
        <animated.div style={{ ...springs }} id="home-container">
            <TbCircleLetterD id="D-icon-home" />
            <div id="home-header">
                <span>{t('chatbot.hiThere')}👋</span>
                <span>{t('tutor.howCanIHelp')}</span>

                <div id="ask-a-question" onClick={() => setSelectedPage('Messanger')}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{t('chatbot.askAQuestion')}</span>
                        <span>
                            {t('tutor.getHelp')} {course_name}
                        </span>
                    </div>

                    <IoSend id="ask-a-question-icon" />
                </div>
            </div>

            <div id="chatbot-nav">
                <AiFillHome className="nav-icon" onClick={() => setSelectedPage('Home')} />
                <IoIosChatboxes className="nav-icon" onClick={() => setSelectedPage('Messanger')} />
            </div>
        </animated.div>
    );
}

function Messanger({ setSelectedPage, course_name }) {
    const { t } = useTranslation();

    const messagesRef = useRef();

    const { user } = useContext(UserContext);

    const [messages, setMessages] = useState([
        {
            role: 'assistant',
            content: `${t('tutor.introduction')} ${course_name}?`,
            id: Date.now(),
        },
    ]);
    const [userInput, setUserInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [threadID, setThreadID] = useState('');

    const backgroundSprings = useSpring({
        from: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 100%)' },
        to: { background: 'linear-gradient(180deg, rgba(11, 68, 117, 1) 2%, rgba(255, 255, 255, 1) 0%)' },
        config: { duration: 100 },
    });

    const [springs, api] = useSprings(
        messages.length,
        () => ({
            to: { opacity: 1, transform: 'scale(1)' },
            from: { opacity: 0, transform: 'scale(0.3)' },
            config: { tension: 250, friction: 20 },
        }),
        []
    );

    function Message({ message, index }) {
        return (
            <animated.div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    ...springs[index],
                    width: '100%',
                    justifyContent: message.role != 'assistant' ? 'flex-end' : 'flex-start',
                    paddingRight: message.role != 'assistant' ? '10px' : '0px',
                }}
            >
                {message.role == 'assistant' ? <img src={logo} className="message-ai-img" /> : null}
                <div className="message" style={message.role != 'assistant' ? { background: 'rgb(0, 123, 255)' } : {}}>
                    <span style={{ textAlign: 'left' }}>{message.content}</span>
                </div>
            </animated.div>
        );
    }

    function handleInputChange(e) {
        setUserInput(e.target.value);
    }

    function submitUserInput() {
        if (userInput != '') {
            messages.push({
                role: 'user',
                content: userInput,
                id: Date.now(),
            });
            createMessage();
            setUserInput('');
            setTimeout(() => (messagesRef.current.scrollTop = messagesRef.current.scrollHeight), 10);
            setLoading(true);
            messages.push({
                role: 'assistant',
                content: '...',
                id: Date.now(),
            });
            setTimeout(() => (messagesRef.current.scrollTop = messagesRef.current.scrollHeight), 10);
        }
    }

    useEffect(() => {
        getThreads()
            .then((res) => {
                // check if user has a thread
                if (res.data[0].tutor_thread == ' ') {
                    openai.beta.threads
                        .create()
                        .then((res) => {
                            updateThreads(res.id);
                            setThreadID(res.id);
                        })
                        .catch((err) => console.log(err));
                } else {
                    setThreadID(res.data[0].tutor_thread);
                }
            })
            .catch((err) => console.log(err));
    }, [user]);

    async function getThreads() {
        const resp = await getStudentThreads(user?.id);
        if (resp.success) {
            return resp;
        } else {
            throw new Error('Could not fetch threads');
        }
    }

    async function updateThreads(thread_id) {
        const resp = await putStudentThreads(user?.id, {
            thread_id: thread_id,
            to_update: 'tutor_thread',
        });
        if (resp.success) {
            return resp;
        } else {
            throw new Error('Could not update threads');
        }
    }

    useEffect(() => {
        setTimeout(() => (messagesRef.current.scrollTop = messagesRef.current.scrollHeight), 10);
    }, [messages]);

    async function createMessage() {
        openai.beta.threads.runs
            .list(threadID)
            .then((res1) => {
                if (res1.body.first_id == null) {
                    openai.beta.threads.messages
                        .create(threadID, {
                            role: 'user',
                            content: `I have a question: ${userInput}. Please use the documents related to ${course_name} to find the answer. If this question is not related
                            to ${course_name}, then please respond that this question is not related to ${course_name}`,
                        })
                        .then((res) => {
                            runAssistant();
                            setLoading(true);
                        })
                        .catch((err) => console.log(err));
                } else {
                    openai.beta.threads.runs
                        .retrieve(threadID, res1.body.first_id)
                        .then((res) => {
                            if (
                                res.status == 'completed' ||
                                res.status == 'expired' ||
                                res.status == 'failed' ||
                                res.status == 'incomplete'
                            ) {
                                openai.beta.threads.messages
                                    .create(threadID, {
                                        role: 'user',
                                        content: `I have a question: ${userInput}. Please use the documents related to ${course_name} to find the answer. If this question is not related
                                        to ${course_name}, then please respond that this question is not related to ${course_name}`,
                                    })
                                    .then((res) => {
                                        runAssistant();
                                        setLoading(true);
                                    })
                                    .catch((err) => console.log(err));
                            }
                        })
                        .catch((err) => console.log('error'));
                }
            })
            .catch((err) => console.log(`error: ${err}`));
    }

    async function runAssistant() {
        setLoading(true);
        openai.beta.threads.runs
            .create(threadID, {
                assistant_id: assistant.id,
            })
            .then((res) => {
                setLoading(true);
                retrieveRun(res.id);
            });
    }

    function retrieveRun(run_id) {
        openai.beta.threads.runs.retrieve(threadID, run_id).then((res) => {
            if (res.status == 'in_progress' || res.status == 'queued') {
                setLoading(true);
                setTimeout(() => {
                    retrieveRun(run_id);
                    console.log('running');
                }, 4000);
            } else if (res.status == 'completed') {
                getMessages();
                setLoading(false);
            } else {
                setLoading(false);
            }
        });
    }

    async function getMessages() {
        openai.beta.threads.messages
            .list(threadID)
            .then((res) => {
                let response = res.data[0].content[0].text.value;
                let role = res.data[0].role;
                const regex = /【[^】]*】/g;

                if (response.includes('【')) {
                    response = response.replace(regex, '');
                }

                if (response.includes('the document')) {
                    response = response.replace('the document', 'Dawraty');
                } else if (response.includes('The document')) {
                    response = response.replace('The document', 'Dawraty');
                }

                if (response.includes('**')) {
                    response = response.replace(/\*\*/g, '');
                }

                messages.pop();
                setMessages((prevConvo) => [
                    ...prevConvo,
                    {
                        role: role,
                        content: response,
                        id: Date.now(),
                    },
                ]);
                // setTimeout(() => (chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight), 100);
            })
            .catch((err) => console.log(err));
    }

    return (
        <animated.div style={{ ...backgroundSprings }} id="help-container">
            <div id="help-nav">
                {/* <IoIosArrowBack id='back-arrow' onClick={() => setSelectedPage('Home')} /> */}
                <span>DAWRATY</span>
            </div>

            <div id="messages-container">
                <div id="help-header">
                    <TbCircleLetterD id="D-icon-help" />
                    <span>{t('chatbot.askAQuestion')}</span>
                    <span>
                        {t('tutor.getHelp')} {course_name}
                    </span>
                </div>

                <div
                    ref={messagesRef}
                    style={{
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        height: '390px',
                        borderBottom: '1px solid rgba(128, 128, 128, 0.388)',
                    }}
                >
                    {messages.map((message, key) => (
                        <Message message={message} key={key} index={key} />
                    ))}
                </div>

                <input
                    value={userInput}
                    onKeyUp={(e) => {
                        if (e.key == 'Enter') {
                            submitUserInput();
                        }
                    }}
                    onChange={(e) => handleInputChange(e)}
                    disabled={loading || threadID == ''}
                    style={{ backgroundColor: loading || threadID == '' ? 'grey' : 'white' }}
                    placeholder="Message..."
                    id="help-input"
                />
            </div>
        </animated.div>
    );
}

function TutorRevamp({ course_name }) {
    const { t } = useTranslation();

    const [selectedPage, setSelectedPage] = useState('Home');

    function fetchPage() {
        switch (selectedPage) {
            case 'Home':
                return <Home setSelectedPage={setSelectedPage} course_name={course_name} />;
            case 'Messanger':
                return <Messanger setSelectedPage={setSelectedPage} course_name={course_name} />;
        }
    }

    return (
        <div id="chatbot-container">
            {/* {fetchPage()} */}
            <Messanger setSelectedPage={setSelectedPage} course_name={course_name} />;
        </div>
    );
}

export default TutorRevamp;
