import './FlashcardFavorites.css';

import { useState, useEffect } from 'react';
import { getFlashcardFavorites } from '../../../services/instructorDashboard/index.js';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';



function FlashcardFavorites({ user_id }) {
    const { t } = useTranslation();
    const [flashcardFavorites, setFlashcardFavorites] = useState([]);
    const [loading, setLoading] = useState(true);

    async function fetchFlashcardFavorites() {
        setLoading(true);
        const res = await getFlashcardFavorites(user_id);
        
        if (res.success) {
            let setter = [];
            for (let i = 0; i < res.data.length; i++) {
                const flashcard = res.data[i].flashcard;
                setter.push(flashcard);
            }
            setFlashcardFavorites(setter);
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchFlashcardFavorites();
    }, []);


    return (
        <div className="flashcard-favorites-container">
            <h1>{t('instructorDashboard.flashcardFavorites')}</h1>

            <div className="flashcard-favorites-list">
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && flashcardFavorites.length === 0 && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <h1>{t('instructorDashboard.noFlashcardFavoritesFound')}</h1>
                    </Box>
                )}
                {!loading && flashcardFavorites.length > 0 && flashcardFavorites.map((flashcard) => (
                    <div className="flashcard-favorites-item">
                        <span><span className="flashcard-favorites-item-label">{t('instructorDashboard.question')}: </span>{flashcard.question}</span>
                        <span><span className="flashcard-favorites-item-label">{t('instructorDashboard.answer')}: </span>{flashcard.answer}</span>
                        <div className="flashcard-favorites-item-votes">
                            <span><span className="flashcard-favorites-item-label">{t('instructorDashboard.upvotes')}: </span>{flashcard.upvotes}</span>
                            <span><span className="flashcard-favorites-item-label">{t('instructorDashboard.downvotes')}: </span>{flashcard.downvotes}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FlashcardFavorites;