import './DashboardHome.css';
import { useTranslation } from 'react-i18next';


function DashboardHome({ fullName, totalStudentsCount, totalCoursesCount, setActiveTab }) {
    const { t } = useTranslation();

    return (
        <div className="instructor-dashboard_home">
            <h2>{t('instructorDashboard.welcome')}, {fullName}</h2>

            <div className="instructor-dashboard_content_total_students">
                <h3 className="instructor-dashboard_content_total_students_title">{t('instructorDashboard.totalStudents')}</h3>
                <span>{totalStudentsCount}</span>
            </div>

            <div className="instructor-dashboard_content_total_courses">
                <h3 className="instructor-dashboard_content_total_courses_title">{t('instructorDashboard.totalCourses')}</h3>
                <span>{totalCoursesCount}</span>
            </div>

            <div className="instructor-dashboard_content_favorite_flashcards">
                <h3 className="instructor-dashboard_content_favorite_flashcards_title">{t('instructorDashboard.favoriteFlashcards')}</h3>
                <button onClick={() => setActiveTab('flashcard-favorites')}>{t('instructorDashboard.view')}</button>
            </div>

            <div className="instructor-dashboard_content_favorite_quizzes">
                <h3 className="instructor-dashboard_content_favorite_quizzes_title">{t('instructorDashboard.favoriteQuizzes')}</h3>
                <button onClick={() => setActiveTab('quiz-favorites')}>{t('instructorDashboard.view')}</button>
            </div>                  
        </div>
    );
}

export default DashboardHome;