import { getAuthDataCall, postAuthDataCall, putAuthDataCall, deleteAuthDataCall } from '../utils';

export const getFlashcardFavorites = async (user_id) => {
    const resp = await getAuthDataCall(`/auth/flashcards/favorites/${user_id}`);
    return resp;
};

export const getQuizFavorites = async (user_id) => {
    const resp = await getAuthDataCall(`/auth/ai_quizzes/favorites/${user_id}`);
    return resp;
};

export const fetchFlashcardsByCourseId = async (course_id) => {
    const resp = await getAuthDataCall(`/auth/flashcards/course/${course_id}`);
    return resp;
};

export const createFlashcard = async (flashcard) => {
    const resp = await postAuthDataCall(`/auth/flashcards`, flashcard);
    return resp;
};

export const deleteFlashcard = async (flashcard_id) => {
    const resp = await deleteAuthDataCall(`/auth/flashcards/${flashcard_id}`);
    return resp;
};

export const updateFlashcard = async (flashcard_id, flashcard) => {
    const resp = await putAuthDataCall(`/auth/flashcards/${flashcard_id}`, flashcard);
    return resp;
}

export const fetchStudentProgress = async (course_id) => {
    const resp = await getAuthDataCall(`/auth/study_guides/course/${course_id}/details`);
    return resp;
}

export const deleteStudentProgress = async (id) => {
    const resp = await deleteAuthDataCall(`/auth/study_guides/${id}`);
    return resp;
}

export const fetchQuizzesByCourseId = async (course_id) => {
    const resp = await getAuthDataCall(`/auth/ai_quizzes/course/${course_id}`);
    return resp;
}

export const createQuizQuestion = async (quiz_question) => {
    const resp = await postAuthDataCall(`/auth/ai_quizzes`, quiz_question);
    return resp;
}

export const fetchReportedIssuesByCourseId = async (course_id) => {
    const resp = await getAuthDataCall(`/auth/flashcards/report_issues/course/${course_id}`);
    const resp2 = await getAuthDataCall(`/auth/ai_quizzes/report_issues/course/${course_id}`);
    return {
        flashcards: resp,
        quizzes: resp2
    };
}

export const deleteQuizQuestion = async (quiz_question_id) => {
    const resp = await deleteAuthDataCall(`/auth/ai_quizzes/${quiz_question_id}`);
    return resp;
}

export const updateQuizQuestion = async (quiz_question_id, quiz_question) => {
    const resp = await putAuthDataCall(`/auth/ai_quizzes/${quiz_question_id}`, quiz_question);
    return resp;
}